import Helpers from "./Helpers";
import RestHost from "./RestHost";
import Logger from "./Logger";
import Timer from "./Timer";

const NCRRest = {};
const retainCaseFields = ["registrationFields", "billPaymentFields", "settings", "promoCode"];

NCRRest.active = false;

const sendGetRequest = (restUrl, successCallback, errorCallback) => {
    const requestTime = new Date();
    NCRRest.start();

    NCRRest.host.removeHeader("X-JSON-ERROR-RESPONSE-FORMAT");
    NCRRest.host.get(restUrl,
        (response) => {
            NCRRest.end();

            const responseTime = new Date();

            if (response.headers) {
                NCRRest.setSessionIdHeader(response.headers["session-id"]);
            }
            if (typeof successCallback === "function") {
                successCallback(response.response ? response.response : response);
            }
        },
        (error) => {
            NCRRest.end();
            if (typeof errorCallback === "function") {
                errorCallback(error);
            }
        },
    );
};

const sendPostRequest = (restUrl, data, successCallback, errorCallback) => {
    const requestTime = new Date();
    NCRRest.start();

    NCRRest.host.removeHeader("X-JSON-ERROR-RESPONSE-FORMAT");
    NCRRest.host.post(restUrl, NCRRest.objectToUpperCase(data),
        (response) => {
            NCRRest.end();

            const responseTime = new Date();

            if (typeof successCallback === "function") {
                successCallback(response.response ? response.response : response);
            }
        },
        (error) => {
            NCRRest.end();

            if (typeof errorCallback === "function") {
                errorCallback(error);
            }
        },
    );
};

const sendRequest = (sendType, restUrl, data, urlParams, successCallback, errorCallback, responseType, processOnBackground, customHeaders) => {
    const requestTime = new Date();
    const strParams = (urlParams != null) ? urlParams.toString() : "";

    NCRRest.host.removeHeader("X-JSON-ERROR-RESPONSE-FORMAT");
    const customer = NCRRest.getAuthHeader();

    if (customer) {
        NCRRest.host.addHeader("Authorization", customer);
    }

    const sessionId = NCRRest.getSessionIdHeader();
    if (sessionId) {
        NCRRest.host.addHeader("session-id", sessionId);
    }

    if (customHeaders) {
        Object.entries(customHeaders).forEach(([key, value]) => NCRRest.host.addHeader(key, value));
    }

    NCRRest.start();
    NCRRest.host.send(sendType, restUrl + strParams, NCRRest.objectToUpperCase(data),
        (response) => {
            NCRRest.end();

            const responseTime = new Date();

            if (typeof successCallback === "function") {
                successCallback(response.response ? response.response : response);
            }
        },
        (error) => {
            NCRRest.end();

            if (typeof errorCallback === "function") {
                errorCallback(error);
            }
        }, responseType, processOnBackground);
};

const sendRequestPromise = (sendType, restUrl, data, urlParams, successCallback, errorCallback, responseType, processOnBackground, customHeaders) => new Promise((resolve, reject) => {
    const strParams = (urlParams != null) ? urlParams.toString() : "";

    NCRRest.host.removeHeader("X-JSON-ERROR-RESPONSE-FORMAT");
    const customer = NCRRest.getAuthHeader();

    if (customer) {
        NCRRest.host.addHeader("Authorization", customer);
    }

    const sessionId = NCRRest.getSessionIdHeader();
    if (sessionId) {
        NCRRest.host.addHeader("session-id", sessionId);
    }

    if (customHeaders) {
        Object.entries(customHeaders).forEach(([key, value]) => NCRRest.host.addHeader(key, value));
    }

    NCRRest.start();
    NCRRest.host.send(sendType, restUrl + strParams, NCRRest.objectToUpperCase(data),
        (response) => {
            NCRRest.end();

            if (typeof successCallback === "function") {
                successCallback(response.response ? response.response : response);
            }
            resolve(response);
        },
        (error) => {
            NCRRest.end();

            if (typeof errorCallback === "function") {
                errorCallback(error);
            }
            reject(error);
        }, responseType, processOnBackground);
});


NCRRest.isActive = () => NCRRest.active === true;

NCRRest.start = () => NCRRest.start === true;

NCRRest.end = () => NCRRest.active === false;

NCRRest.getAuthHeader = () => localStorage.auth;

NCRRest.getSessionIdHeader = () => localStorage.sessionIdHeader;

NCRRest.setAuthHeader = (authHeader) => {
    localStorage.auth = authHeader;
};

NCRRest.setSessionIdHeader = (sessionId) => {
    localStorage.sessionIdHeader = sessionId;
};

NCRRest.generateAuthHeader = (username, password) => `Basic ${window.btoa(`${String(username).toUpperCase()}:${String(password).toUpperCase()}`)}`;

NCRRest.init = (callback, processOnBackground) => {
    const getWebServiceUrlCallback = (url) => {
        NCRRest.host = new RestHost(url);
        NCRRest.host.setIncludeResponseHeaders(true);

        if (callback) {
            callback();
        }
    };
    NCRRest.getLocalSetting("ncrWebServiceUrl", getWebServiceUrlCallback, processOnBackground);
};

NCRRest.login = (rrn, password, successCallback, errorCallback) => {
    const url = Helpers.addForceDisableCacheHeader("login");
    const authHeader = NCRRest.generateAuthHeader(rrn, password);

    NCRRest.host.addHeader("Authorization", authHeader);
    NCRRest.get(
    url,
    (response) => {
        Timer.startCustomerIdTimerExpiration();
        NCRRest.setAuthHeader(authHeader);
        successCallback(response);
    },
    errorCallback,
  );
};

NCRRest.get = (restUrl, successCallback, errorCallback, isXml) => {
    if (NCRRest.host == null) {
        NCRRest.init(() => {
            sendGetRequest(restUrl, successCallback, errorCallback, isXml);
        });
    } else {
        sendGetRequest(restUrl, successCallback, errorCallback, isXml);
    }
};

NCRRest.post = (restUrl, data, successCallback, errorCallback) => {
    if (NCRRest.host == null) {
        NCRRest.init(() => {
            sendPostRequest(restUrl, data, successCallback, errorCallback);
        });
    } else {
        sendPostRequest(restUrl, data, successCallback, errorCallback);
    }
};

NCRRest.send = (sendType, restUrl, data, urlParams, successCallback, errorCallback, responseType, processOnBackground, customHeaders) => {
    if (NCRRest.host == null) {
        NCRRest.init(() => {
            sendRequest(sendType, restUrl, data, urlParams, successCallback, errorCallback, responseType, processOnBackground, customHeaders);
        });
    } else {
        sendRequest(sendType, restUrl, data, urlParams, successCallback, errorCallback, responseType, processOnBackground, customHeaders);
    }
};
NCRRest.sendPromise = (sendType, restUrl, data, urlParams, successCallback, errorCallback, responseType, processOnBackground, customHeaders) => {
    if (NCRRest.host == null) {
        NCRRest.init(() => sendRequestPromise(sendType, restUrl, data, urlParams, successCallback, errorCallback, responseType, processOnBackground, customHeaders));
    } else {
        return sendRequestPromise(sendType, restUrl, data, urlParams, successCallback, errorCallback, responseType, processOnBackground, customHeaders);
    }
};

NCRRest.objectToUpperCase = (arg) => {
    const objectCopy = Helpers.isArray(arg) ? arg.slice(0) : Object.assign({}, arg);
    Object.keys(objectCopy).forEach((key) => {
        if (!retainCaseFields.includes(key)) {
            const value = objectCopy[key];
            if (value === null || typeof value === "undefined") {
                delete objectCopy[key];
            } else if (typeof value === "string") {
                let upperCase = value.toUpperCase();
                upperCase = upperCase.trim();
                objectCopy[key] = NCRRest.replaceSpecialCharacters(upperCase);
            } else if (value && value.constructor && value.constructor.name === "Date") {
                objectCopy[key] = value;
            } else if (typeof value === "object") {
                objectCopy[key] = NCRRest.objectToUpperCase(value);
            }
        }
    });
    return objectCopy;
};

NCRRest.replaceSpecialCharacters = (arg) => {
    return arg.replace(/[`‘’]/g, "'");
};

NCRRest.getApplicationId = () => NCRRest.getLocalSetting("applicationId");

NCRRest.getLocalSetting = (key, callback, processOnBackground) => {
    if (NCRRest.localSettings == null || NCRRest.localSettings == undefined) {
        const restHost = new RestHost("resources/config/");
        restHost.get("settings.json",
            (response) => {
                if (callback) {
                    callback(response[key]);
                }
                NCRRest.localSettings = response;
            },
            (error) => Logger.error(error),
            RestHost.JSON,
            processOnBackground,
        );
    } else if (callback) {
        callback(NCRRest.localSettings[key]);
    } else {
        return NCRRest.localSettings[key];
    }
};

NCRRest.init();

export default NCRRest;
