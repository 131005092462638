/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/button-has-type */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable prefer-template */
import React, { useEffect, useState } from "react";

const letterRow1 = ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"];
const letterRow2 = ["A", "S", "D", "F", "G", "H", "J", "K", "L"];
const letterRow3 = ["Z", "X", "C", "V", "B", "N", "M"];

const row4 = ["CLEAR", "SPACE", "BACKSPACE"];

const LetterKeyboard = ({ setInputValue }) => (
    <React.Fragment>
        <div className="keyboard-wrapper">
            <DisplayKeyboardRow row={letterRow1}
                setInputValue={setInputValue} />
            <DisplayKeyboardRow row={letterRow2}
                setInputValue={setInputValue} />
            <DisplayKeyboardRow row={letterRow3}
                setInputValue={setInputValue} />
            <DisplayKeyboardRow row={row4} setInputValue={setInputValue} />
        </div>
    </React.Fragment>
);

const DisplayKeyboardRow = ({ row = [], setInputValue }) => {
    const handleClick = (key) => {
        if (row4.includes(key)) {
            handleSpecialKey(key);
            return;
        }
        setInputValue((prevState) => prevState + key);
    };

    const handleSpecialKey = (key) => {
        switch (key) {
            case "BACKSPACE":
                setInputValue((prevState) => prevState.slice(0, -1));
                break;
            case "SPACE":
                setInputValue((prevState) => prevState + " ");
                break;
            case "CLEAR":
                setInputValue("");
                break;
            default:
                break;
        }
    };

    const handleLabelDisplay = (key) => {
        if (row4.includes(key) && key !== "SPACE") {
            return "";
        }
        return key;
    };

    return (
        <div className="key-wrapper">
            {row.map((key) => (
                <button onClick={() => handleClick(key)}
                    className={`btn-char-key ${
                        key.length > 1 ? "btn-" + key.toLowerCase() : ""
                    }`}
                    key={key}>
                    {handleLabelDisplay(key)}
                </button>
            ))}
        </div>
    );
};

function CharacterKeyBoard({ title, onChange, errorMessage, pageDetail, value = "" }) {
    const [inputValue, setInputValue] = useState(value || "");
    useEffect(() => {
        const data = { ...pageDetail, value: inputValue };
        if (onChange) {
            onChange(data);
        }
    }, [inputValue]);

    useEffect(() => {
        const hasValueKey = Object.keys(pageDetail).includes("value");
        if (hasValueKey) setInputValue(pageDetail.value);
    }, [pageDetail.name]);

    return (
        <div className="character-keyboard-container">
            {title && <h2 className="title char-keyboard-title">{title}</h2>}
            <input type="text" value={inputValue} onChange={({ target: { value } }) => setInputValue(value)} />
            <LetterKeyboard setInputValue={setInputValue} />
            {errorMessage && <p className="input-error">{errorMessage}</p>}
        </div>
    );
}

export default CharacterKeyBoard;
