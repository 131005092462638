import React from "react";
import { connect } from "react-redux";
import { SHOW_CONSOLE, HIDE_CONSOLE } from "../actions";

const DebugButton = ({ enableDebugMode, showConsole, onShowConsole, onHideConsole }) => {
    const buttonAction = showConsole ? onHideConsole : onShowConsole;
    return enableDebugMode ? <button className="debug-button" onClick={buttonAction}>
        {showConsole ? "Exit" : "View Console"}
    </button>: null;
};

const mapStateToProps = (state) => {
    return {
        showConsole: state.debugMode.showConsole,
        enableDebugMode: state.config.enableDebugMode,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onShowConsole: () => dispatch({ type: SHOW_CONSOLE }),
        onHideConsole: () => dispatch({ type: HIDE_CONSOLE })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DebugButton);
