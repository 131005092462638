/* eslint-disable react/jsx-fragments */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/button-has-type */
import React, { Fragment, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

import MenuItemContent from "./MenuItemContent";
import {
    ADD_PAGE_STACK,
    getEServiceMenuItems,
    getProductDetails,
    NEXT_ITEM_PAGE,
    PREV_ITEM_PAGE,
    REMOVE_PAGE_STACK,
    RESET_DEFAULT_PAGE_PAGES,
} from "../actions/defaultPage";
import MenuUtil from "../utils/MainMenuUtils";
import { extractKeyValueInArray, generateRandomId, isEllipsisActive } from "../utils/helper";
import { getItemDetailsForAllowedCollection } from "../actions/hotFoodAction";
import OperatorIdPrompt from "./interventions/OperatorIdPrompt";

const RenderMenuItems = (props) => {
    const {
        sortedItems,
        onGetMenuItems,
        onGetProductDetails,
        buttonActionMapping,
        operatorInstructionIsActive,
        pageStack,
        currentIndex,
        resetDefaultPagePages,
        setPromptOperatorId,
    } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const { hotFoodComboMessage } = useSelector((state) => state.config);
    useEffect(() => {
        if (operatorInstructionIsActive && currentIndex === 0) {
            resetDefaultPagePages();
        }
    }, [operatorInstructionIsActive, pageStack, currentIndex]);

    const onContinue = (item) => {
        props.setSelectedMenuId(item.menuId);
        if (item.requireAuth && !item.productId) {
            setPromptOperatorId(true, item.name.replace(/\s/g, ""));
            return;
        }
        if (item.productId) {
            const orderID = generateRandomId();
            const hotFoodNames = [
                "7-ElevenHotFood",
                "Food",
                "7-ElevenHotFoodMenu",
            ];
            const hasNameToInclude = (element) => pageStack.includes(element);

            if (!hotFoodNames.some(hasNameToInclude)) {
                dispatch({
                    type: ADD_PAGE_STACK,
                    path: item.name.replace(/\s/g, ""),
                });
            }
            if (item.extendedMenuItems) {
                if (item.allowCollection) {
                    return dispatch(
                        getItemDetailsForAllowedCollection({
                            productId: item.productId,
                            customSuccessCallback: () =>
                                MenuUtil.makeItComboIntervention({
                                    history,
                                    message: hotFoodComboMessage,
                                    dispatch,
                                    item,
                                    location,
                                    orderID,
                                }),
                            orderID,
                        }),
                    );
                }
            }
            onGetProductDetails(item.productId, item.allowCollection, orderID);
        } else {
            dispatch({
                type: ADD_PAGE_STACK,
                path: item.name.replace(/\s/g, ""),
            });
            onGetMenuItems(item.menuId);
        }
    };

    const { startIndex, endIndex } = MenuUtil.getMenuItemPageIndices(props);
    const correspondingMenuItemSymbols = buttonActionMapping.map(
        ({ menuItemName }) => menuItemName,
    );

    return (
        // eslint-disable-next-line react/jsx-fragments
        <Fragment>
            {sortedItems.map((item, index) => {
                const shouldDisplayMenuItem =
                    index <= endIndex && index >= startIndex;
                const isCustomItemName = item.name.includes("|");
                const columnSpan = item.columnSpan || 1;
                const widthLength = columnSpan * 80 + (columnSpan - 1) * 10;
                if (
                    shouldDisplayMenuItem &&
                    correspondingMenuItemSymbols.includes(item.name)
                ) {
                    const findMapping = (actionMapping) =>
                        actionMapping.menuItemName === item.name;
                    const additionalProps = {
                        rawName: item.name,
                        menuId: item.menuId,
                        widthLength: widthLength,
                        requireAuth: item.requireAuth,
                        imageName: item.imageName,
                        ...(item.image && {
                            rawImagePath: item.image,
                        }),
                        ...item,
                    };
                    const comp = buttonActionMapping
                        .find(findMapping)
                        .component(additionalProps);
                    return comp;
                }
                if (shouldDisplayMenuItem && isCustomItemName) {
                    const [customName] = item.name.split("|");
                    const mappingItem = buttonActionMapping.find(
                        (actionMapping) =>
                            actionMapping.menuItemName === customName,
                    );

                    if (mappingItem) {
                        const buttonCustomProps = MenuUtil.getCustomItemProps(
                            mappingItem,
                            item,
                            index,
                            widthLength,
                        );

                        return mappingItem.renderCustomComponent(
                            buttonCustomProps,
                        );
                    }
                }
                const itemProps = {
                    key: `${item.name}-${index}`,
                    onClick: () => onContinue(item),
                    className: `menu-item ${item.productId ? "red" : ""}`,
                    style: {
                        width: `${widthLength}px`,
                        visibility: item.name === "" ? "hidden" : "visible",
                        position: "relative",
                    },
                    ...(item.image && { image: item.image }),
                };

                return shouldDisplayMenuItem ? (
                    <button
                        {...itemProps}
                        ref={(el) => isEllipsisActive(el, "11px")}
                    >
                        <MenuItemContent {...item} />
                    </button>
                ) : null;
            })}
        </Fragment>
    );
};

const MainMenuComponent = (props) => {
    const { search } = useLocation();
    const dispatch = useDispatch();
    useEffect(() => {
        if (search) {
            const searchParam = search.split("&");
            const { pageTitle } = extractKeyValueInArray(searchParam, "=");
            if (pageTitle) {
                const path = pageTitle.replace(/%20/g, "");
                dispatch({ type: ADD_PAGE_STACK, path });
            } else {
                dispatch({ type: ADD_PAGE_STACK, path: "eServiceMenu" });
            }
        } else {
            dispatch({ type: ADD_PAGE_STACK, path: "eServiceMenu" });
        }
    }, []);

    const {
        componentCss,
        onPrevItemPage,
        onNextItemPage,
        paginationStack,
        itemPageIndex,
        alternativePaginationSetup,
        promptOperatorId,
    } = props;
    const { shouldBeSinglePage } = alternativePaginationSetup;
    // eslint-disable-next-line no-param-reassign
    return (
        <React.Fragment>
            <div className="menu-container">
                <div
                    className={`button-container ${
                        shouldBeSinglePage ? "wide-page" : ""
                    }`}
                    style={componentCss}
                >
                    <RenderMenuItems {...props} />
                </div>
                <div
                    className={`scroll-container ${
                        shouldBeSinglePage ? "hidden" : ""
                    }`}
                >
                    <button onClick={() => onPrevItemPage(true)}>
                        <img
                            src={`resources/images/arrow_up_${
                                itemPageIndex > 0 ? "" : "in"
                            }active.png`}
                            className="button-image"
                        />
                    </button>
                    <button
                        onClick={() =>
                            onNextItemPage(paginationStack.length, true)
                        }
                    >
                        <img
                            src={`resources/images/arrow_down_${
                                itemPageIndex < paginationStack.length
                                    ? ""
                                    : "in"
                            }active.png`}
                            className="button-image"
                        />
                    </button>
                </div>
            </div>
            {promptOperatorId && <OperatorIdPrompt />}
        </React.Fragment>
    );
};

const mapStateToProps = (state, ownProps) => {
    const {
        itemPageIndex,
        pageStack,
        currentIndex,
        operatorInstructionIsActive,
        promptOperatorId,
        selectedMenuId,
    } = state.defaultPage;
    const { menuItemProps, componentCss, buttonActionMapping } = ownProps;

    const { menuItemIndex, menuItemStack } = menuItemProps;
    const items = menuItemStack[menuItemIndex];

    const sortedItems = items || [];
    const paginationStack = MenuUtil.getPaginationStack(sortedItems);
    const alternativePaginationSetup =
        MenuUtil.getAlternativePaginationSetup(sortedItems);
    const currentPageStack = paginationStack[itemPageIndex];
    return {
        sortedItems,
        itemPageIndex,
        currentPageStack,
        paginationStack,
        componentCss,
        buttonActionMapping,
        alternativePaginationSetup,
        pageStack,
        operatorInstructionIsActive,
        currentIndex,
        promptOperatorId,
        selectedMenuId,
        menuItemProps,
    };
};

const onNextItemPage = (pageStackLength, disableAddPageStack) => (dispatch) => {
    dispatch({ type: NEXT_ITEM_PAGE, pageStackLength });
    !disableAddPageStack &&
        dispatch({ type: ADD_PAGE_STACK, path: "productMenu" });
};

const onPrevItemPage = (disableRemovePageStack) => (dispatch) => {
    dispatch({ type: PREV_ITEM_PAGE });
    !disableRemovePageStack && dispatch({ type: REMOVE_PAGE_STACK });
};

const handleGetProductDetails =
    (productId, allowCollection, orderID) => (dispatch) => {
        if (allowCollection) {
            dispatch(
                getItemDetailsForAllowedCollection({ productId, orderID }),
            );
            return;
        }
        dispatch(getProductDetails(productId, true, null, allowCollection));
    };

const mapDispatchToProps = (dispatch) => {
    return {
        onGetMenuItems: (menuId) => dispatch(getEServiceMenuItems(menuId)),
        onGetProductDetails: (productId, allowCollection, orderID) =>
            dispatch(
                handleGetProductDetails(productId, allowCollection, orderID),
            ),
        onNextItemPage: (pageStackLength, disableAddPageStack = false) =>
            dispatch(onNextItemPage(pageStackLength, disableAddPageStack)),
        onPrevItemPage: (disableRemovePageStack = false) =>
            dispatch(onPrevItemPage(disableRemovePageStack)),
        resetDefaultPagePages: () =>
            dispatch({ type: RESET_DEFAULT_PAGE_PAGES }),
        addPageStack: (path) => dispatch({ type: ADD_PAGE_STACK, path }),
        addOrderFood: (item) => dispatch({ type: "ADD_ORDER_FOOD_S", item }),
        setPromptOperatorId: (promptOperatorId, pageStackItem) =>
            dispatch({
                type: "SET_PROMPT_OPERATOR_ID",
                promptOperatorId,
                pageStackItem,
            }),
        setSelectedMenuId: (selectedMenuId) =>
            dispatch({ type: "SET_SELECTED_MENU_ID", selectedMenuId }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainMenuComponent);
