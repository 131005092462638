/* eslint-disable import/no-cycle */
/* eslint-disable indent */
import React from "react";
import { RestHost, Helpers } from "common-functions";
import dateFormat from "dateformat";
import ElectronicServices from "../libs/ElectronicServices";
import {
    swipeRequest,
    cancelSwipe,
    printCancelledTransaction,
    customerDisplayCD,
} from "../libs/POSApplicationDA";
import { SHOW_INTERVENTION, HIDE_INTERVENTION } from ".";
import HttpUtils from "../utils/HttpUtils";
import { formatTrack2, convertValueToDouble } from "../utils/GenericUtils";
import ProductFieldEntry from "../containers/defaultPage/pages/ProductFieldEntry";
import { onExitBrowser } from "../components/Navigation";
import axios from "axios";

const prefix = "DEFAULT_PAGE_";

export const NEXT_PAGE = prefix.concat("NEXT_PAGE");
export const PREV_PAGE = prefix.concat("PREV_PAGE");

export const FILL_UP_BARCODE = prefix.concat("FILL_UP_BARCODE");
export const FILL_UP_PRODUCT_FIELD = prefix.concat("FILL_UP_PRODUCT_FIELD");

export const RESET_BARCODE_STATE = prefix.concat("RESET_BARCODE_STATE");
export const CUSTOM_PRODUCT_FIELD_ERROR = prefix.concat(
    "CUSTOM_PRODUCT_FIELD_ERROR",
);

export const ORDER_INFORMATION_REQUEST = prefix.concat(
    "ORDER_INFORMATION_REQUEST",
);
export const ORDER_INFORMATION_SUCCESS = prefix.concat(
    "ORDER_INFORMATION_SUCCESS",
);
export const ORDER_INFORMATION_FAILURE = prefix.concat(
    "ORDER_INFORMATION_FAILURE",
);

export const GET_PRODUCT_BY_BARCODE_REQUEST = prefix.concat(
    "GET_PRODUCT_BY_BARCODE_REQUEST",
);
export const GET_PRODUCT_BY_BARCODE_SUCCESS = prefix.concat(
    "GET_PRODUCT_BY_BARCODE_SUCCESS",
);
export const GET_PRODUCT_BY_BARCODE_FAILURE = prefix.concat(
    "GET_PRODUCT_BY_BARCODE_FAILURE",
);

export const MENU_ITEM_REQUEST = prefix.concat("MENU_ITEM_REQUEST");
export const MENU_ITEM_SUCCESS = prefix.concat("MENU_ITEM_SUCCESS");
export const MENU_ITEM_FAILURE = prefix.concat("MENU_ITEM_FAILURE");

export const STAGE_PRODUCT_REQUEST = prefix.concat("STAGE_PRODUCT_REQUEST");
export const STAGE_PRODUCT_SUCCESS = prefix.concat("STAGE_PRODUCT_SUCCESS");
export const STAGE_PRODUCT_FAILURE = prefix.concat("STAGE_PRODUCT_FAILURE");

export const VOID_PRODUCT_REQUEST = prefix.concat("VOID_PRODUCT_REQUEST");
export const VOID_PRODUCT_SUCCESS = prefix.concat("VOID_PRODUCT_SUCCESS");
export const VOID_PRODUCT_FAILURE = prefix.concat("VOID_PRODUCT_FAILURE");

export const CANCEL_TRANSACTION_FAILURE = prefix.concat(
    "CANCEL_TRANSACTION_FAILURE",
);
export const CANCEL_TRANSACTION_SUCCESS = prefix.concat(
    "CANCEL_TRANSACTION_SUCCESS",
);
export const CANCEL_TRANSACTION_REQUEST = prefix.concat(
    "CANCEL_TRANSACTION_REQUEST",
);

export const REFUND_TRANSACTION_FAILURE = prefix.concat(
    "REFUND_TRANSACTION_FAILURE",
);
export const REFUND_TRANSACTION_SUCCESS = prefix.concat(
    "REFUND_TRANSACTION_SUCCESS",
);
export const REFUND_TRANSACTION_REQUEST = prefix.concat(
    "REFUND_TRANSACTION_REQUEST",
);

export const NEXT_MENU_ITEM = prefix.concat("NEXT_MENU_ITEM");
export const PREV_MENU_ITEM = prefix.concat("PREV_MENU_ITEM");

export const NEXT_ITEM_PAGE = prefix.concat("NEXT_ITEM_PAGE");
export const PREV_ITEM_PAGE = prefix.concat("PREV_ITEM_PAGE");

export const NEXT_PRODUCT_FIELD = prefix.concat("NEXT_PRODUCT_FIELD");
export const PREV_PRODUCT_FIELD = prefix.concat("PREV_PRODUCT_FIELD");

export const INITIATE_DEFAULT_PRODUCT = prefix.concat(
    "INITIATE_DEFAULT_PRODUCT",
);

export const SCAN_ERROR = prefix.concat("SCAN_ERROR");

export const ADD_PAGE_STACK = "ADD_PAGE_STACK";
export const REMOVE_PAGE_STACK = "REMOVE_PAGE_STACK";
export const RESET_PAGE_STACK = "RESET_PAGE_STACK";

export const INITIATE_OPERATOR_INSTRUCTIONS = prefix.concat(
    "INITIATE_OPERATOR_INSTRUCTIONS",
);
export const RESET_DEFAULT_PAGE_PAGES = prefix.concat("RESET_PAGES");
export const CLEAR_SWIPE_INPUT_FIELD = prefix.concat("CLEAR_SWIPE_INPUT_FIELD");

export const getStoreDetails = (defaultValues) => {
    const { storeId, operatorId } = Helpers.getStoreDetails();

    return {
        storePosId: storeId || defaultValues.storeId,
        operatorId: operatorId || defaultValues.operatorId,
    };
};

export const displayError = (detailedMessage) => (dispatch) => {
    const hideIntervention = () => dispatch({ type: HIDE_INTERVENTION });
    const showDetailsIntervention = () =>
        dispatch({
            type: SHOW_INTERVENTION,
            description: detailedMessage,
            jsonFormat: true,
        });
    const children = (
        <div>
            <p className="description">
                An error has occurred. Press &quot;Show Details&quot; for more
                information. Please contact Stores Technology Support (STS) for
                any assistance required.
            </p>
            <div className="actions">
                <button
                    type="button"
                    className="small"
                    onClick={hideIntervention}
                >
                    OK
                </button>
                <button
                    type="button"
                    className="small dim"
                    onClick={showDetailsIntervention}
                >
                    Show Details
                </button>
            </div>
        </div>
    );
    dispatch({ type: SHOW_INTERVENTION, children });
};

const getUnitPriceAmount = (pageDetails, orderInformation) => {
    const amountNames = ["amount", "AMOUNT"];
    const pageDetail = Helpers.isArray(pageDetails)
        ? pageDetails.find((detail) => amountNames.includes(detail.name))
        : null;
    if (
        pageDetail &&
        orderInformation.amount === 0 &&
        (orderInformation.totalAmount === 0 || !orderInformation.totalAmount)
    ) {
        return parseFloat(pageDetail.formattedValue);
    }
    return parseFloat(orderInformation.amount);
};

export const addItemToPOS = (dispatch, getState) => {
    const { orderInformation, stageProduct, pageDetails } =
        getState().defaultPage;
    const hasIsTouchProduct =
        Object.keys(stageProduct).includes("isTouchProduct") &&
        stageProduct.isTouchProduct === false;
    const productCode = parseInt(stageProduct.stagedTransactionId);
    const onCloseBrowser = (responseText, error, timeout) => {
        if (!error && !timeout) {
            const response = JSON.parse(responseText);
            const returnCode = response[1].ReturnCode;
            const returnCodeDescription = response[1].ReturnCodeDescription;

            const formattedResponse = JSON.stringify(response, null, "\t");

            if (returnCode && returnCodeDescription) {
                const nextAction = () =>
                    dispatch(displayError(formattedResponse));
                dispatch(
                    voidProduct(
                        parseInt(stageProduct.stagedTransactionId),
                        nextAction,
                    ),
                );
            } else {
                dispatch(exitBrowser);
            }
        }
    };

    const addItemReq = {};
    addItemReq.Barcode = orderInformation.posCode;
    addItemReq.Description = orderInformation.productDescription;
    addItemReq.UnitPriceAmount = getUnitPriceAmount(
        pageDetails,
        orderInformation,
    );
    addItemReq.ExternalData = {
        "eService.eProductNumber": parseInt(productCode),
        "eService.eServiceTransactionNumber": parseInt(
            stageProduct.stagedTransactionId,
        ),
        "eService.description": orderInformation.productDescription,
        "eService.vendor": orderInformation.vendor,
        "eService.isAccepted": true,
    };

    if (orderInformation.subItems && orderInformation.subItems.length > 0) {
        addItemReq.SubItemList = [];
        orderInformation.subItems.forEach((subProductDetail, index) => {
            const subItemReq = {};

            const stagedTransactionIdStr = String(
                stageProduct.stagedTransactionId,
            );
            const stagedTransactionIdStripped =
                stagedTransactionIdStr.substring(
                    0,
                    stagedTransactionIdStr.length - 1,
                );
            const stagedTransactionId = parseInt(
                stagedTransactionIdStripped + String(index + 2),
            );

            subItemReq.Barcode = subProductDetail.posCode;
            subItemReq.Description = subProductDetail.description;
            subItemReq.UnitPriceAmount = parseFloat(subProductDetail.amount);
            subItemReq.ExternalData = {
                "eService.eProductNumber": hasIsTouchProduct
                    ? subProductDetail.transactionNumber
                    : stagedTransactionId,
                "eService.description": subProductDetail.description,
                "eService.vendor": orderInformation.vendor,
                "eService.isAccepted": true,
                "eService.parenteProductNumber": parseInt(productCode),
            };

            subItemReq.ExternalData["eService.eServiceTransactionNumber"] =
                hasIsTouchProduct
                    ? subProductDetail.transactionNumber
                    : stagedTransactionId;

            addItemReq.SubItemList.push(subItemReq);
        });
    }

    const onAddItemAction = (responseText) => {
        if (responseText && typeof responseText === "string") {
            ElectronicServices.AddItem(
                JSON.stringify(addItemReq),
                onCloseBrowser,
            );
        } else {
            setTimeout(() => dispatch(addItemToPOS), 50);
        }
    };

    cancelSwipe(onAddItemAction);
};

const isNullOrEmpty = (object) =>
    !object || (Helpers.isArray(object) && object.length === 0);

export const getProductDetails =
    (barcodeNumber, usingProductId, customErrorCallback, allowedCollection) =>
        (dispatch, getState) => {
            const { defaultValues } = getState().config;
            const successCallback = (response) => {
                const hasOperatorInstructions =
                    Array.isArray(response.operatorInstructions) &&
                    response.operatorInstructions.length > 0;
                if (response.error) {
                    const { errorMessage } = response.error;
                    dispatch({ type: ORDER_INFORMATION_FAILURE, errorMessage });
                    return;
                }
                if (usingProductId) {
                    const {
                        productFields,
                        operatorInstructions,
                        productDescription,
                    } = response;
                    dispatch({
                        type: ORDER_INFORMATION_SUCCESS,
                        orderInformation: response,
                    });

                    if (!isNullOrEmpty(productFields)) {
                        if (!allowedCollection) {
                            dispatch({
                                type: ADD_PAGE_STACK,
                                path: productDescription,
                            });
                        }
                        if (hasOperatorInstructions) {
                            initiationInstructions(
                                operatorInstructions,
                                productFields[0].displayOrder,
                                true,
                            );
                        } else {
                            dispatch({ type: NEXT_PAGE });
                        }
                    } else {
                        const dispatchStageProduct = hasOperatorInstructions
                            ? initiationInstructions(operatorInstructions, 0)
                            : () => dispatch(initiateStageProduct());
                        dispatch({ type: REMOVE_PAGE_STACK });
                        dispatchStageProduct();
                    }
                } else {
                    dispatch({
                        type: ORDER_INFORMATION_SUCCESS,
                        orderInformation: response,
                    });
                    if (!hasOperatorInstructions) {
                        dispatch(initiateStageProduct());
                        return;
                    }
                    dispatch({ type: NEXT_PAGE });
                }
            };

            const errorCallback = (error) => {
                dispatch({
                    type: ORDER_INFORMATION_FAILURE,
                    errorMessage: error.message,
                });
                if (customErrorCallback) {
                    customErrorCallback(error);
                }
            };

            const initiationInstructions = (
                instructions,
                displayOrder,
                hasProductField = false,
            ) => {
                dispatch({
                    type: INITIATE_OPERATOR_INSTRUCTIONS,
                    operatorInstructions: instructions,
                    displayOrder,
                    hasProductField,
                });
                dispatch({ type: NEXT_PAGE });
            };

            dispatch({ type: ORDER_INFORMATION_REQUEST });

            const productId = barcodeNumber;
            const getProductDetailsRequest = {
                ...getStoreDetails(defaultValues),
                productId,
            };

            if (!usingProductId) {
                getProductDetailsRequest.productId = barcodeNumber.substring(0, 8);
                getProductDetailsRequest.voucherReferenceNumber =
                    barcodeNumber.substring(8, barcodeNumber.length);
            }

            HttpUtils.restSendHandler(
                dispatch,
                RestHost.POST,
                "getProductDetails",
                getProductDetailsRequest,
                null,
                successCallback,
                errorCallback,
                RestHost.JSON,
            );
        };

export const getProductByBarcode =
    (barcodeNumber, entryMethod) => (dispatch, getState) => {
        const { defaultValues } = getState().config;
        const getProductByBarcodeRequest = {
            ...getStoreDetails(defaultValues),
            barcode: barcodeNumber,
            entryMethod,
        };

        const onGetProductByBarcodeError = ({ message }) => {
            const description = message || "Unable to add item to the basket";
            dispatch({ type: GET_PRODUCT_BY_BARCODE_FAILURE });
            dispatch({ type: SHOW_INTERVENTION, description });
        };

        const requiredKeys = [
            "stagedTransactionId",
            "productDescription",
            "productNumber",
            "posCode",
            "amount",
            "vendor",
        ];

        const getProductByBarcodeResponseValid = (response) => {
            const responseKeys = Object.keys(response);
            return requiredKeys.every((key) => responseKeys.includes(key));
        };

        const successCallback = (response) => {
            const { stagedTransactionId } = response;
            const stageProduct = { stagedTransactionId };
            if (
                Array.isArray(response.productFields) &&
                response.productFields.length
            ) {
                const { productFields } = response;
                if (productFields.length) {
                    productFields.forEach((element, index, arr) => {
                        if (element.dataType === "DECIMAL") {
                            // eslint-disable-next-line no-param-reassign
                            arr[index].formattedValue = "0.00";
                        }
                    });
                }
                dispatch({
                    type: GET_PRODUCT_BY_BARCODE_SUCCESS,
                    orderInformation: response,
                    stageProduct,
                    productFields,
                });
                dispatch({
                    type: "INSERT_PAGE",
                    startIndex: 2,
                    component: {
                        step: "AmountField",
                        title: "AmountField",
                        component: <ProductFieldEntry title="amountField" />,
                    },
                });
                dispatch({
                    type: "ADDITIONAL_PRODUCT_FIELD",
                    field: {
                        name: "BARCODE",
                        entryMethod,
                        value: barcodeNumber,
                    },
                });
                dispatch({ type: NEXT_PAGE });
            } else {
                // eslint-disable-next-line no-lonely-if
                if (getProductByBarcodeResponseValid(response)) {
                    dispatch({
                        type: GET_PRODUCT_BY_BARCODE_SUCCESS,
                        orderInformation: response,
                        stageProduct,
                    });
                    dispatch(addItemToPOS);
                } else {
                    onGetProductByBarcodeError(response);
                }
            }
        };

        dispatch({ type: GET_PRODUCT_BY_BARCODE_REQUEST });
        HttpUtils.restSendHandler(
            dispatch,
            RestHost.POST,
            "getProductByBarcode",
            getProductByBarcodeRequest,
            null,
            successCallback,
            onGetProductByBarcodeError,
            RestHost.JSON,
        );
    };

export const scanBarcode =
    (afterBarcodeScan, action, entryMethod = "B") =>
        (dispatch, getState) => {
            const errorCallback = () => dispatch({ type: SCAN_ERROR });
            const { scanTimeOutInSeconds, defaultValues } = getState().config;
            const { defaultGiftCardPrefixes } = defaultValues;
            const successCallback = (value) => {
                let scanValue = value;
                let isValid7ElevenGC = false;

                if (
                    entryMethod.includes("C") &&
                    value &&
                    value.toLowerCase().includes("track")
                ) {
                    const { track2 } = JSON.parse(value.toLowerCase());
                    isValid7ElevenGC = defaultGiftCardPrefixes.some((gcPrefix) =>
                        track2.includes(gcPrefix),
                    );
                    scanValue = track2;
                    if (isValid7ElevenGC) {
                        dispatch({
                            value: formatTrack2(scanValue),
                            type: action,
                            target: "barcode",
                            entryMethod,
                        });
                        dispatch(
                            afterBarcodeScan(
                                value,
                                false,
                                errorCallback,
                                entryMethod,
                            ),
                        );
                    } else {
                        dispatch({
                            value: "",
                            type: action,
                            target: "barcode",
                            entryMethod,
                        });
                        const hideIntervention = () => {
                            dispatch({ type: HIDE_INTERVENTION });
                            dispatch({ type: SCAN_ERROR });
                            dispatch({
                                type: CLEAR_SWIPE_INPUT_FIELD,
                                currentPageDetailsIndex: 0,
                            });
                            swipeRequest(
                                successCallback,
                                errorCallback,
                                allowedEntryMethods,
                                scanTimeOutInSeconds,
                            );
                        };
                        const children = (
                            <div>
                                <p className="description">
                                    Not a valid 7-Eleven Gift Card. Please try
                                    again.
                                </p>
                                <div className="actions">
                                    <button
                                        type="button"
                                        className="small"
                                        onClick={hideIntervention}
                                    >
                                        OK
                                    </button>
                                </div>
                            </div>
                        );
                        dispatch({ type: SHOW_INTERVENTION, children });
                    }
                } else {
                    dispatch({
                        value: formatTrack2(scanValue),
                        type: action,
                        target: "barcode",
                        entryMethod,
                    });
                    dispatch(
                        afterBarcodeScan(value, false, errorCallback, entryMethod),
                    );
                }
            };

            const allowedEntryMethods = entryMethod.split(";").map((method) => {
                const methodEquivalents = { C: "MagStripe", B: "Barcode" };
                return methodEquivalents[method];
            });

            // would request a MagStripe if C was given as entryMethod
            swipeRequest(
                successCallback,
                errorCallback,
                allowedEntryMethods,
                scanTimeOutInSeconds,
            );
        };

export const getEServiceMenuItems =
    (menuId, customSuccessCallback, operatorAuthId, goBack) =>
        (dispatch, getState) => {
            const { defaultValues } = getState().config;
            const { pageStack, pageStackItem } = getState().defaultPage;

            const getMenuItemsRequest = {
                ...getStoreDetails(defaultValues),
                menuId,
                ...(operatorAuthId && { operatorAuthId }),
            };

            const successCallback = (response) => {
                if (menuId) {
                    dispatch({ type: NEXT_MENU_ITEM, selectedMenuId: menuId });
                }
                dispatch({
                    type: "SET_PROMPT_OPERATOR_ID",
                    promptOperatorId: false,
                    pageStackItem: "",
                });
                dispatch({
                    type: MENU_ITEM_SUCCESS,
                    menuItems: response.menuItems,
                });
                if (pageStackItem)
                    dispatch({ type: ADD_PAGE_STACK, path: pageStackItem });
            };

            const accessDenied = (error) => {
                if (typeof error === "object" && error.code === "600") {
                    const handleOk = () => {
                        dispatch({ type: HIDE_INTERVENTION });
                        if (!pageStack.length) {
                            dispatch(onExitBrowser);
                            return;
                        }
                        if (goBack) goBack();
                    };
                    const children = (
                        <div>
                            <p className="description">{error.message}</p>
                            <button type="button" onClick={handleOk}>
                                OK
                            </button>
                        </div>
                    );
                    dispatch({ type: SHOW_INTERVENTION, children });
                    dispatch({
                        type: "SET_PROMPT_OPERATOR_ID",
                        promptOperatorId: false,
                        pageStackItem: "",
                    });
                }
            };

            const errorCallback = (error) => {
                dispatch({ type: MENU_ITEM_FAILURE, error });
                accessDenied(error);
            };

            const successCallbackFn = customSuccessCallback || successCallback;
            dispatch({ type: MENU_ITEM_REQUEST });
            HttpUtils.restSendHandler(
                dispatch,
                RestHost.POST,
                "menu/getEServiceMenu",
                getMenuItemsRequest,
                null,
                successCallbackFn,
                errorCallback,
                RestHost.JSON,
                false,
            );
        };

export const initiateStageProduct =
    (hasProductList) => (dispatch, getState) => {
        const {
            orderInformation,
            barcodeNumber,
            pageDetails,
            currentPageDetailsIndex,
            additionalProductFields,
        } = getState().defaultPage;
        const { defaultValues } = getState().config;

        const productId = barcodeNumber.substring(0, 8);
        const voucherReferenceNumber = barcodeNumber.substring(
            8,
            barcodeNumber.length,
        );
        const amountNames = ["amount", "AMOUNT"];
        const amountFound = Helpers.isArray(pageDetails)
            ? pageDetails.find((pages) => amountNames.includes(pages.name))
            : false;
        const shouldUseDifferentAmount = !!(
            orderInformation.amount === 0 &&
            amountFound &&
            amountFound.value
        );

        const amount = shouldUseDifferentAmount
            ? convertValueToDouble(amountFound.value)
            : orderInformation.totalAmount;
        const stageProductRequest = {
            ...getStoreDetails(defaultValues),
            productId: orderInformation.productNumber || productId,
            amount,
        };

        if (voucherReferenceNumber) {
            stageProductRequest.voucherReferenceNumber = voucherReferenceNumber;
        }

        const onStageProductError = ({ message }) => {
            const description = message || "Unable to add item to the basket";
            let continueAction = [{ type: SCAN_ERROR }];
            if (pageDetails.length) {
                const { name: currentProductFieldName } =
                    pageDetails[currentPageDetailsIndex];
                const pageDetail = pageDetails.find(
                    (productField) => productField.name === "MAGDATA",
                );
                // eslint-disable-next-line no-unneeded-ternary
                const { entryMethod: magdataEntryMethod } = pageDetail || {
                    entryMethod: null,
                };

                const isFromSwipe = magdataEntryMethod === "C";

                const clearInputFieldsAction = [
                    {
                        type: CLEAR_SWIPE_INPUT_FIELD,
                        currentPageDetailsIndex: 0,
                    },
                ];

                const prevPageAction = [{ type: PREV_PRODUCT_FIELD }];

                if (
                    amountNames.includes(currentProductFieldName) &&
                    isFromSwipe
                ) {
                    continueAction = [
                        ...continueAction,
                        ...clearInputFieldsAction,
                        ...prevPageAction,
                    ];
                }

                if (
                    amountNames.includes(currentProductFieldName) &&
                    magdataEntryMethod === "K"
                ) {
                    continueAction = [...continueAction, ...prevPageAction];
                }
                if (
                    pageDetails.length === 1 &&
                    amountNames.includes(currentProductFieldName)
                ) {
                    continueAction = [
                        { type: "DEFAULT_PAGE_PREV_PAGE" },
                        { type: FILL_UP_BARCODE, value: "" },
                    ];
                }

                if (currentProductFieldName === "MAGDATA" && isFromSwipe) {
                    continueAction = [
                        ...continueAction,
                        ...clearInputFieldsAction,
                    ];
                }
            }

            dispatch({ type: STAGE_PRODUCT_FAILURE });
            dispatch({ type: SHOW_INTERVENTION, description, continueAction });
        };

        const successCallback = (response) => {
            if (response.validationStatus === "SUCCESS") {
                dispatch({
                    type: STAGE_PRODUCT_SUCCESS,
                    stageProduct: response,
                });
                if (Object.keys(response).includes("extendedMessage")) {
                    const { customer, operator } = response.extendedMessage;
                    const onContinue = () => {
                        dispatch({ type: HIDE_INTERVENTION });
                        dispatch(addItemToPOS);
                    };
                    const children = (
                        <div>
                            <p className="custom-title">{customer}</p>
                            <p className="description">{operator}</p>
                            <div className="actions">
                                <button
                                    type="button"
                                    className="small"
                                    onClick={onContinue}
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    );

                    cancelScan();
                    customerDisplayCD(customer);
                    dispatch({ type: SHOW_INTERVENTION, children });
                } else {
                    dispatch(addItemToPOS);
                }
            } else {
                dispatch({ type: STAGE_PRODUCT_FAILURE });
                onStageProductError(response);
            }
        };

        if (hasProductList) {
            const productFields = pageDetails.map((details) => {
                if (details.name) {
                    return {
                        name: details.name,
                        value: details.formattedValue || details.value,
                        entryMethod: details.entryMethod,
                    };
                }
                return null;
            });
            if (Object.keys(additionalProductFields).length) {
                productFields.unshift(additionalProductFields);
            }
            stageProductRequest.productFields = productFields.filter(
                (field) => field,
            );
        }
        dispatch({ type: STAGE_PRODUCT_REQUEST });
        HttpUtils.restSendHandler(
            dispatch,
            RestHost.POST,
            "stageProduct",
            stageProductRequest,
            null,
            successCallback,
            onStageProductError,
            RestHost.JSON,
        );
    };

export const voidProduct =
    (eServiceTransactionNumber, nextActionCallback) => (dispatch) => {
        const voidProductRequest = { eServiceTransactionNumber };

        const errorCallback = () => {
            dispatch({ type: VOID_PRODUCT_FAILURE });
            nextActionCallback();
        };

        const successCallback = (response) => {
            if (response.result === "SUCCESS") {
                dispatch({ type: VOID_PRODUCT_SUCCESS });
                nextActionCallback();
            } else {
                errorCallback();
            }
        };

        dispatch({ type: VOID_PRODUCT_REQUEST });
        HttpUtils.restSendHandler(
            dispatch,
            RestHost.POST,
            "voidProduct",
            voidProductRequest,
            null,
            successCallback,
            errorCallback,
            RestHost.JSON,
        );
    };

export const cancelTransaction =
    (eServiceTransactionNumber) => (dispatch, getState) => {
        const { config, defaultPage } = getState();
        const { defaultValues, productInformationList } = config;
        const { transaction } = defaultPage;

        const cancelTransactionRequest = {
            ...getStoreDetails(defaultValues),
            transactionId: eServiceTransactionNumber,
        };

        const errorCallback = ({ message }) => {
            const description = message || "Failed to cancel transaction";
            dispatch({ type: CANCEL_TRANSACTION_FAILURE });
            dispatch({ type: SHOW_INTERVENTION, description });
        };

        const successCallback = (response) => {
            const productInformation = productInformationList.find(
                (productInfo) => productInfo.productName === transaction || productInfo.menuItemName === transaction,
            );
            const { operatorInstructions, operatorInstructionsReceipt } =
                productInformation;

            const printCancelledTransactionProps = {
                eServiceTransactionNumber,
                operatorInstructionsReceipt,
                ...response,
            };

            const orderInformation = { operatorInstructions };

            dispatch({ type: ORDER_INFORMATION_SUCCESS, orderInformation });
            dispatch({ type: CANCEL_TRANSACTION_SUCCESS });
            dispatch({ type: NEXT_PAGE });

            printCancelledTransaction(printCancelledTransactionProps);
        };

        dispatch({ type: CANCEL_TRANSACTION_REQUEST });
        HttpUtils.restSendHandler(
            dispatch,
            RestHost.POST,
            "cancelTransaction",
            cancelTransactionRequest,
            null,
            successCallback,
            errorCallback,
            RestHost.JSON,
        );
    };

export const supportRefundTransaction =
    (referenceNumber) => (dispatch, getState) => {
        const { config } = getState();
        const { defaultValues } = config;

        const storeDetails = getStoreDetails(defaultValues);
        const eServiceTransactionNumber =
            Helpers.getURLParameter("transactionNumber") ||
            defaultValues.transactionNumber;
        const refundTransactionRequest = {
            storeId: storeDetails.storePosId,
            operatorId: storeDetails.operatorId,
            refNo: referenceNumber,
            eServiceTransactionNumber,
        };

        const errorCallback = ({ message }) => {
            const description = message || "Failed to refund transaction";
            dispatch({ type: REFUND_TRANSACTION_FAILURE });
            dispatch({
                type: SHOW_INTERVENTION,
                description,
                shouldExitBrowser: true,
            });
        };

        const successCallback = (response) => {
            if (response.refundStatus !== "FAIL") {
                dispatch({ type: REFUND_TRANSACTION_SUCCESS });
                dispatch(exitBrowser);
            } else {
                errorCallback({ message: response.responseText });
            }
        };

        dispatch({ type: REFUND_TRANSACTION_REQUEST });
        HttpUtils.restSendHandler(
            dispatch,
            RestHost.POST,
            "refund",
            refundTransactionRequest,
            null,
            successCallback,
            errorCallback,
            RestHost.JSON,
        );
    };

export const cancelScan = () => cancelSwipe();

export const exitBrowser = (d, getState) => {
    const { pathname } = window.location;
    const refundFailed = getState().defaultPage.refundStatus !== "SUCCESS";
    const etenderFailed = getState().etender.stageEtenderStatus !== "SUCCESS";
    const currentPage = {
        "/etender": etenderFailed,
        "/refund": refundFailed,
    };

    const onResponse = (responseText) => {
        const oRequest = { Cancel: currentPage[pathname] };
        if (responseText && typeof responseText === "string") {
            ElectronicServices.CloseBrowser(JSON.stringify(oRequest));
        } else {
            setTimeout(() => exitBrowser(), 50);
        }
    };
    cancelSwipe(onResponse);
};


export const getImages =
    (params) =>
        (dispatch, getState) => {
            const { defaultValues, ncrWebServiceUrl } = getState().config;
            if (!defaultValues || !Object.keys(defaultValues).length) return;
            const customRequest = {
                ...getStoreDetails(defaultValues),
                menuId: params.menuId,
                allImages: false,
                extended: params.extended
            };

            const defaultRequest = {
                isAllImages: true
            }

            const successCallback = (response) => {
                let allImages = [];
                if (response?.data) {
                    allImages = response?.data
                }
                dispatch({ type: "GET_IMAGES_SUCCESS", allImages });
            };

            const request = params.allImages ? defaultRequest : customRequest;
            const errorCallback = (error) => {
                dispatch({ type: "GET_IMAGES_FAILURE", error });
            };

            dispatch({ type: "GET_IMAGES_REQUEST" });
            axios.get(ncrWebServiceUrl + "menu/getAllDisplayImages", {
                params: request,
                headers: {
                    ['Content-Type']: "application/json",
                    accept: "application/json"
                }
            }).then(successCallback).catch(errorCallback)

        };