const Timer = {
    active: false,
    interval: null,

    idleCallback: null,
    sessionCallback: null,

    init: (config, idleCallback, sessionCallback) => {
        window.Timer = {};
        window.Timer.idleCallback = idleCallback;
        window.Timer.sessionCallback = sessionCallback;
        Timer.idleCallback = idleCallback;
        Timer.sessionCallback = sessionCallback;
        const customerIdTimeOut = (config.defaultValues.customerExpiredTimeInMinute || 15) * 60000;
        getStorage().setItem("Timer.customerIdTimeOut", customerIdTimeOut);
        const idleTimeOut = (config.defaultValues.idleTimeInMinute || 15) * 60000;
        getStorage().setItem("Timer.idleTimeOut", idleTimeOut);
        Timer.checkTimeOut();
    },

    checkTimeOut: () => {
        const currentTime = new Date().getTime();
        const customerIdStartTime = getStorage().getItem("Timer.customerIdStartTime");
        const customerIdTimeOut = getStorage().getItem("Timer.customerIdTimeOut");
        const customerId = getStorage().getItem("customerId");
        if (customerIdStartTime && customerIdTimeOut) {
            if ((currentTime - customerIdStartTime) >= customerIdTimeOut) {
                Timer.onSessionTimeout();
                return;
            }
        } else if (!customerIdStartTime && customerId) {
            Timer.onSessionTimeout();
            return;
        }

        const idleStartTime = getStorage().getItem("Timer.idleStartTime");
        const idleTimeOut = getStorage().getItem("Timer.idleTimeOut");
        if (idleStartTime && idleTimeOut && customerId) {
            if ((currentTime - idleStartTime) >= idleTimeOut) {
                Timer.onIdleTimeout();
            }
        }
    },

    startCustomerIdTimerExpiration: () => {
        getStorage().setItem("Timer.customerIdStartTime", new Date().getTime());
        Timer.startIdleTimerExpiration();
    },

    startIdleTimerExpiration: () => {
        if (!Timer.active) {
            Timer.active = true;
            Timer.interval = window.setInterval(Timer.checkTimeOut, 1000);
        }

        getStorage().setItem("Timer.idleStartTime", new Date().getTime());
    },

    resetIdleStartTime: () => {
        Timer.clearIdleTimer();
        Timer.startIdleTimerExpiration();
    },

    clearCustomerIdExpirationTimer: () => {
        getStorage().removeItem("Timer.customerIdStartTime");
    },

    clearIdleTimer: () => {
        getStorage().removeItem("Timer.idleStartTime");
    },

    clearAll: () => {
        Timer.active = false;
        clearInterval(Timer.interval);

        Timer.clearCustomerIdExpirationTimer();
        Timer.clearIdleTimer();
    },

    onIdleTimeout: () => {
        if (window.opener && window.opener.Timer) {
            const idleCallback = window.opener.Timer.idleCallback;
            if (typeof idleCallback === "function") {
                idleCallback();
            }
        } else if (window.Timer) {
            const idleCallback = window.Timer.idleCallback;
            if (typeof idleCallback === "function") {
                idleCallback();
            }
        } else if (Timer.idleCallback) {
            const idleCallback = Timer.idleCallback;
            if (typeof idleCallback === "function") {
                idleCallback();
            }
        }
        Timer.clearAll();
    },

    onSessionTimeout: () => {
        if (window.opener && window.opener.Timer) {
            const sessionCallback = window.opener.Timer.sessionCallback;
            if (typeof sessionCallback === "function") {
                sessionCallback();
            }
        } else if (window.Timer) {
            const sessionCallback = window.Timer.sessionCallback;
            if (typeof sessionCallback === "function") {
                sessionCallback();
            }
        } else if (Timer.sessionCallback) {
            const sessionCallback = Timer.sessionCallback;
            if (typeof sessionCallback === "function") {
                sessionCallback();
            }
        }
        Timer.clearAll();
    },
};

const getStorage = () => localStorage || {
    getItem: () => undefined,
    setItem: () => {},
    removeItem: () => {},
};

document.onmousemove = Timer.resetIdleStartTime;
document.onkeypress = Timer.resetIdleStartTime;

export default Timer;
