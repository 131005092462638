import React from "react";
import { connect } from "react-redux";
import {
    HIDE_CONSOLE, CLEAR_CONSOLE_LOG, SHOW_INTERVENTION, HIDE_INTERVENTION,
} from "../actions";

const rowBackground = (actionType) => ({
    SUCCESS: "green",
    REQUEST: "darkgray",
    ERROR: "crimson",
}[actionType]);

const Debugger = ({
    showConsole, enableDebugMode, onHideConsole, onDisplayData, onClearData, consoleLogs,
}) => {
    let lastIdentifier = null;
    const showConsoleLog = showConsole && enableDebugMode;
    return (
        <div className="debugger-container" style={{ display: showConsoleLog ? "block" : "none" }}>
            <p className="title">Debugger</p>
            <div>
                <table>
                    <thead>
                        <tr>
                            <th className="td1">Action Type</th>
                            <th className="td2">Rest URL</th>
                            <th className="td3">Data</th>
                            <th className="td4">Request Type</th>
                            <th className="td5">Date</th>
                            <th className="td6">Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {consoleLogs.map((consoleLog, index) => {
                            const rowComponent = (
                                <tr key={`console-${index}`} style={{ background: rowBackground(consoleLog.actionType) }}>
                                    <td className="td1">{consoleLog.actionType}</td>
                                    <td className="td2">{consoleLog.restUrl}</td>
                                    <td className="td3"><button onClick={() => onDisplayData(consoleLog.data)}>View Data</button></td>
                                    <td className="td4">{consoleLog.sendType}</td>
                                    <td className="td5">{consoleLog.date}</td>
                                    <td className="td6">{consoleLog.time}</td>
                                </tr>
                            );
                            if (lastIdentifier !== consoleLog.identifier && index !== 0) {
                                lastIdentifier = consoleLog.identifier;
                                return [<br key={`console-${index}${index}`} />, rowComponent];
                            }
                            lastIdentifier = consoleLog.identifier;
                            return rowComponent;
                        })}
                    </tbody>
                </table>
            </div>
            <button className="clear" onClick={onClearData}>Clear Logs</button>
        </div>
    );
};

const displayData = (data) => (dispatch) => {
    const jsonData = JSON.stringify(data, null, "\t");
    const hideIntervention = () => dispatch({ type: HIDE_INTERVENTION });
    const children = (
        <div>
            <pre className="description">{jsonData}</pre>
            <button onClick={hideIntervention}>OK</button>
        </div>
    );
    dispatch({ type: SHOW_INTERVENTION, children, prioritizeDisplay: true });
};

const mapStateToProps = (state) => {
    return {
        enableDebugMode: state.config.enableDebugMode,
        showConsole: state.debugMode.showConsole,
        consoleLogs: state.debugMode.consoleLogs,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onDisplayData: (data) => dispatch(displayData(data)),
        onClearData: () => dispatch({ type: CLEAR_CONSOLE_LOG }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Debugger);
