/* eslint-disable react/button-has-type */
/* eslint-disable object-curly-newline */
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { HIDE_INTERVENTION, SHOW_INTERVENTION } from "../../../../actions";
import { deleteProductPricing, getCurrentPrice, getProductPricingAndRules, showIntervention, updateProductPricing } from "../../../../actions/pricingManagementAction";
import { onExitBrowser } from "../../../../components/Navigation";
import { formatDate, formatEffectiveDate } from "../../../../utils/dateUtils";
import { beautifyPrice } from "../../../../utils/helper";
import CustomNumKeyboard from "../../components/CustomNumKeyBoard";
import DatePicker from "../../components/DatePicker";
import useIdleHook from "../../../../utils/hooks/idleHook";
import { validatePrice } from "../../pricingManagementUtil";

const UpdatePricePage = () => {
    const dispatch = useDispatch();
    const { location, goBack } = useHistory();
    const { errorMessage, selectedProductPricing, newPriceValue, dateRange, selectedEndDate, currentPrice } = useSelector(({ pricingManagement }) => pricingManagement);
    // eslint-disable-next-line no-unused-vars
    const idleTimer = useIdleHook();
    const [isLoading, setIsLoading] = useState(true);
    const [showPriceInput, setShowPriceInput] = useState(true);
    const [hasStorePricing, setHasStorePricing] = useState(false);
    const [hasPricingRules, setHasPricingRules] = useState(false);
    const [dayDuration, setDayDuration] = useState(null);
    const [id, setId] = useState(null);

    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        const locationHasState = location && Object.keys(location.state).length;
        if (locationHasState) {
            const { state: { item: { productId } } } = location;
            setIsLoading(true);
            setId(productId);
            getProductPricing(productId);
        }
        return () => {
            dispatch({ type: "RESET_UPDATE_PRICING_PAGE" });
        };
    }, []);

    useEffect(() => {
        handleShowErrorInvervention();
    }, [errorMessage]);

    useEffect(() => {
        if (errorMessage) {
            dispatch({ type: "SET_PRICE_MANAGEMENT_ERROR", errorMessage: "" });
        }
    }, [newPriceValue, selectedEndDate, errorMessage]);

    useEffect(() => {
        setShowPriceInput(!currentPage);
    }, [currentPage]);

    useEffect(() => {
        if (!Object.keys(selectedProductPricing.storePricing).length) {
            setHasStorePricing(false);
        }
    }, [currentPrice]);

    const getProductPricing = (productId) => {
        const customSuccessCallback = (response) => {
            if (response.storePricing) {
                const { endDate, startDate } = response.storePricing;
                dispatch({ type: "SET_DATE_RANGE", dateRange: { start: new Date(startDate), end: endDate } });
                handleSelectEndDate(endDate);
                setHasStorePricing(true);
            }
            dispatch({ type: "SELECTED_PRODUCT_PRICING", selectedProductPricing: response });
            if (response.pricingRules) {
                const { dayDuration: duration } = response.pricingRules;
                setHasPricingRules(true);
                setDayDuration(duration);
            }
            setIsLoading(false);
            dispatch(getCurrentPrice(response));
            dispatch({ type: "PRODUCT_PRICING_RULES_SUCCESS" });
        };
        dispatch(getProductPricingAndRules({ productId, customSuccessCallback }));
    };

    const handleRemoveProductPricing = () => {
        const description = `Are you sure you want to remove the current promotional pricing for ${selectedProductPricing.productDescription}?`;
        const remove = () => dispatch(deleteProductPricing(id));
        confirmationIntervention(description, remove);
    };

    const handleUpdatePricing = () => {
        if (currentPage < 1) {
            if (!validInputPrice()) {
                dispatch({ type: "SET_PRICE_MANAGEMENT_ERROR", errorMessage: "Invalid price amount." });
                return;
            }
            setCurrentPage(1);
            return;
        }
        const request = {
            ...selectedProductPricing,
            price: newPriceValue,
            productId: id,
            endDate: selectedEndDate,
        };
        handleShowErrorInvervention();
        if (!errorMessage) {
            const description = `Are you sure you want to update ${selectedProductPricing.productDescription}?`;
            const updatePricing = () => dispatch(updateProductPricing(request));
            confirmationIntervention(description, updatePricing);
        }
    };

    const validInputPrice = () => {
        const inputValue = +newPriceValue;
        if (!inputValue) return false;
        if (hasPricingRules) return validatePrice(+newPriceValue, selectedProductPricing.pricingRules);
        return true;
    };

    const confirmationIntervention = (description, fn) => {
        const children = (
            // eslint-disable-next-line react/jsx-fragments
            <React.Fragment>
                <p className="description">
                    {description}
                </p>
                <button style={{ marginRight: 16, width: 140 }} onClick={() => dispatch({ type: HIDE_INTERVENTION })}>Cancel</button>
                <button style={{ width: 140 }} onClick={fn}>Yes</button>
            </React.Fragment>
        );
        showIntervention({ dispatch, children });
    };

    const dispatchSetPriceValue = (price) => dispatch({ type: "SET_PRICE_VALUE", newPriceValue: price });

    const handleGoBack = () => {
        if (currentPage === 1) {
            setCurrentPage(0);
            return;
        }
        if (errorMessage) dispatch({ type: "SET_PRICE_MANAGEMENT_ERROR", errorMessage: "" });
        goBack();
    };

    const handleShowErrorInvervention = () => {
        if (errorMessage) dispatch({ type: SHOW_INTERVENTION, description: errorMessage });
    };

    const handleSelectEndDate = (date) => dispatch({ type: "SET_SELECTED_ENDDATE", selectedEndDate: date });

    return (
        <div className="update-price-page-container">
            <h2 className="title">Item Price Management</h2>
            <div className="details-wrapper" style={{ marginBottom: !hasPricingRules ? 16 : "" }}>
                <div className="item-row">
                    <p className="label">Item:</p>
                    <p className="item-description">{selectedProductPricing.productDescription}</p>
                </div>
                <div className="item-row">
                    <p className="label">Current Price:</p>
                    <p className="item-description">
                        {beautifyPrice(currentPrice)}
                        {(hasStorePricing && dateRange.end) && (
                            <span className="promo-effectivity-date">
                                {" "}
                                *Promo price is effective until
                                {" "}
                                {formatEffectiveDate(dateRange.end)}
                            </span>
                        )}
                    </p>
                    {hasStorePricing && <button type="button" onClick={handleRemoveProductPricing} className="remove-btn">Remove</button>}
                </div>
                { hasPricingRules && (
                    <div className="item-row">
                        <p className="label">Allowed Price:</p>
                        <p className="item-description">
                            {`${beautifyPrice(selectedProductPricing.pricingRules.priceMin)} - ${beautifyPrice(selectedProductPricing.pricingRules.priceMax)}`}
                        </p>
                    </div>
                ) }
            </div>
            <div className="component-wrapper" style={{ ...(hasPricingRules && !showPriceInput) && { paddingTop: 4, paddingLeft: 4, paddingBottom: 0 } }}>
                {!isLoading ? (
                    // eslint-disable-next-line react/jsx-fragments
                    <Fragment>
                        {showPriceInput && <CustomNumKeyboard price={newPriceValue} setPriceValue={dispatchSetPriceValue} />}
                        {!showPriceInput && <DatePicker selectedEndDate={selectedEndDate} handleSelectEndDate={handleSelectEndDate} duration={dayDuration} />}
                    </Fragment>
                ) : <p>Loading</p>}
            </div>
            <div className="action-container">
                <button onClick={() => onExitBrowser(dispatch)} style={{ marginRight: 16 }} type="button"> Quit </button>
                <button onClick={handleGoBack} type="button"> Back </button>
                <span className="spacer" />
                <button onClick={handleUpdatePricing} type="button">Next</button>
            </div>
        </div>
    );
};

export default UpdatePricePage;
