/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { checkImageExist, getImage, isEllipsisActive } from "../utils/helper";

const MenuItemContent = (props) => {
    const { image, name, alt, itemPrice, showImageWithName, imageName } = props;
    const { allImages } = useSelector((state) => state.defaultPage);
    const [imageHasError, setImageHasError] = useState(false);
    const [imageHash] = useState(Math.floor(Math.random() * 1000000000));
    const nameToRender = name === "" ? null : name;
    const config = useSelector((state) => state.config);
    const imageContent = getImage(allImages, imageName);
    const imagePath = `resources/${image}?${imageHash}`;
    function displayoverlay() {
        const isDisplayOverlay =
            typeof config === "object" &&
            Object.keys(config).includes("overlayMenuNameWithImage") &&
            showImageWithName &&
            config.overlayMenuNameWithImage &&
            (imageName || image) &&
            !alt;

        return isDisplayOverlay;
    }

    const defaultItem = !imageHasError ? (
        <img
            src={imageName ? imageContent : image ? imagePath : ""}
            alt={alt}
            // style={{ width: "100%" }}
            // onError={() => setImageHasError(true)}

            onError={({ currentTarget }) => {
                if (!imageName && image) {
                    if (checkImageExist(image, config.ncrWebServiceUrl)) {
                        currentTarget.onerror = null;
                        return (currentTarget.src = imagePath);
                    }
                }
                setImageHasError(true);
            }}
            className="button-image"
        />
    ) : (
        nameToRender
    );

    return (
        <React.Fragment>
            {displayoverlay() && (imageName || image) && !imageHasError && (
                <p
                    className="overlay-menuname-with-image"
                    ref={(el) => isEllipsisActive(el, "13.3px", true)}
                >
                    {name}
                </p>
            )}
            {defaultItem}
            <span // this piece of code fix the issue of overlapping text
                style={{
                    visibility: "hidden",
                    position: "absolute",
                    top: 0,
                    right: 0,
                }}
            >
                {nameToRender}
            </span>
            {itemPrice > 0 && (
                <span className="item-price">${itemPrice.toFixed(2)}</span>
            )}
        </React.Fragment>
    );
};

export default MenuItemContent;
