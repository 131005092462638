/* eslint camelcase: 0 */

import HttpUtils from "./HttpUtils";
import Helpers from "./Helpers";

const RestHost = function RestHost(hostUrl, headers, cacheParam) {
    this.host = hostUrl;
    this.defaultHeaders = headers;
    this.includeResponseHeaders = false;
    const defaultCacheParam = __webpack_hash__;
    this.cacheParam = cacheParam ? window.encodeURIComponent(cacheParam) : defaultCacheParam;
    this.clearHeaders();
};

RestHost.onGoingRequestCount = 0;

RestHost.GET = "GET";
RestHost.PUT = "PUT";
RestHost.POST = "POST";
RestHost.DELETE = "DELETE";

RestHost.JSON = "JSON";
RestHost.XML = "XML";
RestHost.PROPERTIES = "PROPERTIES";
RestHost.TXT = "TXT";

RestHost.prototype.showLoadingBox = function showLoadingBox() {
    RestHost.onGoingRequestCount += 1;
};

RestHost.prototype.hideLoadingBox = function hideLoadingBox() {
    RestHost.onGoingRequestCount -= 1;
};

const tryParse = function tryParse(value) {
    try {
        return JSON.parse(value);
    } catch (e) {
        return value;
    }
};

RestHost.prototype.get = function get(restUrl, successCallback, errorCallback,
  responseFormat, processOnBackground) {
    if (!processOnBackground) {
        this.showLoadingBox();
    }

    const ccgHttpRequest = HttpUtils.createRequest();
    const restHost = this;
    ccgHttpRequest.onreadystatechange = () => {
        if (ccgHttpRequest.readyState !== 4) {
            return;
        }

        const windowTimeout = window.setTimeout(() => {
            if (!processOnBackground) {
                restHost.hideLoadingBox();
            }

            if (ccgHttpRequest.status !== 200 && ccgHttpRequest.status !== 204
                    && ccgHttpRequest.status !== 1223 && !(ccgHttpRequest.status === 0 && ccgHttpRequest.responseText)) {
                if (typeof errorCallback === "function") {
                    errorCallback(tryParse(ccgHttpRequest.responseText));
                }
                return;
            }

            const resp = ccgHttpRequest.responseText;
            if (typeof successCallback === "function") {
                const headers = ccgHttpRequest.getAllResponseHeaders();
                successCallback(restHost.processResponse(resp, responseFormat, headers));
            }

            window.clearTimeout(windowTimeout);
        }, 500);
    };

    ccgHttpRequest.open(RestHost.GET, this.host + this.addCacheParam(restUrl), true);
    for (const headerName in this.headers) { // eslint-disable-line guard-for-in,no-restricted-syntax
        ccgHttpRequest.setRequestHeader(headerName, this.headers[headerName]);
    }

    ccgHttpRequest.send();

    this.clearHeaders();
};

RestHost.prototype.post = function post(restUrl, requestData, successCallback,
  errorCallback, responseFormat, processOnBackground) {
    this.send(RestHost.POST, restUrl, requestData, successCallback,
    errorCallback, responseFormat, processOnBackground);
};

RestHost.prototype.send = function send(sendType, restUrl, requestData, successCallback,
  errorCallback, responseFormat, processOnBackground) {
    if (!processOnBackground) {
        this.showLoadingBox();
    }

    const ccgHttpRequest = HttpUtils.createRequest();
    const restHost = this;
    ccgHttpRequest.onreadystatechange = () => {
        if (ccgHttpRequest.readyState !== 4) {
            return;
        }

        const windowTimeout = window.setTimeout(() => {
            if (!processOnBackground) {
                restHost.hideLoadingBox();
            }

            if (ccgHttpRequest.status !== 200 && ccgHttpRequest.status !== 204
                    && ccgHttpRequest.status !== 1223 && !(ccgHttpRequest.status === 0 && ccgHttpRequest.responseText)) {
                if (typeof errorCallback === "function") {
                    errorCallback(tryParse(ccgHttpRequest.responseText));
                }
                return;
            }

            const resp = ccgHttpRequest.responseText;
            if (typeof successCallback === "function") {
                const headers = ccgHttpRequest.getAllResponseHeaders();
                successCallback(restHost.processResponse(resp, responseFormat, headers));
            }

            window.clearTimeout(windowTimeout);
        }, 500);
    };

    ccgHttpRequest.open(sendType, this.host + this.addCacheParam(restUrl), true);
    for (const headerName in this.headers) {  // eslint-disable-line guard-for-in,no-restricted-syntax
        ccgHttpRequest.setRequestHeader(headerName, this.headers[headerName]);
    }

    if (typeof requestData !== "undefined") {
        ccgHttpRequest.send(JSON.stringify(requestData));
    } else {
        ccgHttpRequest.send();
    }

    this.clearHeaders();
};

RestHost.prototype.setIncludeResponseHeaders = function setIncludeResponseHeaders(value) {
    this.includeResponseHeaders = value;
};

RestHost.prototype.addHeader = function addHeader(name, value) {
    this.headers[name] = value;
};

RestHost.prototype.removeHeader = function removeHeader(name) {
    delete this.headers[name];
};

RestHost.prototype.clearHeaders = function clearHeaders() {
    this.headers = this.defaultHeaders ? this.defaultHeaders : {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json;charset=UTF-8",
        "X-JSON-ERROR-RESPONSE-FORMAT": "true",
    };
};

RestHost.prototype.processResponse = function processResponse(resp, format, stringHeaders) {
    if (typeof format !== "undefined" && format === RestHost.PROPERTIES) {
        return this.processPropertiesResponse(resp, stringHeaders);
    } else if (typeof format !== "undefined" && (format === RestHost.XML || format === true)) {
        return this.processXMLResponse(resp, stringHeaders);
    } else if (typeof format !== "undefined" && format === RestHost.TXT) {
        return resp;
    }
    return this.processJSONResponse(resp, stringHeaders);
};

RestHost.prototype.processPropertiesResponse = function processPropertiesResponse(resp, stringHeaders) {
    const result = {};
    const { safari } = Helpers.navigator;
    const entries = safari ? resp.split(/\r\n/g) : resp.split(/\n|\r\n/g);;

    for (let i = 0; i < entries.length; i += 1) {
        const entry = entries[i].split(/=| = /g);
        if (entry[0] && (entry[0].indexOf("#") !== 0)) {
            result[entry[0]] = entry[1];
        }
    }
    if (this.includeResponseHeaders) {
        const headers = this.processHeaders(stringHeaders);
        result.headers = headers;
    }
    return result;
};

RestHost.prototype.processJSONResponse = function processJSONResponse(response, stringHeaders) {
    let jsonResponse = null;
    if (response) {
        jsonResponse = response.replace(/{"@xsi\.nil":"true"}/g, "null");
        jsonResponse = JSON.parse(jsonResponse);
        if (this.includeResponseHeaders) {
            const headers = this.processHeaders(stringHeaders);
            jsonResponse.headers = headers;
        }
    }
    return jsonResponse;
};

RestHost.prototype.processHeaders = function processHeader(stringHeaders) {
    const arr = stringHeaders.trim().split(/[\r\n]+/);
    const map = {};
    arr.forEach((line) => {
        const parts = line.split(": ");
        const header = parts.shift();
        const value = parts.join(": ");
        map[header] = value;
    });
    return map;
};

RestHost.prototype.addCacheParam = function addCacheParam(url) {
    let newUrl = url;
    if (this.cacheParam) {
        const delimiter = url.indexOf("?") >= 0 ? "&" : "?";
        newUrl = `${newUrl}${delimiter}cp=${this.cacheParam}`;
    }
    const msie = Helpers.getIEVersion();
    if (msie > 0) {
        const delimiter = url.indexOf("?") >= 0 ? "&" : "?";
        newUrl = `${newUrl}${delimiter}session=${msie}${new Date().getTime()}`;
    }
    return newUrl;
};

export default RestHost;
