import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { HIDE_INTERVENTION } from "../actions";
import { exitBrowser } from "../actions/defaultPage";

const DefaultInterventionContent = ({
    title,
    description,
    jsonFormat,
    shouldExitBrowser,
    continueLabel,
    onContinue,
    onExitBrowser,
}) => {
    const actionProps = {
        className: "small",
        onClick: shouldExitBrowser ? onExitBrowser : onContinue,
    };

    actionProps.to = continueLabel ? null : "/";
    actionProps.children = continueLabel || "OK";

    const descriptionText = description || "Unable to process your reuqest.";

    return (
        <div>
            <p className={`description ${jsonFormat ? "json" : ""}`}>
                {jsonFormat ? (
                    <pre style={{ whiteSpace: "pre-wrap" }}>
                        {descriptionText}
                    </pre>
                ) : (
                    descriptionText
                )}
            </p>
            <button {...actionProps} />
        </div>
    );
};

const Intervention = (props) => {
    const { children, className, show, showOnTop } = props;
    const defaultClassName = "intervention";
    const component = (
        <div
            style={showOnTop ? { zIndex: 2000 } : {}}
            className={
                className
                    ? `${className} ${defaultClassName}`
                    : `${defaultClassName}`
            }
        >
            <div className="centered">
                {children || <DefaultInterventionContent {...props} />}
            </div>
        </div>
    );
    return show ? component : null;
};

const mapStateToProps = (state) => {
    const {
        showIntervention,
        interventionTitle,
        interventionDescription,
        interventionChildren,
        interventionShouldExitBrowser,
        interventionClassName,
        interventionContinueLabel,
        showInterventionOnTop,
        interventionJsonFormat,
    } = state.ui;
    return {
        show: showIntervention,
        title: interventionTitle,
        description: interventionDescription,
        children: interventionChildren,
        className: interventionClassName,
        continueLabel: interventionContinueLabel,
        showOnTop: showInterventionOnTop,
        jsonFormat: interventionJsonFormat,
        shouldExitBrowser: interventionShouldExitBrowser,
    };
};

const onInterventionContinue = (dispatch, getState) => {
    const { interventionContinueAction } = getState().ui;
    const iterateDispatcher = () => {
        if (Array.isArray(interventionContinueAction)) {
            for (let i = 0; i < interventionContinueAction.length; i++) {
                dispatch(interventionContinueAction[i]);
            }
        }
    };
    if (interventionContinueAction) {
        const isString = typeof interventionContinueAction === "string";
        if (isString) {
            dispatch({ type: interventionContinueAction });
        } else {
            iterateDispatcher();
        }
    }
    dispatch({ type: HIDE_INTERVENTION });
};

const mapDispatchToProps = (dispatch) => {
    return {
        onContinue: () => dispatch(onInterventionContinue),
        onExitBrowser: () => dispatch(exitBrowser),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Intervention);
