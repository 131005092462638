import { SHOW_CONSOLE, HIDE_CONSOLE, HANDLE_CONSOLE_LOG, CLEAR_CONSOLE_LOG } from "../actions";

const defaultState = {
    currentIdentifer: 1,
    showConsole: false,
    consoleLogs: [],
};

const debugModeReducer = (state, action) => {
    const debugMode = state.debugMode || defaultState;

    switch (action.type) {
        case SHOW_CONSOLE:
            return Object.assign({}, debugMode, { showConsole: true });

        case HIDE_CONSOLE:
            return Object.assign({}, debugMode, { showConsole: false });

        case HANDLE_CONSOLE_LOG: {
            const { logProps } = action;
            const { currentIdentifer } = debugMode;
            const nextIdentifier = logProps.actionType !== "REQUEST" ? currentIdentifer : currentIdentifer + 1;

            logProps.identifier = nextIdentifier;
            return Object.assign({}, debugMode, {
                consoleLogs: [logProps, ...debugMode.consoleLogs],
                currentIdentifer: nextIdentifier,
            });
        }

        case CLEAR_CONSOLE_LOG: {
            return Object.assign({}, debugMode, { consoleLogs: [] });
        }

        default:
            return debugMode;
    }
};

export default debugModeReducer;
