import React from "react";
import { connect } from "react-redux";

import CustomProductField from "../../../components/CustomProductField";
import BarcodeNumberEntry from "../../../components/BarcodeNumber";

const ProductFieldEntry = ({ customProductField }) => {
    return customProductField ? <CustomProductField /> : <BarcodeNumberEntry />;
};

const mapStateToProps = (state, ownProps) => {
    const { orderInformation, defaultProduct } = state.defaultPage;
    const productFields = orderInformation && orderInformation.productFields;
    return {
        customProductField: ownProps.title || (productFields && productFields.length > 0 && !defaultProduct),
    };
};

export default connect(mapStateToProps)(ProductFieldEntry);
