import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { HIDE_INTERVENTION, SHOW_INTERVENTION } from "../../../../actions";
import {
    beautifyPrice,
    getUniqueFromArray,
    subTotal,
    parseQueryString,
} from "../../../../utils/helper";
import ScrollButton from "../../../../components/ScrollButton";
import useIdleHook from "../../../../utils/hooks/idleHook";
import { REMOVE_PAGE_STACK } from "../../../../actions/defaultPage";
import { useLocation } from "react-router";

const OrderDetails = () => {
    const { searchParam } = useSelector(({ ui }) => ui);
    const { allOrderFoods: orderedFoods, hotFoodProductFields } = useSelector(
        ({ hotFood }) => hotFood,
    );
    // eslint-disable-next-line no-unused-vars
    const idleTimer = useIdleHook();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize] = useState(6);
    const [lastPage, setLastPage] = useState(0);

    useEffect(() => {
        setLastPage(Math.ceil(orderedFoods.length / pageSize) - 1);
        if (orderedFoods.length <= pageSize) setCurrentPage(0);
    }, [orderedFoods.length]);

    const handleComplete = () => {
        const pathname = searchParam
            ? `/custom-productfields/${searchParam}`
            : "/custom-productfields";
        dispatch({
            type: "SET_PRODUCT_FIELDS",
            productFields: getUniqueFromArray(hotFoodProductFields, "name"),
        });
        history.push(pathname);
    };

    const handleRemoveHotFoodOrder = (productNumber, name, orderID) => {
        const handleYes = () => {
            dispatch({ type: "REMOVE_HOT_FOOD_ORDER", productNumber, orderID });
            dispatch({ type: HIDE_INTERVENTION });
        };
        const children = (
            <React.Fragment>
                <p className="title">
                    Are you sure you want to remove {name} on your basket?{" "}
                </p>
                <div className="actions-container">
                    <button
                        onClick={() => dispatch({ type: HIDE_INTERVENTION })}
                    >
                        Cancel
                    </button>
                    <button onClick={handleYes}>Yes</button>
                </div>
            </React.Fragment>
        );
        dispatch({ type: SHOW_INTERVENTION, children });
    };

    const handleGoBack = () => {
        if (history.location.search) {
            const queryString = parseQueryString(history.location.search);
            if (Object.keys(queryString).includes("pageTitle")) {
                dispatch({ type: REMOVE_PAGE_STACK });
            }
        }
        if (location?.state?.prevPage === "/hotfood-combo") {
            return history.push({ pathname: "/", search: location.search });
        }

        history.goBack();
    };

    return (
        <div className="order-details-container">
            <p className="order-details-title">Order Details</p>
            <p className="sub-title">Items:</p>
            <div className="item-list-container">
                <div className="item-list-wrapper">
                    <div className="item-label">
                        <span className="list-header">Product</span>
                        {/* <span className="list-header col">Price</span>
                        <span className="list-header col">Total Price</span> */}
                    </div>
                    {orderedFoods
                        .slice(
                            currentPage * pageSize,
                            currentPage * pageSize + pageSize,
                        )
                        .map(
                            ({
                                productDescription,
                                productNumber,
                                amount,
                                orderID,
                            }) => (
                                <div key={orderID} className="item-container">
                                    <span className="item-name-wrapper">
                                        <img
                                            onClick={() =>
                                                handleRemoveHotFoodOrder(
                                                    productNumber,
                                                    productDescription,
                                                    orderID,
                                                )
                                            }
                                            src="/resources/img/remove.png"
                                            alt=""
                                            width={40}
                                            height={40}
                                        />
                                        <p> {productDescription}</p>
                                    </span>
                                    {/* <p className="col">
                                        {typeof amount === "string"
                                            ? amount
                                            : beautifyPrice(amount)}
                                    </p>
                                    <p className="col">
                                        {typeof amount === "string"
                                            ? amount
                                            : beautifyPrice(+amount)}
                                    </p> */}
                                </div>
                            ),
                        )}
                </div>
                <ScrollButton
                    lastPage={lastPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
                {/* <div className="sub-total">
                    <p>Sub Total</p>
                    <span className="spacer" />
                    <p>${subTotal(orderedFoods)}</p>
                </div> */}
            </div>
            {/* <p className="pricing-message">
                Combo and Offer pricing will apply once order is complete.
            </p> */}
            <div className="action-container">
                <button type="button" onClick={handleGoBack}>
                    {" "}
                    Back{" "}
                </button>
                <span className="spacer" />
                {orderedFoods.length > 0 && (
                    <button type="button" onClick={handleComplete}>
                        Order Complete
                    </button>
                )}
            </div>
        </div>
    );
};

export default OrderDetails;
