import { RestHost } from "common-functions";

// import { SHOW_INTERVENTION } from "./";

export const VALIDATE = "VALIDATE";
export const VALIDATION_FAILED = "VALIDATION_FAILED";
export const CLEAR_VALIDATION_ERRORS = "CLEAR_VALIDATION_ERRORS";

export const VALIDATE_INPUTS_FORMAT = "VALIDATE_INPUTS_FORMAT";
export const RESET_INPUTS_FORMAT_VALIDATION = "RESET_INPUTS_FORMAT_VALIDATION";

export const VALIDATIONS_REQUEST = "VALIDATIONS_REQUEST";
export const VALIDATIONS_SUCCESS = "VALIDATIONS_SUCCESS";
export const VALIDATIONS_FAILURE = "VALIDATIONS_FAILURE";

export const validate = (successCallback, errorCallback) => (dispatch, getState) => {
    dispatch({ type: "VALIDATE" });
    const hasError = getState().ui.errors.length;

    if (!hasError && successCallback) {
        successCallback(dispatch, getState);
    } else if (hasError && errorCallback) {
        errorCallback(dispatch, getState);
    }
};

export const mergeErrors = (errors, error) => {
    const errorForFieldExists = errors.find((item) => item.fieldName === error.fieldName);
    return errorForFieldExists ? errors : [...errors, error];
};

export const validationFailed = (fieldName, message) => {
    return {
        type: VALIDATION_FAILED,
        error: { fieldName, message },
    };
};

export const validationsRequest = (dispatch, isProduction) => {
    const processOnBackground = true;
    dispatch({ type: VALIDATIONS_REQUEST, processOnBackground });

    const restHost = new RestHost("resources/config/", null, null);
    const validations = isProduction ? "validations.min.json" : "validations.json";

    restHost.get(`${validations}`,
        (response) => dispatch({ type: VALIDATIONS_SUCCESS, response, processOnBackground }),
        (error) => dispatch({ type: VALIDATIONS_FAILURE, error, processOnBackground }),
        RestHost.JSON,
        processOnBackground);
};
