/* CONSTANTS/ENUMS */
export const defaultProductMappingByPath = {
    "/kiosk": "KIOSK",
    "/giftcards": "GIFT_CARDS",
    "/cancel": "CANCELLATION",
    "/refund": "REFUND",
    default: "KIOSK",
};

export const transactionEnum = {
    KIOSK: "Kiosk",
    GIFT_CARDS: "GiftCards",
    CANCELLATION: "Cancellation",
    REFUND: "Refund",
    REFUND_REQUEST: "REFUND REQUEST",
};

export const inputFieldMappingForError = {
    [transactionEnum.CANCELLATION]: "E-Service transaction number",
    [transactionEnum.KIOSK]: "Voucher reference number",
    [transactionEnum.GIFT_CARDS]: "Barcode",
    [transactionEnum.REFUND]: "Barcode",
    "&cancel": "E-Service transaction number",
    "&kiosk": "Voucher reference number",
    "&giftcards": "Barcode",
    "&refund": "Barcode",
    "&scanproduct": "Barcode",
};
