/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import FullScreenWrapper from "../../components/FullScreenWrapper";
import OperatorIdPrompt from "../../components/interventions/OperatorIdPrompt";
import ScrollButton from "../../components/ScrollButton";
import CharacterKeyBoard from "../../components/VirtualCharKeyboard";
import { parseQueryString } from "../../utils/helper";
import useIdleHook from "../../utils/hooks/idleHook";
import {
    getNameDetails,
    getPriceManagementMenu,
} from "./pricingManagementUtil";
import { onExitBrowser } from "../../components/Navigation";
import MenuItemContent from "../../components/MenuItemContent";

const ProductPricingManagement = () => {
    const {
        ui: { searchParam },
        defaultPage: { pageStack, promptOperatorId, selectedMenuId },
        config: { productInformationList },
        pricingManagement: { operatorAuth, fromItemPricePage, fromMainMenu },
    } = useSelector((reduxStore) => reduxStore);

    const dispatch = useDispatch();
    const { location, goBack, push } = useHistory();
    // eslint-disable-next-line no-unused-vars
    const idleTimer = useIdleHook();
    const [showKeyboard, setShowKeyboard] = useState(false);
    const [searchKey, setSearchKey] = useState({ value: "" });
    const [isSearching, setIsSearching] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize] = useState(6);
    const [lastPage, setLastPage] = useState(0);
    const [products, setProducts] = useState([]);
    const [productsCopy, setProductsCopy] = useState([]);
    const [pageDetail, setPageDetail] = useState({ name: "", description: "" });
    const [menuID, setMenuID] = useState(null);
    const [isRequireAuth, setRequiredAuth] = useState(null);
    useEffect(() => {
        if (productInformationList && !pageStack.length) {
            getNameDetails("&pricemgmt", productInformationList, setPageDetail);
            const priceManagementDetails = productInformationList.find(
                ({ menuItemName }) => menuItemName === "&pricemgmt",
            );
            setRequiredAuth(priceManagementDetails.requireAuth);
        }
    }, [productInformationList]);

    useEffect(() => {
        setLastPage(Math.ceil(products.length / pageSize) - 1);
        if (products.length <= pageSize) setCurrentPage(0);
    }, [products.length]);

    useEffect(() => {
        if (typeof isRequireAuth === "boolean" && !pageStack.length) {
            if (isRequireAuth && !fromItemPricePage) {
                dispatch({
                    type: "SET_PROMPT_OPERATOR_ID",
                    promptOperatorId: true,
                });
            } else {
                getProducts();
            }
        }
    }, [isRequireAuth]);

    useEffect(() => {
        if (isSearching) {
            if (!searchKey.value.length) {
                setProducts(productsCopy);
                return;
            }
            const searchResult = products.filter(({ name }) =>
                name.toUpperCase().includes(searchKey.value.toUpperCase()),
            );
            setProducts(searchResult);
        }
    }, [isSearching]);

    useEffect(() => {
        if (!searchKey.value.length) {
            setProducts(productsCopy);
        }
    }, [searchKey]);

    useEffect(() => {
        initiateMenuID();
    }, []);

    const initiateMenuID = () => {
        let menuId = null;
        if (location.search) {
            const queryString = parseQueryString(location.search);
            if (queryString.menuId) menuId = queryString.menuId;
            if (queryString.storeId && queryString.operatorId) {
                const urlParam = `?storeId=${queryString.storeId}&operatorId=${queryString.operatorId}`;
                dispatch({ type: "SET_SEARCH_PARAM", searchParam: urlParam });
            }
        }
        if (location.state) {
            if (location.state.menuId) menuId = location.state.menuId;
            if (location.state.menuItemName)
                getNameDetails(
                    location.state.menuItemName,
                    productInformationList,
                    setPageDetail,
                );
        }
        setMenuID(menuId);
        if (!promptOperatorId) fetchProductDetialsOnInit(menuId);
    };

    const fetchProductDetialsOnInit = (menuId) => {
        if ((menuId && fromItemPricePage) || fromMainMenu) {
            getProducts();
        }
    };

    const handleSearch = () => {
        setIsSearching(true);
        setShowKeyboard(false);
        setTimeout(() => {
            setIsSearching(false);
        }, 50);
    };

    const navigateToUpdatePricePage = (item) => {
        const pathname = searchParam
            ? `/item-price/${searchParam}`
            : "/item-price";
        dispatch({
            type: "SET_FROM_UPDATE_PRICE_PAGE",
            fromItemPricePage: true,
        });
        push(pathname, { item });
    };

    const getProducts = () => {
        if (menuID || selectedMenuId) {
            dispatch(
                getPriceManagementMenu({
                    setProducts,
                    setProductsCopy,
                    menuId: menuID || selectedMenuId,
                    ...(operatorAuth && { operatorAuthId: operatorAuth }),
                }),
            );
        }
    };

    const handleGoBack = () => {
        dispatch({ type: "RESET_PRICE_MANAGEMENT" });
        goBack();
    };

    return (
        // eslint-disable-next-line react/jsx-fragments
        <React.Fragment>
            <div className="price-management-container">
                <h2 className="title">{pageDetail.name}</h2>
                <p className="description price-management-description">
                    {pageDetail.description}
                </p>

                <div
                    onClick={() => setShowKeyboard(true)}
                    className="search-bar"
                >
                    <input
                        type="input"
                        value={searchKey.value}
                        placeholder="Search here ..."
                        className="searchbar"
                    />
                    <div className="searchbar-icon-suffix">
                        <div className="vertical-line" />
                        <img
                            className="search-icon"
                            src="/resources/images/search-icon.png"
                            alt="search icon"
                        />
                    </div>
                </div>

                <div className="product-list-container">
                    <div className="product-list-wrapper">
                        {products.length > 0 &&
                            products
                                .slice(
                                    currentPage * pageSize,
                                    currentPage * pageSize + pageSize,
                                )
                                .map((product, index) => {
                                    const { image, name, isImageExists } =
                                        product;
                                    const productButtonClass = image
                                        ? "product-button product-button-background-image"
                                        : "product-button";
                                    const productNameClassName = isImageExists
                                        ? "product-name-with-image"
                                        : "product-name-without-image";
                                    // eslint-disable-next-line prefer-template
                                    return (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <div
                                            onClick={() =>
                                                navigateToUpdatePricePage(
                                                    product,
                                                )
                                            }
                                            key={`name-${index}`}
                                            className="product-button-wrapper"
                                        >
                                            {/* <div className={productButtonClass} style={{ backgroundImage: isImageExists ? `url(/resources/${image})` : "" }}>
                                            { !image && <p style={{ alignSelf: isImageExists ? "center" : "flex-end" }} className={productNameClassName}>{name}</p>}
                                        </div> */}
                                            <div className={productButtonClass}>
                                                <MenuItemContent {...product} />
                                            </div>
                                        </div>
                                    );
                                })}
                        {!products.length && (
                            <p className="no-product-found">
                                No product found.
                            </p>
                        )}
                    </div>
                    <ScrollButton
                        buttonSize={{ width: 72, height: 63 }}
                        lastPage={lastPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </div>
                <div style={{ marginTop: 30 }} className="action-container">
                    <button
                        onClick={() => dispatch(onExitBrowser)}
                        className="quit-button"
                    >
                        {" "}
                        Quit
                    </button>
                    {pageStack.length > 0 && (
                        <button onClick={handleGoBack}>Back</button>
                    )}
                </div>
            </div>
            {showKeyboard && (
                <FullScreenWrapper className="dialog-background">
                    <CharacterKeyBoard
                        value={searchKey.value}
                        pageDetail={{}}
                        onChange={setSearchKey}
                        errorMessage=""
                        title="Search Product"
                    />
                    <div className="action-container pricing-management-actions">
                        <button onClick={() => setShowKeyboard(false)}>
                            Back
                        </button>
                        <span className="spacer" />
                        <button onClick={handleSearch}>Search</button>
                    </div>
                </FullScreenWrapper>
            )}
            {promptOperatorId && (
                <OperatorIdPrompt
                    setProducts={setProducts}
                    setProductsCopy={setProductsCopy}
                    menuId={menuID}
                />
            )}
        </React.Fragment>
    );
};

export default ProductPricingManagement;
