import React, { useEffect, useState } from "react";

import VirtualKeyboard from "../../../components/VirtualKeyboard";

const CustomNumKeyboard = ({ price: originalPrice, setPriceValue }) => {
    const [price, setPrice] = useState("");
    const [formattedValue, setFormattedValue] = useState();

    useEffect(() => {
        setFormattedValue(formatValue(price));
    }, [price]);

    useEffect(() => {
        setPriceValue(formattedValue);
    }, [formattedValue]);

    useEffect(() => {
        const newPrice = Math.round(originalPrice * 100);
        setPrice(newPrice);
    }, []);

    const handleKeyPress = (value) => {
        switch (value) {
            case "DELETE":
                setPrice("");
                break;

            case "BACKSPACE":
                setPrice((prevState) => {
                    const priceCopy = String(prevState);
                    return priceCopy.slice(0, -1);
                });
                break;
            default:
                setPrice((prevState) => `${prevState}${value}`);
                break;
        }
    };

    const formatValue = (value) => parseFloat(value / 100).toFixed(2);

    const addValue = ({ target: { value } }) => {
        const val = String(value).replace(".", "");
        setPrice(val);
    };

    return (
        <div className="custom-numkeyboard-container">
            <input onChange={addValue} type="number" value={formattedValue} placeholder="0.00" className="customkeyboard-input" />
            <VirtualKeyboard onKeyPress={handleKeyPress} />
        </div>
    );
};

export default CustomNumKeyboard;
