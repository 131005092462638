import IconButton from "@mui/material/IconButton";
import ArrowRight from "@mui/icons-material/KeyboardDoubleArrowRight";
import ArrowLeft from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { Stack } from "@mui/system";
import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";

const ArrowButton = ({ icon, onClick, isDisabled, sx }) => (
    <IconButton
        onClick={onClick}
        disabled={isDisabled}
        sx={{
            position: "absolute",
            ...sx,
        }}
    >
        {icon}
    </IconButton>
);

function HotFoodTab({ handleTabClick, setTabTitle, containerSx }) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage] = React.useState(3);
    const [pageCount, setPageCount] = React.useState(0);
    const [tabMenus, setTabMenus] = React.useState([]);

    const { tabs, activeTabTitle } = useSelector(
        (state) => state.hotFoodCombo,
    );

    useEffect(() => {
        if (Array.isArray(tabs) && tabs.length) {
            setPageCount(Math.ceil(tabs.length / rowsPerPage));
            setSelectedTab(tabs[0].menuId);
        }
    }, [tabs]);

    const handleNextPage = () => setPage(page + 1);
    const handlePrevPage = () => setPage(page- 1);

    const onHandleTabClick = (tab, index) => {
        handleTabClick(tab, index);
        setSelectedTab(tab.menuId);
    };

    useEffect(() => {
        if (activeTabTitle) {
            const selectedTab = tabs.find((tab) => tab.name === activeTabTitle);
            if (selectedTab) setSelectedTab(selectedTab.menuId);
        }
    }, [activeTabTitle]);


    const setSelectedTab = (menuId) => {
        const tabMenuCopy = tabs.map((tab) => {
            tab.isSelected = false;
            if (tab.menuId === menuId) {
                tab.isSelected = true;
            }
            return tab;
        });

        setTabMenus(tabMenuCopy);
    };

    return (
        <Box sx={{ position: "relative", width: "560px", ...containerSx }}>
            {tabMenus.length > 3 && (
                <ArrowButton
                    isDisabled={page === 0}
                    sx={{ left: "-45px" }}
                    onClick={handlePrevPage}
                    icon={<ArrowLeft />}
                />
            )}
            <Stack spacing={2} direction={"row"}>
                {tabMenus
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((tab, index) => (
                        <button
                            style={{
                                height: "35px",
                                width:
                                    tab.columnSpan * 80 +
                                    (tab.columnSpan - 1) * 10,
                                ...(tab.isSelected && {
                                    color: "#fff",
                                    backgroundColor: "#ff8526",
                                }),
                            }}
                            className="menu-item"
                            key={tab.menuId}
                            onClick={() => onHandleTabClick(tab, index)}
                        >
                            {setTabTitle(tab.name)}
                        </button>
                    ))}
            </Stack>
            {tabMenus.length > 3 && (
                <ArrowButton
                    isDisabled={pageCount === page + 1}
                    sx={{ right: "-25px", top: 0 }}
                    onClick={handleNextPage}
                    icon={<ArrowRight />}
                />
            )}
        </Box>
    );
}

export default HotFoodTab;
