/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import HotFoodComboWithHideNavPage from "./HotComboWithHideNav";
import HotFoodComboWithNoHideTabPage from "./HotComboWithNoHideNav";
import { useDispatch } from "react-redux";

function HotFoodComboPage() {
    const { state } = useLocation();
    const [isHideNav, setHideNav] = useState(true);
    const dispatch = useDispatch();
    useEffect(() => {
        if (state.item) {
            const { extendedMenuItems } = state.item;
            const isHideNav = extendedMenuItems.some((item) => item.hideNav);
            setHideNav(isHideNav);
            dispatch({ type: 'HFC_SET_HIDE_PARENT_NAV', hideParentNav: isHideNav })
        }
    }, [state.item]);

    

    return isHideNav ? <HotFoodComboWithHideNavPage /> : <HotFoodComboWithNoHideTabPage />;
}

export default HotFoodComboPage;
