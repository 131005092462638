/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { getEServiceMenuItems } from "../../actions/defaultPage";
import { getPriceManagementMenu } from "../../containers/pricing-management/pricingManagementUtil";

import FullScreenWrapper from "../FullScreenWrapper";
import HeadTitle from "../HeadTitle";
import VirtualKeyboard from "../VirtualKeyboard";

const OperatorIdPrompt = ({
    setProducts,
    setProductsCopy,
    menuId,
    customContinue,
}) => {
    const { selectedMenuId } = useSelector(({ defaultPage }) => defaultPage);
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { goBack } = useHistory();

    const [inputValue, setInputValue] = useState("");
    const [error, setError] = useState(null);

    useEffect(() => {
        if (inputValue && inputValue.length) {
            setError(null);
        }
    }, [inputValue]);

    const handleSubmit = () => {
        if (!inputValue) {
            setError("Operator ID is required");
            return;
        }
        if (typeof customContinue === "function") return customContinue({
            operatorAuthId: inputValue
        });
        if (menuId) {
            dispatch(
                getPriceManagementMenu({
                    setProducts,
                    setProductsCopy,
                    menuId,
                    operatorAuthId: inputValue,
                    goBack,
                }),
            );
            return;
        }
        dispatch(getEServiceMenuItems(selectedMenuId, null, inputValue));
    };

    const handleOnChange = (data) => {
        // if (inputValue.length > 8) return;
        if (typeof data === "object") {
            const {
                target: { value },
            } = data;
            setInputValue(value);
            return;
        }
        handleKeyPress(data);
    };

    const handleKeyPress = (data) => {
        switch (data) {
            case "BACKSPACE":
                setInputValue((prevState) => prevState.slice(0, -1));
                break;
            case "DELETE":
                setInputValue("");
                break;
            default:
                setInputValue((prevState) => prevState + data);
                break;
        }
    };

    const closeOperotorPrompt = () => {
        if (pathname !== "/") goBack();
        dispatch({ type: "SET_PROMPT_OPERATOR_ID", promptOperatorId: false });
    };

    return (
        <FullScreenWrapper className="dialog-background">
            <HeadTitle
                className="prompt-operator-id-title"
                style={{ marginBottom: 30 }}
                textValue="Enter Operator ID"
            />
            <div className="prompt-input-container">
                <input
                    type="number"
                    value={inputValue}
                    onChange={handleOnChange}
                />
                <VirtualKeyboard onKeyPress={handleOnChange} />
                {error && (
                    <p className="input-error operatorid-prompt-error">
                        {error}
                    </p>
                )}
            </div>
            <div className="action-container pricing-management-actions">
                <button onClick={closeOperotorPrompt}>Back</button>
                <span className="spacer" />
                <button onClick={handleSubmit}>Next</button>
            </div>
        </FullScreenWrapper>
    );
};

export default OperatorIdPrompt;
