import React from "react";

const HotFoodQuitInterventionContent = ({
    allOrderFoods: orderedFoods,
    handleCancel,
    handleYes,
}) => (
    <div className="intervention-container">
        <p className="description">
            Are you sure you want to quit without adding{" "}
            {Array.isArray(orderedFoods) && orderedFoods.length > 1
                ? "items"
                : "item"}{" "}
            to the basket?
        </p>
        <div className="actions-container">
            <button onClick={handleCancel}>Cancel</button>
            <button onClick={handleYes}>Yes</button>
        </div>
    </div>
);

export default HotFoodQuitInterventionContent;
