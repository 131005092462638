import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import LoadingBox from "./components/LoadingBox";
import Debugger from "./components/Debugger";
import DebugButton from "./components/DebugButton";
import Intervention from "./components/Intervention";
import DefaultPage from "./containers/defaultPage/DefaultPage";
import ComingSoon from "./containers/ComingSoon";
import OrderDetails from "./containers/defaultPage/pages/hotFood/OrderDetails";
import CustomProductFields from "./containers/defaultPage/pages/customProductFields/customProductFields";
import ProductPricingManagement from "./containers/pricing-management";
import UpdatePricePage from "./containers/pricing-management/sub-pages/updatePrice";
import EtenderPage from "./containers/defaultPage/pages/etender";
import HotFoodComboPage from "./containers/hot-food-combo";
import { getImages } from "./actions/defaultPage";
import useMaintenanceMode from "./utils/hooks/useMaintenanceMode";
import { useDispatch, useSelector } from "react-redux";

document.onmousemove = null;
document.onkeypress = null;

const App = () => {
    const dispatch = useDispatch();
    const config = useSelector((state) => state.config);
    const { showMaintenanceIntervention, displayText, inMaintenaceMode } =
        useMaintenanceMode();

    useEffect(() => {
        if (inMaintenaceMode) {
            dispatch(showMaintenanceIntervention(displayText));
        }
    }, [inMaintenaceMode]);

    useEffect(() => {
        if (config) dispatch(getImages({ allImages: true }));
    }, [config]);

    return (
        <div className="container">
            {!inMaintenaceMode && (
                <Switch>
                    <Route exact path="/">
                        <DefaultPage />
                    </Route>
                    <Route exact path="/main">
                        <DefaultPage />
                    </Route>
                    <Route exact path="/kiosk">
                        <DefaultPage />
                    </Route>
                    <Route exact path="/cancel">
                        <DefaultPage />
                    </Route>
                    <Route exact path="/giftcards">
                        <DefaultPage />
                    </Route>
                    <Route exact path="/refund">
                        <DefaultPage />
                    </Route>
                    <Route exact path="/index.html">
                        <DefaultPage />
                    </Route>
                    <Route exact path="/comingSoon">
                        <ComingSoon />
                    </Route>
                    <Route exact path="/hot-food">
                        <OrderDetails />
                    </Route>
                    <Route exact path="/etender">
                        <EtenderPage />
                    </Route>
                    <Route exact path="/custom-productfields">
                        <CustomProductFields />
                    </Route>
                    <Route exact path="/pricing-management">
                        <ProductPricingManagement />
                    </Route>
                    <Route exact path="/item-price">
                        <UpdatePricePage />
                    </Route>
                    <Route exact path="/hotfood-combo">
                        <HotFoodComboPage />
                    </Route>
                </Switch>
            )}

            {!inMaintenaceMode && <LoadingBox />}
            <Intervention />
            <DebugButton />
            <Debugger />
        </div>
    );
};

export default App;
