import { format } from "date-fns";

export const formatDate = (date, dateFormat = "d MMMM yyyy") => format(date, dateFormat);

const splitDate = (date) => {
    const [year, month, day] = date.split("-");
    return new Date(year, month - 1, day);
};

export const formatEffectiveDate = (date, dateFormat = "d MMMM yyyy") => {
    const formattedDate = typeof date === "string" ? splitDate(date) : new Date(date);
    return format(formattedDate, dateFormat);
};
export const formatEndDate = (date) => (typeof date === "string" ? splitDate(date) : new Date(date));
