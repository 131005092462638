/* eslint-disable react/jsx-first-prop-new-line */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import CharacterKeyBoard from "../../../../components/VirtualCharKeyboard";
import HeadTitle from "../../../../components/HeadTitle";
import Navigation from "../../../../components/Navigation";
import { validateProductField } from "./utils";
import { stageHotFoodProductDetails } from "../../../../actions/hotFoodAction";
import useIdleHook from "../../../../utils/hooks/idleHook";

const CustomProductFields = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    // eslint-disable-next-line no-unused-vars
    useIdleHook();

    const { currentPageIndex, productFieldsCopy } = useSelector(({ productFieldsPage }) => productFieldsPage);

    const [currentProductField, setCurrentProductField] = useState({
        description: "",
        errorMessage: "",
    });

    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        setErrorMessage("");
        setCurrentProductField(productFieldsCopy[currentPageIndex]);
    }, [currentPageIndex]);

    const handleOnKeyboardChange = (item) => {
        dispatch({ type: "SET_PRODUCT_FIELD_VALUE", item });
    };

    const handleOnNext = () => {
        const newErrorMessage = dispatch(validateProductField);
        if (newErrorMessage) {
            setErrorMessage(newErrorMessage);
            return;
        }

        if (currentPageIndex < (productFieldsCopy.length - 1)) {
            dispatch({ type: "SET_CURRENT_PAGE_INDEX", currentPageIndex: currentPageIndex + 1, name: currentProductField.name });
            return;
        }
        dispatch(stageHotFoodProductDetails);
    };

    const handleGoBack = () => {
        if (!currentProductField.isSave) {
            currentProductField.value = "";
            dispatch({ type: "SET_PRODUCT_FIELD_VALUE", item: currentProductField });
        }
        if (currentPageIndex > 0 && currentPageIndex <= productFieldsCopy.length) {
            dispatch({ type: "SET_CURRENT_PAGE_INDEX", currentPageIndex: currentPageIndex - 1 });
            return;
        }
        history.goBack();
    };

    return (
        <div className="product-field">
            <div className="container">
                <div className="field-container">
                    <HeadTitle className="product-title"
                      style={{ marginBottom: 30 }} textValue={currentProductField.description} />
                    <CharacterKeyBoard pageDetail={currentProductField} onChange={handleOnKeyboardChange} errorMessage={errorMessage} />
                </div>
                <Navigation
                  onNext={handleOnNext}
                  onBack={handleGoBack} />
            </div>
        </div>
    );
};

export default CustomProductFields;
