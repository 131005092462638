import { Box } from "@mui/material";
import { HIDE_INTERVENTION, SHOW_INTERVENTION } from "../../../actions";

export function getCurentPageDetails(pageDetails, currentPageDetailsIndex) {
    return Array.isArray(pageDetails)
        ? pageDetails[currentPageDetailsIndex]
        : pageDetails;
}


export function getDefaultStoreDetails(defaultValues) {
    if (!defaultValues) {
        return {
            storeId: null,
            operatorId: null,
        };
    }
    return {
        storeId: defaultValues.storeId,
        operatorId: defaultValues.operatorId,
    };
}

export const showHotFoodComboIntervention = ({ tabName, dispatch, onGoBack, onContinue, preventNameSplit }) => {
    const [name] = preventNameSplit ? [tabName] : tabName ? tabName.split(" ") : [];
    const handleGoBack = () => {
        if (typeof onGoBack === 'function') onGoBack();
        dispatch({ type: HIDE_INTERVENTION });
    }

    const handleContinue = () => {
        if (typeof onContinue === 'function') onContinue();
        dispatch({ type: HIDE_INTERVENTION });
    }

    const children = (
        <>
            <p className="title">
                <Box
                    component={"span"}
                    sx={{
                        textTransform: "capitalize",
                        position: "relative !important",
                        fontSize: "28px !important",
                        top: "auto !important",
                    }}
                >
                    {typeof name === "string" ? name.toLowerCase() : ''}
                </Box>{" "}
                option added. Do you want to continue selecting Combo
                options?{" "}
            </p>
            <div className="actions-container">
                <button onClick={handleGoBack}>Back</button>
                <button
                    onClick={handleContinue}
                >
                    Yes
                </button>
            </div>
        </>
    );
    dispatch({ type: SHOW_INTERVENTION, children });
};


export const isRequiredAuth = ({ extendedMenuItems, dispatch, handleGetExtendedEserviceMenu, setSubMenuId }) => {
    const requiredAuths = extendedMenuItems.filter(item => item.requireAuth);

    if (requiredAuths.length) {
        setSubMenuId(requiredAuths[0].menuId);
        return dispatch({
            type: "SET_PROMPT_OPERATOR_ID",
            promptOperatorId: true,
            // pageStackItem: name.replace(/\s/g, ""),
        });
    }
    setSubMenuId(extendedMenuItems[0].menuId);
    handleGetExtendedEserviceMenu({ menuId: extendedMenuItems[0].menuId });
}


export function getTotalColumnSpan(menuItems = []) {
    if (!Array.isArray(menuItems) || !menuItems.length) return 0;
    const columns = menuItems.filter((item) => item.columnSpan);
    return columns.reduce((acc, item) => acc + item.columnSpan, 0);
}

export function setPageItem({ items, currentPage, lastRowCount, isHideTabs }) {
    // const rows = isHideTabs ? 4 : 3;
    const rows = 4;
    if (!Array.isArray(items) || !items.length) return { newItems: [], pageLength: 0 };
    const _items = setRowCount(items, isHideTabs);
    const pageLength = getPageLength(items, isHideTabs);
    const counters = Array.from(Array(pageLength)).map((_, i) => (i + 1) * rows);
    const newItems = _items.filter(
        (item) => item.row > lastRowCount && item.row <= counters[currentPage],
    );

    return { newItems, pageLength };
}

function setRowCount(menuItems = [], isHideTabs) {
    let total = 0;
    let row = 1;
    let tempArr = [];
    const columnPerPage = 6;
    menuItems.forEach((item) => {
        const colSpan = item.columnSpan ?? 1;
        total += colSpan;
        if (total > columnPerPage) {
            total = colSpan;
            row += 1;
        }
        tempArr.push({ ...item, row });
    });
    return tempArr;
}

function getPageLength(menuItems = [], isHideTabs) {
    // const rows = isHideTabs ? 4 : 3;
    const rows = 4;
    let total = 0;
    let row = 1;
    let tempArr = [];
    menuItems.forEach((item) => {
        const colSpan = item.columnSpan ?? 1;
        total += colSpan;
        if (total > 6) {
            total = colSpan;
            row += 1;
        }
        tempArr.push({ ...item, row });
    });

    const lastIndex = tempArr[tempArr.length - 1];
    return Math.ceil(lastIndex?.row / rows);
}

export function setTabLabel(name = "", productInformationList) {
    if (!name.includes("&")) return name;
    const [index0, description] = name.split("|");
    const productInfo = productInformationList.find((info) => info.menuItemName === index0);
    return description || productInfo?.productName;
}
