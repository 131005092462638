const InputValidator = [
    {
        name: "BARCODE",
        validationRegex: "^([0-9A-Za-z]{32}|[0-9A-Za-z]{30}|[0-9A-Za-z]{19})$",
        validationErrorMessage: "Barcode length is not valid",
    },
    {
        name: "AMOUNT",
        validationRegex: "^[1-9]\\d*(\\.\\d+)?$",
        validationErrorMessage: "Amount format error",
        format: (value) => (value ? parseFloat(value / 100).toFixed(2) : "0.00"),
    },
    {
        name: "7ELEVENGIFTCARD",
        validationGiftcards: (arr = [], str = "") => arr.some((prefix) => str.includes(prefix)),
        validationErrorMessage: "Not a valid 7-Eleven Gift Card. Please try again",
    },
];

export default InputValidator;
