/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import HeadTitle from "../../components/HeadTitle";

import Navigation from "../../components/Navigation";
import {
    REMOVE_PAGE_STACK,
} from "../../actions/defaultPage";
import HotFoodComboPageItems from "./components/RenderItems";
import HotFoodTab from "./components/HotFoodTab";
import CustomField from "./components/CustomField";
import { isRequiredAuth } from "./helpers/hotFoodCombo.utils";
import OperatorIdPrompt from "../../components/interventions/OperatorIdPrompt";
import useGetExtendedEserviceMenu from "./helpers/hooks/useGetExtendedEserviceMenu";


import "./hot-combo.css";
import usePrefetchGetExtendedEserviceMenu from "./helpers/hooks/usePrefetchGetExtendedEserviceMenu";
import { getUniqueFromArray } from "../../utils/helper";


function HotFoodComboWithHideNavPage() {
    const { state } = useLocation();
    const [title, setTitle] = useState("");
    const [selectedOption, setSelectedOption] = useState({});
    const [showBarcode, setShowBarcode] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();
    const { handleGetExtendedEserviceMenu } = useGetExtendedEserviceMenu();
    const { handlePrefetchGetExtendedEserviceMenu } = usePrefetchGetExtendedEserviceMenu();

    const { promptOperatorId } = useSelector((state) => state.defaultPage);
    const { isSuccessAuth, subMenuId, tabs, menuItems: items, hideNavigationTab: hideNav, tabsStack, prefetchedItems, orders, activeTabTitle: activeTabName, parentTabMenuId } = useSelector((state) => state.hotFoodCombo);

    const { productInformationList } = useSelector((state) => state.config);

    const setSubMenuId = (subMenuId) => dispatch({ type: "HFC_SET_SUB_MENU_ID", subMenuId });

    useEffect(() => {
        if (state.item) {
            const { name, extendedMenuItems } = state.item;
            isRequiredAuth({ extendedMenuItems, dispatch, handleGetExtendedEserviceMenu, setSubMenuId });
            const tabName = setTabLabel(extendedMenuItems[0].name);
            setTitle(name);
            dispatch({ type: "HFC_SET_ACTIVE_TAB_TITLE", title: tabName });
            // fetchProductDetails(extendedMenuItems); //for barcode scanning
            dispatch({ type: "HFC_ADD_TAB_STACK", tabs: extendedMenuItems });
            dispatch({ type: "HFC_SET_TABS", tabs: extendedMenuItems });
        }
    }, [state.item]);



    useEffect(() => {
        if (isSuccessAuth) {
            if (state?.item) {
                const extendedMenuItems = state?.item?.extendedMenuItems;
                const menuId = extendedMenuItems[0].menuId;
                const requiredMenus = extendedMenuItems.filter(item => item.requiredAuth);
                dispatch({ type: "HFC_ADD_PAGE_STACK", menuId: extendedMenuItems[0].menuId });
                if (menuId !== requiredMenus[0]?.menuId) {
                    handleGetExtendedEserviceMenu({ menuId });
                }
            }
        }
    }, [isSuccessAuth])

    useEffect(() => {
        if (tabs.length && subMenuId) {
            dispatch({ type: "HFC_ADD_PAGE_STACK", menuId: tabs[0].menuId })
            const isHideNav = tabs.some((item) => item.hideNav);
            dispatch({ type: "HFC_SET_HIDE_NAVIGATION_TABS", hideNavigationTab: isHideNav })
            const selectedTabs = tabs.find((tab) => tab.menuId === subMenuId);
            if (selectedTabs) setSelectedOption(selectedTabs);
        }
    }, [subMenuId, tabs]);


    function isMainTabFn(tabs = []) {
        if (state.item) {
            const { extendedMenuItems } = state.item;
            const extendedMenuIds = extendedMenuItems.map(item => item.menuId);
            const tabsMenuIds = tabs.map(item => item.menuId);
            return extendedMenuIds.every(item => tabsMenuIds.includes(item));
        };
        return false;
     }


    useEffect(() => {
        if (tabs.length) {
            const tab = tabs[0];
            const isMainTab = isMainTabFn(tabs);
            handlePrefetchGetExtendedEserviceMenu(tabs, isMainTab);
            if (tab && title) {
                const tabName = setTabLabel(tab.name);
                dispatch({ type: "HFC_SET_ACTIVE_TAB_TITLE", title: tabName });
            }
        }

        if (!tabs.length && tabsStack.length > 0) {
            let currentTab = null;
            currentTab = tabsStack[0].findIndex((tab) => {
                if (tab?.children && tab.children.includes(subMenuId)) {
                    return tab;
                }
            });
            if (currentTab === -1) currentTab = tabsStack[0].find((tab) => tab.menuId === subMenuId);
            if (typeof currentTab === 'object' && currentTab.name) {
                dispatch({ type: "HFC_SET_ACTIVE_TAB_TITLE", title: currentTab.name });
            }

        };
    }, [tabs]);

    useEffect(() => {
        dispatch({ type: "HFC_SET_HIDE_NAVIGATION_TABS", hideNavigationTab: !Boolean(parentTabMenuId) })

    }, [parentTabMenuId])

    // useEffect(() => {
    //     const uniqueOrders = getUniqueFromArray(orders, 'parentMenuId');
    //     if (!Array.isArray(tabsStack[0]) || !tabsStack[0].length) return;
    //     if (uniqueOrders.length >= tabsStack[0].length) {
    //         dispatch({ type: REMOVE_PAGE_STACK });
    //         history.goBack();
    //     }
    // }, [orders.length, tabsStack.length])

    useEffect(() => {
        return () => dispatch({ type: 'HFC_RESET_STATE' })
    }, [])

    function setTabLabel(name = "") {
        if (!name.includes("&")) return name;
        const [index0, description] = name.split("|");
        const productInfo = productInformationList.find((info) => info.menuItemName === index0);
        return description || productInfo?.productName;
    }


    const onBackPage = () => {
        const result = getMenuIdAndTabStack();
        if (result && result.menuId) {
            const { menuId, tabStack } = result;
            dispatch({ type: "HFC_REMOVE_PAGE_STACK" });
            if (tabStack) dispatch({ type: "HFC_SET_TABS", tabs: tabStack });
            return handleGetExtendedEserviceMenu({ menuId });
        }
        dispatch({ type: REMOVE_PAGE_STACK });
        history.goBack();
    };

    function getMenuIdAndTabStack() {
        let tabIndex = null;
        let menuId = null;
        tabIndex = tabsStack[0].findIndex((tab) => {
            if (tab?.children && tab.children.includes(subMenuId)) {
                return tab;
            }
        });

        if (tabIndex === -1) tabIndex = tabsStack[0].findIndex((tab) => tab.menuId === subMenuId);
        if (tabIndex <= 0) return null;
        menuId = tabsStack[0].filter((d, index) => index === (tabIndex - 1))[0].menuId;
        const tabStack = tabsStack.find((tab) => {
            return tab.find(t => t.parentMenuId === menuId)
        });

        return {
            menuId: menuId || null,
            tabStack: tabStack || []
        }
    }


    function handleTabClick(tab) {
        const tabName = setTabLabel(tab.name);
        dispatch({ type: "HFC_SET_ACTIVE_TAB_TITLE", title: tabName });
        if (isCustomFunction(tab.name)) {
            setShowBarcode(true);
            return;
        } else {
            const menuItems = prefetchedItems.find((item) => item.menuId === tab.menuId);
            setShowBarcode(false);
            if (menuItems) {
                return dispatch({ type: 'HFC_MENU_ITEMS', menuItems: menuItems.menuItems })
            }
          
            handleGetExtendedEserviceMenu({ menuId: tab.menuId });

        }
        setSubMenuId(tab.menuId);
    }

    const isCustomFunction = (name = "") => name.includes('&scanproduct');

    function truncateTitle(title, tab) {
        const fullTitle = `${title}__${tab}`;
        const maxLength = 40;
        const existingChar = Math.abs(fullTitle.length - maxLength);
        const char = '... - ';
        if (fullTitle.length <= maxLength) return fullTitle.replace('__', " - ");

        const toReplaceIndex = fullTitle.indexOf('__');
        const newTitle = fullTitle.slice(0, (toReplaceIndex - existingChar) - 4);
        const tabName = fullTitle.slice(toReplaceIndex + 2, fullTitle.length);

        return newTitle + char + tabName;
    }

    return (
        <div className={`default-page main-menu hot-food-combo-page`}>
            <HeadTitle
                className={"title"}
                style={{ marginBottom: "22px", alignSelf: "flex-start" }}
                textValue={truncateTitle(title, activeTabName)}
            />
            {!hideNav && <HotFoodTab
                containerSx={{
                    ...!showBarcode && {
                        transform: "translateX(-40px)",
                    },
                    ...(tabs.length > 3 &&
                    {
                        transform: `translateX(${!showBarcode ? -2 : 12}px)`,
                    }),
                }}
                handleTabClick={handleTabClick}
                setTabTitle={setTabLabel}
            />}
            {items.length <= 0 && !showBarcode && (
                <p>No available product...</p>
            )}
            {items.length > 0 && !showBarcode && (
                <HotFoodComboPageItems
                    sortedItems={items}
                    tabName={selectedOption?.name}
                    activeTabName={activeTabName}

                />
            )}

            {showBarcode && <CustomField />}

            <Navigation
                onBack={onBackPage}
                onNext={() => console.log("next")}
                showViewOrderButton={false}
                disableNext
            />
            {promptOperatorId && <OperatorIdPrompt customContinue={handleGetExtendedEserviceMenu} />}
        </div>
    );
}

export default HotFoodComboWithHideNavPage;
