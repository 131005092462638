export const formatTrack2 = (val) => {
    const value = val.replace(";", "");
    return value.split("=")[0];
};

export const convertValueToDouble = (val) => {
    let num = val || "0";
    const numLength = num.toString().length;
    if (numLength <= 1) {
        for (let x = numLength; x <= 1; x++) {
            num = `0${num}`;
        }
        num = `.${num}`;
    } else {
        num = `${num.substr(0, num.length - 2)}.${num.substr(num.length - 2, num.length)}`;
    }
    return parseFloat(num).toFixed(2);
};
