/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import RDatePicker from "react-datepicker";
import { subDays, addDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { lastEndDate } from "../pricingManagementUtil";
import { formatDate, formatEndDate } from "../../../utils/dateUtils";

const DatePicker = ({
    duration, handleSelectEndDate, selectedEndDate: endDate,
}) => {
    const dateNow = new Date();
    const [showIncludeDateInterval, setShowIncludeDateInterval] = useState(false);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    useEffect(() => {
        if (duration) {
            setShowIncludeDateInterval(true);
        }
        if (endDate) setSelectedEndDate(formatEndDate(endDate));
    }, []);

    const handleSelectDate = (date) => {
        setSelectedEndDate(date);
        handleSelectEndDate(date);
    };
    const datePickerProps = {
        ...showIncludeDateInterval && { includeDateIntervals: [{ start: subDays(dateNow, 1), end: addDays(dateNow, duration - 1) }] },
        minDate: dateNow,
        onChange: handleSelectDate,
        selected: selectedEndDate,
    };

    return (
        <div className="date-picker-container">
            <RDatePicker {...datePickerProps} inline fixedHeight className="date-picker-class" />
            <div className="left-container">
                { lastEndDate(duration) && <p style={{ padding: 8 }}>*Last end date allowed is { formatDate(lastEndDate(duration)) }</p>}
            </div>
        </div>
    );
};
export default DatePicker;
