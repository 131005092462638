import dateFormat from 'dateformat';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router";
import { exitBrowser } from '../../actions/defaultPage';
import { SHOW_INTERVENTION } from '../../actions';



const showMaintenanceIntervention = (displayText) => (dispatch, getState) => {
    console.log('get state.......', getState())
    const hideIntervention = () => exitBrowser(dispatch, getState);
    const children = (
        <div>
            <p className="description">{displayText}</p>
            <div className="actions">
                <button
                    type="button"
                    className="small"
                    onClick={hideIntervention}
                >
                    OK
                </button>
            </div>
        </div>
    );
    dispatch({ type: SHOW_INTERVENTION, children });
}

function checkDateRange({ maintenanceMode, setInMaintenanceMode, setDisplayText }) {
    const currentDate = dateFormat();
    const dateNow = new Date(currentDate);
    const startDate = new Date(
        dateFormat(maintenanceMode.startDate, "ddd mmm dd yyyy 00:00:01"),
    );
    const endDate = new Date(
        dateFormat(maintenanceMode.endDate, "ddd mmm dd yyyy 23:59:59"),
    );
    if (
        maintenanceMode.enabled &&
        Date.parse(dateNow.toString()) >= Date.parse(startDate.toString()) &&
        Date.parse(dateNow.toString()) <= Date.parse(endDate.toString())
    ) {
        setInMaintenanceMode(true);
        setDisplayText(maintenanceMode.displayText);

    }
}

function useMaintenanceMode() {
    const dispatch = useDispatch();
    const [inMaintenaceMode, setInMaintenanceMode] = useState(false);
    const [displayText, setDisplayText] = useState(false);
    const config = useSelector((state) => state.config);
    const loc = useLocation();
    useEffect(() => {
        if (typeof config === "object" && Object.keys(config).length) {
            const { maintenanceMode, etenderMaintenanceMode } = config;
            if (loc.pathname === '/etender') {
                return checkDateRange({ maintenanceMode: etenderMaintenanceMode, setInMaintenanceMode, setDisplayText });
            }
            return checkDateRange({ maintenanceMode, setInMaintenanceMode, setDisplayText });
        }
    }, [config]);





    // return { inMaintenaceMode };
    return { inMaintenaceMode, showMaintenanceIntervention, displayText };
}

export default useMaintenanceMode;