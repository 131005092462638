import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Helpers } from "common-functions";
import { useHistory } from "react-router";

import { transactionEnum } from "../../../utils/Constants";
import {
    NEXT_PAGE,
    PREV_MENU_ITEM,
    INITIATE_DEFAULT_PRODUCT,
    ORDER_INFORMATION_SUCCESS,
    getEServiceMenuItems,
    REMOVE_PAGE_STACK,
} from "../../../actions/defaultPage";
import Navigation from "../../../components/Navigation";
import MenuItemContent from "../../../components/MenuItemContent";
import MainMenuComponent from "../../../components/MainMenuComponent";
import HeadTitle from "../../../components/HeadTitle";

import MenuUtil from "../../../utils/MainMenuUtils";

const DefaultPage = ({
    onContinue,
    onPreviousItem,
    onGetMenuItems,
    selectedMenuStack,
    skipEServicesPage,
    defaultValues,
    menuItemProps,
    redirectTransactionKey,
    hideButtonWhileLoading,
    productInformationList,
    pageStack,
    setPromptOperatorId,
    setSelectedMenuId,
    fromMainMenu,
}) => {
    const { menuItemIndex, menuId } = menuItemProps;
    const {
        ui: { searchParam },
    } = useSelector((_state) => _state);
    const history = useHistory();
    const onMenuConfigurationChange = () => {
        if (skipEServicesPage) {
            onContinue(transactionEnum[redirectTransactionKey]);
        }
        if (
            defaultValues &&
            !menuItemProps.menuItemStack[0] &&
            !skipEServicesPage
        ) {
            onGetMenuItems(menuId);
        }
    };

    useEffect(onMenuConfigurationChange, [skipEServicesPage, defaultValues]);
    const [buildInfoVisible, setBuildInfoVisible] = useState(false);

    const defaultButtonTemplate = (additionalProps) => {
        const {
            imagePath,
            transaction,
            widthLength,
            productName,
            pageDetails,
            index,
            menuItemName,
            rawImagePath,
            imageName,
        } = additionalProps;
        const buttonName = (pageDetails && pageDetails.name) || productName;
        const defaultButtonStyle = { width: `${widthLength}px` };
        const handleContinue = () => {
            setSelectedMenuId(additionalProps.menuId);
            if (
                additionalProps.requireAuth &&
                !additionalProps.rawName.includes("&pricemgmt")
            ) {
                setPromptOperatorId(true);
                return;
            }
            if (menuItemName === "&pricemgmt" || transaction === "&pricemgmt") {
                const pathname = searchParam
                    ? `/pricing-management${searchParam}`
                    : "/pricing-management";
                const historyState = {
                    requireAuth: additionalProps.requireAuth,
                    menuId: additionalProps.menuId,
                    menuItemName: additionalProps.rawName,
                };
                history.push(pathname, historyState);
                fromMainMenu();
                if (additionalProps.requireAuth) setPromptOperatorId(true);
                return;
            }
            onContinue(transaction, pageDetails);
        };
        return (
            <button
                type="button"
                className="menu-item"
                style={widthLength ? defaultButtonStyle : null}
                onClick={handleContinue}
                key={`default-menu-item-${index}`}
            >
                <MenuItemContent
                    image={rawImagePath || imagePath}
                    name={buttonName}
                    alt={transaction}
                    imageName={imageName}
                />
            </button>
        );
    };

    const buttonActionMapping = MenuUtil.getMenuItemActionMapping(
        productInformationList,
        defaultButtonTemplate,
    );

    const componentCss = {
        visibility: `${hideButtonWhileLoading ? "hidden" : "visible"}`,
    };
    const directedToMenuItem =
        menuItemIndex === 1 && selectedMenuStack.length === 1 && menuId;

    const showBuildInfo = () => {
        setBuildInfoVisible(true);
        setTimeout(() => setBuildInfoVisible(false), 5000);
    };

    const buildInfo = buildInfoVisible ? <span>{`${""}`}</span> : null;

    return (
        <div className="main-menu">
            <HeadTitle
                className="title"
                style={componentCss}
                textValue={MenuUtil.getMenuTitle(
                    menuItemProps,
                    selectedMenuStack,
                )}
            />

            <MainMenuComponent
                componentCss={componentCss}
                buttonActionMapping={buttonActionMapping}
                menuItemProps={menuItemProps}
            />

            <Navigation
                disableBack={
                    menuItemIndex === 0 ||
                    directedToMenuItem ||
                    pageStack.length === 1
                }
                onBack={onPreviousItem}
                disableNext
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    const {
        currentMenuItemStackIndex,
        menuItemStack,
        selectedMenuStack,
        menuItemRequestCompleted,
        pageStack,
    } = state.defaultPage;
    const { maintenanceMode, productInformationList } = state.config;
    const menuItemProps = {
        menuId: Helpers.getURLParameter("menuId"),
        menuTitle: Helpers.getURLParameter("pageTitle"),
        menuItemIndex: currentMenuItemStackIndex,
        menuItemStack,
    };

    const { skipEServicesPage, transactionKey } =
        MenuUtil.checkIfShouldSkipEServicesPage(state);
    return {
        defaultValues: state.config.defaultValues,
        hideButtonWhileLoading: menuItemRequestCompleted === 0,
        redirectTransactionKey: transactionKey,
        productInformationList,
        skipEServicesPage,
        selectedMenuStack,
        menuItemProps,
        maintenanceMode,
        pageStack,
    };
};

const onContinueDefaultProduct =
    (transaction, customPageDetails) => (dispatch, getState) => {
        const { productInformationList } = getState().config;
        const productInformation =
            productInformationList.find(
                (product) => product.productName === transaction,
            ) || productInformationList[0];

        const detailsToUse = customPageDetails || productInformation;
        const { title, description, productName, operatorInstructionsOnly } =
            detailsToUse;

        dispatch({
            type: INITIATE_DEFAULT_PRODUCT,
            title,
            description,
            productName,
            transaction,
        });
        dispatch({ type: NEXT_PAGE });
        if (operatorInstructionsOnly) {
            const { operatorInstructions } = productInformation;
            const orderInformation = { operatorInstructions };
            dispatch({ type: ORDER_INFORMATION_SUCCESS, orderInformation });
            dispatch({ type: NEXT_PAGE });
        }
    };

const onPrevItemPage = (dispatch) => {
    dispatch({ type: PREV_MENU_ITEM });
    dispatch({ type: REMOVE_PAGE_STACK });
};

const mapDispatchToProps = (dispatch) => {
    return {
        onContinue: (transaction, customPageDetails) =>
            dispatch(onContinueDefaultProduct(transaction, customPageDetails)),
        onGetMenuItems: (menuId) => dispatch(getEServiceMenuItems(menuId)),
        onPreviousItem: () => onPrevItemPage(dispatch),
        // checkIfMaintenance: () => dispatch(checkForMaintenance()),
        setPromptOperatorId: (promptOperatorId) =>
            dispatch({ type: "SET_PROMPT_OPERATOR_ID", promptOperatorId }),
        setSelectedMenuId: (selectedMenuId) =>
            dispatch({ type: "SET_SELECTED_MENU_ID", selectedMenuId }),
        fromMainMenu: () =>
            dispatch({ type: "SET_FROM_MAIN_MENU", fromMainMenu: true }),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DefaultPage);
