import React from "react";
import { connect } from "react-redux";

const LoadingBox = ({ show }) => (
    <div style={{ display: show ? "block" : "none" }} className="loading-box">
        <div className="sk-circle">
            <div className="sk-circle1 sk-child"></div>
            <div className="sk-circle2 sk-child"></div>
            <div className="sk-circle3 sk-child"></div>
            <div className="sk-circle4 sk-child"></div>
            <div className="sk-circle5 sk-child"></div>
            <div className="sk-circle6 sk-child"></div>
            <div className="sk-circle7 sk-child"></div>
            <div className="sk-circle8 sk-child"></div>
            <div className="sk-circle9 sk-child"></div>
            <div className="sk-circle10 sk-child"></div>
            <div className="sk-circle11 sk-child"></div>
            <div className="sk-circle12 sk-child"></div>
        </div>
    </div>
);

const mapStateToProps = (state) => {
    const { onGoingRequestCount } = state.ui;
    return { show: onGoingRequestCount && onGoingRequestCount > 0 };
};

export default connect(mapStateToProps, null)(LoadingBox);
