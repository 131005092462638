import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Helpers } from "common-functions";

import VirtualKeyboard from "./VirtualKeyboard";
import Navigation from "./Navigation";
import HeadTitle from "./HeadTitle";
import MenuUtil from "../utils/MainMenuUtils";

import { inputFieldMappingForError, transactionEnum } from "../utils/Constants";
import {
    FILL_UP_BARCODE,
    RESET_BARCODE_STATE,
    PREV_PAGE,
    ORDER_INFORMATION_FAILURE,
    getProductByBarcode,
    getProductDetails,
    cancelTransaction,
    supportRefundTransaction,
    scanBarcode,
    cancelScan,
    REMOVE_PAGE_STACK,
} from "../actions/defaultPage";
import { SHOW_INTERVENTION } from "../actions";

const BarcodeNumber = ({
    barcodeNumber,
    skipEServicesPage,
    pageDetails,
    scanRetries,
    errorMessage,
    allowScan,
    customErrorMessage,
    onShowErrorMessage,
    onCancelScanVoucher,
    onStartScanVoucher,
    orderHasError,
    onBarcodeChange,
    onAddItem,
    onPrevious,
    onNumpadPress,
    initialDefaultProduct,
}) => {
    useEffect(() => {
        if (allowScan) {
            onStartScanVoucher();
        }
        return onCancelScanVoucher;
    }, [scanRetries]);

    useEffect(() => {
        if (customErrorMessage) {
            onShowErrorMessage(customErrorMessage);
        }
    }, []);

    const handleOnChange = (event) => {
        onBarcodeChange(event);
    };
    const handleOnKeyPress = (key) => {
        onNumpadPress(key);
    };

    return (
        <div className="barcode-number">
            <div className="container">
                <div className="field-container">
                    <HeadTitle
                        className="title"
                        textValue={
                            pageDetails.title || initialDefaultProduct.title
                        }
                    />
                    <label htmlFor="barcode-value">
                        {pageDetails.description ||
                            initialDefaultProduct.description}
                    </label>
                    <input
                        id="barcode"
                        name="barcode"
                        onChange={handleOnChange}
                        value={barcodeNumber}
                        type="text"
                    />
                    <VirtualKeyboard
                        onKeyPress={handleOnKeyPress}
                        onSubmit={() => onAddItem(barcodeNumber)}
                    />
                    <div
                        className="voucher-error"
                        style={{ display: orderHasError ? "" : "none" }}
                    >
                        <p>{errorMessage || "Unknown voucher."}</p>
                    </div>
                </div>
                <Navigation
                    disableBack={skipEServicesPage}
                    onNext={() => onAddItem(barcodeNumber)}
                    onBack={onPrevious}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { skipEServicesPage } =
        MenuUtil.checkIfShouldSkipEServicesPage(state);

    const scanNotAllowedTransactions = [
        transactionEnum.CANCELLATION,
        transactionEnum.REFUND,
        "&cancel",
    ];
    const allowScan = !scanNotAllowedTransactions.includes(
        state.defaultPage.transaction,
    );
    const customRefundErrorMessage = Helpers.getURLParameter("errorMessage");
    return {
        allowScan,
        skipEServicesPage,
        customErrorMessage: customRefundErrorMessage,
        scanRetries: state.defaultPage.scanRetries,
        pageDetails: state.defaultPage.pageDetails,
        errorMessage: state.defaultPage.errorMessage,
        orderHasError: state.defaultPage.orderHasError,
        barcodeNumber: state.defaultPage.barcodeNumber,
        initialDefaultProduct: state.defaultPage.initialDefaultProduct,
    };
};

const onContinueBarcodePage =
    (barcodeNumber, usingProductId, errorCallback, entryMethod) =>
    (dispatch, getState) => {
        const { transaction } = getState().defaultPage;
        const eServiceTransactionNumber = barcodeNumber;

        if (transaction === transactionEnum.KIOSK || transaction === "&kiosk") {
            dispatch(
                getProductDetails(barcodeNumber, usingProductId, errorCallback),
            );
        } else if (
            transaction === transactionEnum.GIFT_CARDS ||
            transaction === "&giftcards"
        ) {
            dispatch(getProductByBarcode(barcodeNumber, entryMethod || "K"));
        } else if (
            transaction === transactionEnum.CANCELLATION ||
            transaction === "&cancel"
        ) {
            dispatch(cancelTransaction(eServiceTransactionNumber));
        } else if (
            transaction === transactionEnum.REFUND ||
            transaction === "&refund"
        ) {
            dispatch(supportRefundTransaction(eServiceTransactionNumber));
        }
    };

const onBarcodeChange = (event, numpad) => (dispatch, getState) => {
    const { config, defaultPage } = getState();
    const { productName } = defaultPage;

    let productInfo = config.productInformationList.find(
        (product) => product.productName === productName,
    );
    productInfo =
        productInfo ||
        config.productInformationList.find(
            (product) => product.menuItemName === productName,
        );

    if (numpad) {
        dispatch({
            type: FILL_UP_BARCODE,
            value: event,
            source: "keyboard",
            target: "barcode",
            validation: productInfo.validation,
        });
    } else {
        const { value } = event.target;
        dispatch({
            type: FILL_UP_BARCODE,
            value,
            target: event.target.id,
            validation: productInfo.validation,
        });
    }
};

const validate = (barcodeNumber, successCallback) => (dispatch, getState) => {
    const { transaction, productName } = getState().defaultPage;
    const { productInformationList } = getState().config;
    if (barcodeNumber) {
        let productInfo = productInformationList.find(
            (product) => product.productName === productName,
        );
        productInfo =
            productInfo ||
            productInformationList.find(
                (product) => product.menuItemName === transaction,
            );

        const { validation } = productInfo;
        if (barcodeNumber.length < validation.minLength) {
            dispatch({
                type: ORDER_INFORMATION_FAILURE,
                errorMessage: `Minimum length is ${validation.minLength}`,
            });
        } else {
            dispatch(successCallback(barcodeNumber));
        }
    } else {
        const inputField = inputFieldMappingForError[transaction];
        dispatch({
            type: ORDER_INFORMATION_FAILURE,
            errorMessage: `${inputField} is required`,
        });
    }
};

const onPrevious = (dispatch) => {
    dispatch({ type: REMOVE_PAGE_STACK });
    dispatch({ type: RESET_BARCODE_STATE });
    dispatch({ type: PREV_PAGE });
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAddItem: (barcodeNumber) =>
            dispatch(validate(barcodeNumber, onContinueBarcodePage)),
        onStartScanVoucher: () =>
            dispatch(scanBarcode(onContinueBarcodePage, FILL_UP_BARCODE)),
        onCancelScanVoucher: () => dispatch(cancelScan),
        onNumpadPress: (key) => dispatch(onBarcodeChange(key, true)),
        onBarcodeChange: (event) => dispatch(onBarcodeChange(event)),
        onShowErrorMessage: (errorMessage) => {
            dispatch({
                type: SHOW_INTERVENTION,
                description: errorMessage,
                shouldExitBrowser: true,
            });
        },
        onPrevious: () => dispatch(onPrevious),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(BarcodeNumber);
