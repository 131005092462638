import React, { useState } from "react";
import { connect } from "react-redux";
import { transactionEnum } from "../../../utils/Constants";
import {
    PREV_PAGE,
    initiateStageProduct,
    exitBrowser,
} from "../../../actions/defaultPage";
import Navigation from "../../../components/Navigation";

const OperatorInstructions = ({
    operatorInstructions,
    transaction,
    instructionsOnly,
    customTitle,
    onPreviousPage,
    onFinalizeTransaction,
}) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const instruction = operatorInstructions[currentIndex];

    // const splitValues = instruction.split(/\n/);

    const lastPageReached = currentIndex === operatorInstructions.length - 1;
    const onNext = () =>
        lastPageReached
            ? onFinalizeTransaction(transaction)
            : setCurrentIndex(currentIndex + 1);
    const onBack = () =>
        currentIndex === 0
            ? onPreviousPage(instructionsOnly)
            : setCurrentIndex(currentIndex - 1);

    const title = instructionsOnly ? customTitle : "Instructions: ";
    return (
        <div
            className={`operator-instructions ${
                instructionsOnly ? "custom-style" : ""
            }`}
        >
            <div className="operator-instructions-container">
                <div>
                    <h2 className="title">{title}</h2>
                    <pre className="instruction">{instruction}</pre>
                </div>
            </div>

            <Navigation onBack={onBack} onNext={onNext} />
        </div>
    );
};

const mapStateToProps = (state) => {
    const { transaction, orderInformation } = state.defaultPage;
    const { productInformationList } = state.config;
    const { operatorInstructions } = orderInformation;

    const productInformation =
        productInformationList.find(
            (product) => product.productName === transaction,
        ) || {};
    const { operatorInstructionsOnly, title } = productInformation;
    return {
        transaction,
        operatorInstructions,
        instructionsOnly: operatorInstructionsOnly,
        customTitle: title,
    };
};

const finalizeTransction = (transaction) => (dispatch) => {
    const transactionsToExit = [
        transactionEnum.CANCELLATION,
        transactionEnum.REFUND_REQUEST,
        "&cancel",
    ];
    if (transaction === transactionEnum.KIOSK) {
        dispatch(initiateStageProduct());
    } else if (transactionsToExit.includes(transaction)) {
        dispatch(exitBrowser);
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onPreviousPage: (instructionsOnly) => {
            const defaultStep = 1;
            const navigateStep = instructionsOnly
                ? defaultStep + 1
                : defaultStep;
            dispatch({ type: PREV_PAGE, step: navigateStep });
        },
        onFinalizeTransaction: (transaction) =>
            dispatch(finalizeTransction(transaction)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OperatorInstructions);
