/* eslint-disable react/jsx-fragments */
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import { useLocation } from "react-router";

import VirtualKeyboard from "./VirtualKeyboard";
import Navigation from "./Navigation";
import HeadTitle from "./HeadTitle";
import MenuUtil from "../utils/MainMenuUtils";

import { SHOW_INTERVENTION } from "../actions";
import {
    FILL_UP_PRODUCT_FIELD,
    PREV_PAGE,
    CUSTOM_PRODUCT_FIELD_ERROR,
    NEXT_PRODUCT_FIELD,
    PREV_PRODUCT_FIELD,
    SCAN_ERROR,
    initiateStageProduct,
    scanBarcode,
    cancelScan,
    CLEAR_SWIPE_INPUT_FIELD,
    REMOVE_PAGE_STACK,
    ADD_PAGE_STACK,
} from "../actions/defaultPage";
import { inputValidation, removeLeadingZero } from "../utils/helper";
import useIdleHook from "../utils/hooks/idleHook";
import CharacterKeyBoard from "./VirtualCharKeyboard";

const CustomProductField = ({
    skipEServicesPage,
    scanRetries,
    hasError,
    currentPageDetails,
    allowScan,
    onCancelScanVoucher,
    onStartScanVoucher,
    onFieldChange,
    onPreviousPage,
    onNextPage,
    onNumpadPress,
    showOrderDetails,
    onCharacterKeyBoardChange,
    currentPageDetailsIndex,
}) => {
    // eslint-disable-next-line no-unused-vars
    useIdleHook();
    const [shouldDisplayError, setShouldDisplayError] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const isEtenderPage = location.pathname === "/etender";
    useEffect(() => {
        if (allowScan) {
            onStartScanVoucher();
            return onCancelScanVoucher;
        }
    }, [scanRetries]);

    useEffect(() => {
        if (hasError && currentPageDetails.errorMessage) {
            setShouldDisplayError(true);
        }
    }, [hasError, currentPageDetails.errorMessage]);

    useEffect(() => {
        resetErrorState();
        return () => resetErrorState();
    }, [currentPageDetailsIndex]);

    function resetErrorState() {
        setShouldDisplayError(false);
        dispatch({ type: "SET_HAS_ERROR", hasError: false });
    }

    const handleOnChange = (event) => {
        onFieldChange(event);
    };
    const handleOnKeyPress = (key) => {
        onNumpadPress(key);
    };

    const showCharackerKeyboard = () => {
        const { dataType, entryMethodCopy } = currentPageDetails;
        return dataType === "ALPHANUMERIC" && entryMethodCopy === "K";
    };

    // eslint-disable-next-line no-nested-ternary
    const marginBottom = showOrderDetails
        ? 30
        : showCharackerKeyboard()
        ? 28
        : "";

    return (
        <div className="product-field">
            <div className="container">
                <div className="field-container">
                    <HeadTitle
                        className="product-title"
                        style={{ marginBottom }}
                        textValue={currentPageDetails.description}
                    />
                    {!showCharackerKeyboard() && (
                        <React.Fragment>
                            <input
                                id={currentPageDetails.name}
                                name={currentPageDetails.name}
                                onChange={handleOnChange}
                                value={
                                    currentPageDetails.formattedValue ||
                                    currentPageDetails.value
                                }
                                type="text"
                            />
                            <VirtualKeyboard onKeyPress={handleOnKeyPress} />
                            <div
                                className="input-error"
                                style={{
                                    display: shouldDisplayError ? "" : "none",
                                }}
                            >
                                <p>
                                    {currentPageDetails.errorMessage ||
                                        "Invalid input"}
                                </p>
                            </div>
                        </React.Fragment>
                    )}
                    {showCharackerKeyboard() && (
                        <CharacterKeyBoard
                            pageDetail={currentPageDetails}
                            onChange={onCharacterKeyBoardChange}
                            errorMessage={currentPageDetails.errorMessage}
                            value={currentPageDetails.value}
                        />
                    )}
                </div>
                <Navigation
                    disableBack={skipEServicesPage}
                    onNext={onNextPage}
                    onBack={onPreviousPage}
                    mainWrapperStyle={{
                        ...(isEtenderPage && showCharackerKeyboard() && { bottom: "-10px" }),
                    }}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { skipEServicesPage } =
        MenuUtil.checkIfShouldSkipEServicesPage(state);
    const { currentPageDetailsIndex, pageDetails, pageStack } =
        state.defaultPage;

    const { showOrderDetails } = state.hotFood;

    const currentPageDetails = pageDetails
        ? pageDetails[currentPageDetailsIndex]
        : {};
    const { entryMethodCopy } = currentPageDetails;

    const allowScan = entryMethodCopy
        ? entryMethodCopy.includes("C") || entryMethodCopy.includes("B")
        : false;

    return {
        skipEServicesPage,
        currentPageDetails,
        allowScan,
        currentPageDetailsIndex,
        scanRetries: state.defaultPage.scanRetries,
        fieldValue: state.defaultPage.currentFieldValue,
        hasError: state.defaultPage.hasError,
        pageStack,
        showOrderDetails,
    };
};

const onFieldValueChange = (event, numpad) => (dispatch) => {
    if (numpad) {
        dispatch({
            type: FILL_UP_PRODUCT_FIELD,
            value: event,
            source: "keyboard",
            entryMethod: "K",
        });
    } else {
        const { value, id } = event.target;
        dispatch({
            type: FILL_UP_PRODUCT_FIELD,
            value,
            target: id,
            entryMethod: "K",
        });
    }
};

const onCharacterKeyBoardChange =
    ({ value }) =>
    (dispatch) => {
        dispatch({
            type: FILL_UP_PRODUCT_FIELD,
            value,
            // source: "keyboard",
            keyboardType: "ALPHANUMERIC",
            entryMethod: "K",
        });
    };

const validateProductField =
    (onSuccessValidate, ownProps) => (dispatch, getState) => {
        const { config, defaultPage } = getState();
        const { defaultGiftCardPrefixes } = config.defaultValues;
        const {
            pageDetails,
            currentPageDetailsIndex,
            orderInformation: { productFields },
        } = defaultPage;
        const productDetails = pageDetails[currentPageDetailsIndex];
        const productField =
            Array.isArray(productFields) &&
            productFields[currentPageDetailsIndex];
        const {
            jsValidator,
            value,
            name,
            minLength,
            maxLength,
            formattedValue,
            dataType,
        } = productDetails;
        const jsValidators =
            jsValidator &&
            jsValidator.split(",").map((validator) => validator.trim());
        const productFieldValidator = {};
        if (productField) {
            const {
                dataType: fieldDataType,
                fieldMax,
                fieldMin,
            } = productField;
            if (fieldDataType === "DECIMAL") {
                Object.assign(productFieldValidator, {
                    fieldDataType,
                    fieldMax,
                    fieldMin,
                    formattedValue,
                    currentFieldValue: defaultPage.currentFieldValue,
                });
            }
        }
        const validationError = inputValidation({
            // eslint-disable-next-line no-nested-ternary
            value: value
                ? dataType === "DECIMAL"
                    ? removeLeadingZero(value)
                    : value
                : null,
            name,
            jsValidators,
            minLength,
            maxLength,
            defaultGiftCardPrefixes,
            productFieldValidator,
        });
        if (validationError && Object.keys(validationError).length) {
            const { name: validatorName, message: errorMessage } =
                validationError;
            if (validatorName !== "7ELEVENGIFTCARD") {
                dispatch({
                    type: CUSTOM_PRODUCT_FIELD_ERROR,
                    error: errorMessage,
                    pageDetailsIndex: currentPageDetailsIndex,
                });
            } else {
                const continueAction = [{ type: SCAN_ERROR }];
                dispatch({
                    type: SHOW_INTERVENTION,
                    description: errorMessage,
                    continueAction,
                });
            }
        } else {
            onSuccessValidate(dispatch, getState, ownProps);
        }
    };

const onScanBarcode = (ownProps) => (dispatch, getState) => {
    const { currentPageDetailsIndex } = getState().defaultPage;
    const pageDetailsList = getState().defaultPage.pageDetails;
    const { entryMethodCopy } = pageDetailsList[currentPageDetailsIndex];
    const fieldCount = pageDetailsList.length;
    const onSuccessScan = () => () => {
        if (currentPageDetailsIndex >= fieldCount - 1) {
            if (ownProps.onSubmit && typeof ownProps.onSubmit === "function") {
                ownProps.onSubmit();
                return;
            }
            dispatch(initiateStageProduct(true));
        } else {
            dispatch({ type: NEXT_PRODUCT_FIELD });
        }
    };

    dispatch(
        scanBarcode(onSuccessScan, FILL_UP_PRODUCT_FIELD, entryMethodCopy),
    );
};

const onNextPage = (dispatch, getState, ownProps) => {
    const { pageDetails, currentPageDetailsIndex } = getState().defaultPage;
    const fieldCount = pageDetails.length;
    if (currentPageDetailsIndex >= fieldCount - 1) {
        if (ownProps.onSubmit) {
            ownProps.onSubmit();
            return;
        }
        dispatch(initiateStageProduct(true));
    } else {
        dispatch({ type: NEXT_PRODUCT_FIELD });
    }
};

const onPreviousPage = (ownProps) => (dispatch, getState) => {
    const { defaultPage } = getState();
    const { currentPageDetailsIndex, pageDetails } = defaultPage;
    dispatch({ type: REMOVE_PAGE_STACK });
    if (currentPageDetailsIndex === 0) {
        dispatch({ type: PREV_PAGE });
    } else {
        dispatch({ type: PREV_PRODUCT_FIELD });
        if (pageDetails[0] && pageDetails[0].entryMethod === "C") {
            dispatch({
                type: CLEAR_SWIPE_INPUT_FIELD,
                currentPageDetailsIndex,
            });
        }
        dispatch(onScanBarcode(ownProps.onSubmit || null));
    }
    dispatch(cancelScan);
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onAddItemToPOS: () => dispatch(initiateStageProduct(true)),
        onStartScanVoucher: () => dispatch(onScanBarcode(ownProps)),
        onCancelScanVoucher: () => dispatch(cancelScan),
        onNumpadPress: (key) => dispatch(onFieldValueChange(key, true)),
        onFieldChange: (event) => dispatch(onFieldValueChange(event)),
        onNextPage: () => dispatch(validateProductField(onNextPage, ownProps)),
        onPreviousPage: () => dispatch(onPreviousPage(ownProps)),
        onNextProductField: () => dispatch({ type: NEXT_PRODUCT_FIELD }),
        onPreviousProductField: () => dispatch({ type: PREV_PRODUCT_FIELD }),
        onCharacterKeyBoardChange: (details) =>
            dispatch(onCharacterKeyBoardChange(details)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomProductField);
