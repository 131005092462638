import { RestHost } from "common-functions";
import { format } from "date-fns";
import React from "react";
import { HIDE_INTERVENTION, SHOW_INTERVENTION } from ".";
import { onExitBrowser } from "../components/Navigation";
import { validateProductPricingAndRules } from "../containers/pricing-management/pricingManagementUtil";
import HttpUtils from "../utils/HttpUtils";
import { getStoreDetails } from "./defaultPage";

export const getProductPricingAndRules = ({ productId, customSuccessCallback }) => (dispatch, getState) => {
    const { defaultValues } = getState().config;

    const getProductPricingAndRulesRequest = {
        ...getStoreDetails(defaultValues),
        productId,
    };

    const customErrorCallback = () => {
        dispatch({ type: "PRODUCT_PRICING_RULES_FAILED" });
    };

    dispatch({ type: "PRODUCT_PRICING_RULES_REQUEST" });
    HttpUtils.restSendHandler(
        dispatch,
        RestHost.POST,
        "getProductPricingAndRules",
        getProductPricingAndRulesRequest,
        null,
        customSuccessCallback,
        customErrorCallback,
        RestHost.JSON,
        false,
    );
};
export const deleteProductPricing = (productId) => (dispatch, getState) => {
    const { defaultValues } = getState().config;

    const deleteProductPricingRequest = {
        ...getStoreDetails(defaultValues),
        productId,
    };

    const errorCallback = (error) => {
        dispatch({ type: SHOW_INTERVENTION, description: error.message });
        dispatch({ type: "DELETE_PRODUCT_PRICING_FAILURE" });
    };

    const successCallback = () => {
        showIntervention({ dispatch, description: "Product pricing successfully removed" });
        dispatch({ type: "REMOVE_STORE_PRICING" });
        dispatch({ type: "DELETE_PRODUCT_PRICING_SUCCESS" });
    };

    dispatch({ type: "DELETE_PRODUCT_PRICING_REQUEST" });
    HttpUtils.restSendHandler(
        dispatch,
        RestHost.POST,
        "deleteProductPricing",
        deleteProductPricingRequest,
        null,
        successCallback,
        errorCallback,
        RestHost.JSON,
        false,
    );
};

export const updateProductPricing = (product) => (dispatch, getState) => {
    const { defaultValues } = getState().config;
    const {
        productId, price, endDate,
    } = product;
    if (validateProductPricingAndRules(product, dispatch)) return;
    const updateProductPricingRequest = {
        ...getStoreDetails(defaultValues),
        productId,
        price,
        endDate: typeof endDate === "string" ? endDate : format(endDate, "yyyy-MM-dd"),
    };
    const errorCallback = (error) => {
        dispatch({ type: SHOW_INTERVENTION, description: error.message });
        dispatch({ type: "UPDATE_PRODUCT_PRICING_FAILURE" });
    };

    const successCallback = (response) => {
        if (response.status === "FAIL") {
            dispatch({ type: "UPDATE_PRODUCT_PRICING_FAILURE" });
            showIntervention({ dispatch, children: <SuccessUpdatePricingIntervention description="Product pricing update is not successful." dispatch={dispatch} /> });
            return;
        }
        showIntervention({ dispatch, children: <SuccessUpdatePricingIntervention description="Product pricing updated successfully." dispatch={dispatch} /> });
        dispatch({ type: "UPDATE_PRODUCT_PRICING_SUCCESS" });
    };

    dispatch({ type: "UPDATE_PRODUCT_PRICING_REQUEST" });
    HttpUtils.restSendHandler(
        dispatch,
        RestHost.POST,
        "updateProductPricing",
        updateProductPricingRequest,
        null,
        successCallback,
        errorCallback,
        RestHost.JSON,
        false,
    );
};

export function showIntervention({
    dispatch, description, children, shouldExitBrowser,
}) {
    dispatch({
        type: SHOW_INTERVENTION,
        ...description && { description },
        ...children && { children },
        ...shouldExitBrowser && { shouldExitBrowser },
    });
}

export const getCurrentPrice = (response) => (dispatch) => {
    let currentPrice = response.price;
    if (response.storePricing) currentPrice = response.storePricing.price;
    dispatch({ type: "SET_CURRENT_PRICE", currentPrice });
};

const SuccessUpdatePricingIntervention = ({ description, dispatch }) => {
    const handleOnCLick = () => {
        dispatch({ type: HIDE_INTERVENTION });
        dispatch(onExitBrowser);
    };
    return (
        // eslint-disable-next-line react/jsx-fragments
        <React.Fragment>
            <p className="description">
                {description}
            </p>
            <button type="button" onClick={handleOnCLick}>Ok</button>
        </React.Fragment>
    );
};
