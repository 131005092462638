import { NCRRest, Helpers } from "common-functions";
import { SHOW_INTERVENTION, HANDLE_CONSOLE_LOG } from "../actions";

const HttpUtils = {};

HttpUtils.restSendHandler = (dispatch, sendType, restUrl, data, urlParams, successCallback, errorCallback, ...restArgs) => {
    const connectionError = "Connection error. Unable to connect to eServices provider. Please try again. Contact 24/7 Support if this issue persists.";
    const consoleProps = { sendType, restUrl };

    const maxRetries = 2;
    let currentRetries = 0;

    const customErrorCallback = (error) => {
        if (!error) {
            if (currentRetries < maxRetries) {
                actualRequest();
                currentRetries += 1;
            } else {
                dispatch(handleConsoleLogs(consoleProps, connectionError, "ERROR"));
                dispatch({ type: SHOW_INTERVENTION, description: connectionError, hideLoadingBox: true });
                currentRetries = 0;
            }
        } else {
            const errorObject = {};
            const stringResponse = typeof error === "string";
            errorObject.message = stringResponse ? processError(error) : null;

            dispatch(handleConsoleLogs(consoleProps, error, "ERROR"));
            errorCallback(stringResponse ? errorObject : error);
            currentRetries = 0;
        }
    };

    const customSuccessCallback = (response) => {
        const failedResponse = JSON.stringify(response).includes("FAIL");
        dispatch(handleConsoleLogs(consoleProps, response, failedResponse ? "ERROR" : "SUCCESS"));
        const hasProductId = Object.keys(data).includes("productId");
        const returnProductID = restArgs.includes("returnProductId");
        if (returnProductID && hasProductId) {
            response.productId = data.productId;
        }
        if (successCallback && typeof successCallback === "function") {
            successCallback(response);
        }
        currentRetries = 0;
    };

    const actualRequest = () => {
        dispatch(handleConsoleLogs(consoleProps, data, "REQUEST"));
        NCRRest.send(sendType, restUrl, data, urlParams, customSuccessCallback, customErrorCallback, ...restArgs);
    };
    actualRequest();
};

HttpUtils.restSendHandlerPromise = (dispatch, sendType, restUrl, data, urlParams, successCallback, errorCallback, ...restArgs) => {
    const connectionError = "Connection error. Unable to connect to eServices provider. Please try again. Contact 24/7 Support if this issue persists.";
    const consoleProps = { sendType, restUrl };

    const maxRetries = 2;
    let currentRetries = 0;

    // eslint-disable-next-line consistent-return
    function customErrorCallback(error) {
        if (!error) {
            if (currentRetries < maxRetries) {
                currentRetries += 1;
                dispatch(handleConsoleLogs(consoleProps, data, "REQUEST"));
                return NCRRest.sendPromise(sendType, restUrl, data, urlParams, customSuccessCallback, customErrorCallback, ...restArgs);
            }
            dispatch(handleConsoleLogs(consoleProps, connectionError, "ERROR"));
            dispatch({ type: SHOW_INTERVENTION, description: connectionError, hideLoadingBox: true });
            currentRetries = 0;
        } else {
            const errorObject = {};
            const stringResponse = typeof error === "string";
            errorObject.message = stringResponse ? processError(error) : null;

            dispatch(handleConsoleLogs(consoleProps, error, "ERROR"));
            if (errorCallback && typeof errorCallback === "function") {
                errorCallback(stringResponse ? errorObject : error);
            }
            currentRetries = 0;
        }
    }

    const customSuccessCallback = (response) => {
        const failedResponse = JSON.stringify(response).includes("FAIL");
        dispatch(handleConsoleLogs(consoleProps, response, failedResponse ? "ERROR" : "SUCCESS"));
        const hasProductId = Object.keys(data).includes("productId");
        const returnProductID = restArgs.includes("returnProductId");
        if (returnProductID && hasProductId) {
            response.productId = data.productId;
        }
        if (successCallback && typeof successCallback === "function") {
            successCallback(response);
        }
        currentRetries = 0;
    };

    dispatch(handleConsoleLogs(consoleProps, data, "REQUEST"));
    return NCRRest.sendPromise(sendType, restUrl, data, urlParams, customSuccessCallback, customErrorCallback, ...restArgs);
};

export const handleConsoleLogs = (props, data, actionType) => (dispatch, getState) => {
    const dataCopy = { ...data };
    const { enableDebugMode } = getState().config;
    if (enableDebugMode) {
        const time = Helpers.getFullTime(new Date().toLocaleString().replace(/[^ -~]/g, ""));
        const date = new Date().toDateString().substring(4);
        const logProps = {
            data: dataCopy,
            actionType,
            time,
            date,
            ...props,
        };

        delete dataCopy.headers;
        dispatch({ type: HANDLE_CONSOLE_LOG, logProps });
    }
};

const processError = (errorString) => (errorString.includes("For input string") ? "Unknown voucher" : errorString);

export default HttpUtils;
