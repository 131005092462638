import { VALIDATIONS_SUCCESS, VALIDATIONS_FAILURE } from "../actions/validation";

const defaultState = [];

const validationReducer = (state, action) => {
    const validation = state.validation || defaultState;

    switch (action.type) {
        case VALIDATIONS_SUCCESS:
            return action.response;

        case VALIDATIONS_FAILURE:
            return defaultState;

        default:
            return validation;
    }
};

export default validationReducer;
