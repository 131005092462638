import Helpers from "./Helpers";

const Preloader = {};

/* Need to hold references to avoid garbage collection of preloaded images. */
const localCache = [];

Preloader.load = (file, subDirectory = "") => {
    const rootDirectory = `${Helpers.getURLRootPath()}resources/`;
    try {
        const length = localCache.length;
        localCache[length] = new Image();
        localCache[length].src = `${rootDirectory}${subDirectory}${file}`;
    } catch (error) {
        console.log(`Caching failed on image: ${subDirectory}${file}`);
    }
};

export default Preloader;
