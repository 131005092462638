const HttpUtils = {};

HttpUtils.createRequest = () => {
    let result = null;
    if (window.XMLHttpRequest) {
        // FireFox, Safari, etc.
        result = new XMLHttpRequest();
        if (typeof result.overrideMimeType !== "undefined") {
            result.overrideMimeType("text/json"); // Or anything else
        }
    } else if (window.ActiveXObject) {
        // MSIE
        result = new window.ActiveXObject("Microsoft.XMLHTTP");
    }

    return result;
};

HttpUtils.parseXml = (xml) => {
    let dom = null;

    if (window.DOMParser) {
        try {
            dom = (new DOMParser()).parseFromString(xml, "text/xml");
        } catch (e) {
            dom = null;
        }
    } else if (window.ActiveXObject) {
        try {
            dom = new window.ActiveXObject("Microsoft.XMLDOM");
            dom.async = false;
        } catch (e) {
            dom = null;
        }
    }
    return dom;
};

HttpUtils.urlEncodeParam = (key, value) => {
    const urlEncodedParam = encodeURIComponent(value).replace(/'/g, "%27").replace(/"/g, "%22");
    return `${key}=${urlEncodedParam}`;
};

export default HttpUtils;
