import React from "react";

const FullScreenWrapper = ({ children, className }) => {
    const classname = className ? `fullscreen-container ${className}` : "fullscreen-container";
    return (
        <div className={classname}>
            {children}
        </div>
    );
};

export default FullScreenWrapper;
