import { RestHost } from "common-functions";
import ElectronicServices from "../libs/ElectronicServices";
import { duplicateQuantity } from "../utils/helper";
import axios from 'axios';
import HttpUtils from "../utils/HttpUtils";
// eslint-disable-next-line import/no-cycle
import {
    displayError,
    exitBrowser,
    getStoreDetails,
    voidProduct,
} from "./defaultPage";

export const stageHotFoodProductDetails = (dispatch, getState) => {
    const { defaultValues } = getState().config;
    dispatch({ type: "HOT_FOOD_ORDER_COMPLETE_REQUEST" });
    const orderCopyDetails = getAllFoodProduct(getState);
    if (orderCopyDetails.length) {
        stageAllHotFood({
            orderCopyDetails,
            dispatch,
            defaultValues,
            getState,
        });
    }
};

function getAllFoodProduct(getState) {
    const {
        hotFood: { allOrderFoods },
        productFieldsPage,
    } = getState();
    const { productFields: _productFields } = productFieldsPage;
    const orderFoodCopy = [...allOrderFoods];
    const orderCopyDetails = orderFoodCopy.map(
        (productDetail) => {
            const { productNumber, productFields } = { ...productDetail };
            productFields.forEach((productField, index, ownArray) => {
                const hotFoodProduct = _productFields.find(
                    ({ name }) => name === productField.name,
                );
                if (hotFoodProduct) {
                    const { entryMethod, value, name } = hotFoodProduct;
                    // eslint-disable-next-line no-param-reassign
                    ownArray[index] = { entryMethod, name, value };
                }
            });
            return {
                ...productDetail,
                productId: productNumber,
                productFields,
            };
        },
    );
    return orderCopyDetails;
}

const addHotFoodItemToPOS = ({ response, orderCopyDetails, dispatch }) => {
    // let addItemReq = {};
    const addItemReq = {};
    const sortItem = (itemA, itemB) =>
        parseInt(itemA.productId) - parseInt(itemB.productId);
    const sortedResponse = response.sort(sortItem);
    const sortedOrderCopyDetails = orderCopyDetails.sort(sortItem);
    sortedOrderCopyDetails.forEach(
        ({ productId: stageProductId }, index, arr) => {
            sortedResponse.forEach(({ productId, stagedTransactionId }, i) => {
                if (stageProductId === productId && i === index) {
                    // eslint-disable-next-line no-param-reassign
                    arr[index].stagedTransactionId = stagedTransactionId;
                }
            });
        },
    );

    const onCloseBrowser = (responseText, error, timeout) => {
        if (!error && !timeout) {
            const res = JSON.parse(responseText);
            const returnCode = res[1].ReturnCode;
            const returnCodeDescription = res[1].ReturnCodeDescription;

            const formattedResponse = JSON.stringify(res, null, "\t");
            dispatch({ type: "HOT_FOOD_ORDER_COMPLETE_SUCCESS" });
            if (returnCode && returnCodeDescription) {
                const nextAction = () =>
                    dispatch(displayError(formattedResponse));
                dispatch(
                    voidProduct(
                        parseInt(addItemReq.stagedTransactionId),
                        nextAction,
                    ),
                );
            } else {
                dispatch(exitBrowser);
            }
        }
    };

    orderCopyDetails.forEach((orders, i) => {
        if (i === orderCopyDetails.length - 1) {
            ElectronicServices.AddItem(
                JSON.stringify(initializeAddItemRequest(orders)),
                onCloseBrowser,
            );
            return;
        }
        ElectronicServices.AddItem(
            JSON.stringify(initializeAddItemRequest(orders)),
        );
    });

    // addItemReq = initializeAddItemRequest(orderCopyDetails[0]);
    // if (orderCopyDetails.length > 1) {
    //     addItemReq.SubItemList = [];
    //     orderCopyDetails.slice(1, orderCopyDetails.length).forEach((element) => {
    //         addItemReq.SubItemList = [...addItemReq.SubItemList, initializeAddItemRequest(element)];
    //     });
    // }

    // const onAddItemAction = (responseText) => {
    //     if (responseText && typeof responseText === "string") {
    //         ElectronicServices.AddItem(
    //             JSON.stringify(addItemReq),
    //             onCloseBrowser,
    //         );
    //     } else {
    //         setTimeout(() => dispatch(addHotFoodItemToPOS), 50);
    //     }
    // };

    // cancelSwipe(onAddItemAction);
    // ElectronicServices.AddItem(JSON.stringify(addItemReq), onCloseBrowser);
};

// function getAllFoodProductCopy(getState) {
//     const {
//         hotFood: { orderedFoods },
//         productFieldsPage,
//     } = getState();
//     const { productFields: _productFields } = productFieldsPage;
//     const orderFoodCopy = [...orderedFoods];
//     const orderCopyDetails = duplicateQuantity(orderFoodCopy).map(
//         (productDetail) => {
//             const { productNumber, productFields } = { ...productDetail };
//             productFields.forEach((productField, index, ownArray) => {
//                 const hotFoodProduct = _productFields.find(
//                     ({ name }) => name === productField.name,
//                 );
//                 if (hotFoodProduct) {
//                     const { entryMethod, value, name } = hotFoodProduct;
//                     // eslint-disable-next-line no-param-reassign
//                     ownArray[index] = { entryMethod, name, value };
//                 }
//             });
//             return {
//                 ...productDetail,
//                 productId: productNumber,
//                 productFields,
//             };
//         },
//     );
//     return orderCopyDetails;
// }

function stageAllHotFood({
    orderCopyDetails,
    dispatch,
    defaultValues,
    getState,
}) {
    const orderCopyDetailsCopy = [...orderCopyDetails];
    const sortItem = (itemA, itemB) =>
        parseInt(itemA.displayOrder) - parseInt(itemB.displayOrder);
    const { config: { ncrWebServiceUrl } } = getState();
    const hotFoodProductRequests = orderCopyDetailsCopy
        .sort(sortItem)
        .map((item) => {
            const { amount, productFields, productId } = item;
            return {
                productFields,
                productId,
                amount,
                ...getStoreDetails(defaultValues),
                ...item.hotFoodComboID && { hotFoodComboID: item.hotFoodComboID },
                ...item.extendedTab && { extendedTab: item.extendedTab },
                ...item.isChildCombo && { isChildCombo : item.isChildCombo }
            };
        });
    stageProduct(hotFoodProductRequests, ncrWebServiceUrl).then(response => {
        if (response.length) {
            try {
                addHotFoodItemToPOS({
                    response,
                    orderCopyDetails,
                    getState,
                    dispatch,
                });
                dispatch({ type: "HOT_FOOD_ORDER_COMPLETE_SUCCESS" });
            } catch (error) {
                dispatch({ type: "HOT_FOOD_ORDER_COMPLETE_FAILURE" });
                // eslint-disable-next-line no-console
                console.log("error catch.......", error);
            }
        }
    })
}

const stageProductRequest = (requestBody, ncrWebServiceUrl) => axios.post(ncrWebServiceUrl + 'stageProduct', requestBody);

async function stageProduct(hotFoodProductRequests, ncrWebServiceUrl) {
    const stageRequests = []
    const requests = groupByExtendedMenu(hotFoodProductRequests);
    console.log('request....', requests);
    for (let index = 0; index < requests.length; index++) {
        let product = requests[index];
        console.log('produdct.....', product);
        const response = await stageProductRequest(product, ncrWebServiceUrl)
        if (response.data) {
            response.data.productId = product?.productId;
            stageRequests.push(response.data);
            
            if (product?.extendedMenus) {
                const extendedMenus = product?.extendedMenus
                for (let index = 0; index < extendedMenus.length; index++) {
                    const menu = extendedMenus[index]
                    const additionalProduct = {
                        entryMethod: "K",
                        name: "PARENT_STAGED_TRANSACTION_ID",
                        value: response.data?.stagedTransactionId
                    }
                    menu.productFields.push(additionalProduct)
                    const extendedMenuResponse = await stageProductRequest(menu, ncrWebServiceUrl)
                    if (extendedMenuResponse.data) {
                        extendedMenuResponse.data.productId = menu?.productId;
                        stageRequests.push(extendedMenuResponse.data)
                    }
                }
            }
        }
    }
    return stageRequests
}
// function stageAllHotFood({
//     orderCopyDetails,
//     dispatch,
//     defaultValues,
//     getState,
// }) {
//     const orderCopyDetailsCopy = [...orderCopyDetails];
//     const sortItem = (itemA, itemB) =>
//         parseInt(itemA.displayOrder) - parseInt(itemB.displayOrder);
//     const hotFoodProductRequests = orderCopyDetailsCopy
//         .sort(sortItem)
//         .map((item) => {
//             const { amount, productFields, productId } = item;
//             return {
//                 productFields,
//                 productId,
//                 amount,
//                 ...getStoreDetails(defaultValues),
//                 ...item.hotFoodComboID && { hotFoodComboID: item.hotFoodComboID },
//                 ...item.extendedTab && { extendedTab: item.extendedTab }
//             };
//         });
//     let delay = 0;
//     const delayIncrement = 1500;

//     console.log(JSON.stringify(hotFoodProductRequests))

//     const promises = hotFoodProductRequests.map((stageProductRequest) => {
//         delay += delayIncrement;
//         return new Promise((resolve) => setTimeout(resolve, delay)).then(() =>
//             HttpUtils.restSendHandlerPromise(
//                 dispatch,
//                 RestHost.POST,
//                 "stageProduct",
//                 stageProductRequest,
//                 null,
//                 null,
//                 null,
//                 RestHost.JSON,
//                 "returnProductId",
//             ),
//         );
//     });
//     Promise.all(promises)
//         .then((response) => {
//             if (response.length) {
//                 addHotFoodItemToPOS({
//                     response,
//                     orderCopyDetails,
//                     getState,
//                     dispatch,
//                 });
//                 dispatch({ type: "HOT_FOOD_ORDER_COMPLETE_SUCCESS" });
//             }
//         })
//         .catch((error) => {
//             dispatch({ type: "HOT_FOOD_ORDER_COMPLETE_FAILURE" });
//             // eslint-disable-next-line no-console
//             console.log("error catch.......", error);
//         });
// }

function groupByExtendedMenu(request) {
    const groupRequests = groupBy(request, request => request.hotFoodComboID);
    const newProducts = request.map(product => {
        if (product.hotFoodComboID && !product.extendedTab) {
            const extendedMenus = groupRequests.get(product.hotFoodComboID)
            const filteredExtendedMenus = extendedMenus.filter(
                product => product.extendedTab
            )
            product.extendedMenus = filteredExtendedMenus;
            return product
        }
        return product
    })
    return newProducts.filter(n => !n?.isChildCombo)
}

function groupBy(list, keyGetter) {
    const map = new Map()
    list.forEach(item => {
        const key = keyGetter(item)
        const collection = map.get(key)
        if (!collection) {
            map.set(key, [{ ...item, isParentMenu: true }])
        } else {
            collection.push(item)
        }
    })
    return map
}

const initializeAddItemRequest = (item) => {
    const addItemReq = {};
    addItemReq.Barcode = item.posCode;
    addItemReq.Description = item.productDescription;
    if (!item?.extendedTab) addItemReq.UnitPriceAmount = parseFloat(item.amount);
    addItemReq.ExternalData = {
        "eService.eProductNumber": parseInt(item.stagedTransactionId),
        "eService.eServiceTransactionNumber": parseInt(
            item.stagedTransactionId,
        ),
        "eService.description": item.productDescription,
        "eService.vendor": item.vendor,
        "eService.isAccepted": true,
    };

    return addItemReq;
};

export const getItemDetailsForAllowedCollection =
    (params) => (dispatch, getState) => {
        const { productId, customErrorCallback, customSuccessCallback } =
            params;
        const { defaultValues } = getState().config;

        const successCallback = (response) => {
            if (response.error) {
                const { errorMessage } = response.error;
                dispatch({
                    type: "ITEM_DETAILS_ALLOWED_FOR_COLLECTION_FAILURE",
                    errorMessage,
                });
                return;
            }
            if (getDisplayOrder(productId, getState)) {
                response.displayOrder = getDisplayOrder(productId, getState);
            }
            if (typeof customSuccessCallback === "function")
                customSuccessCallback();
            dispatch({
                type: "ITEM_DETAILS_ALLOWED_FOR_COLLECTION_SUCCESS",
                itemDetail: response,
            });

            if (Object.keys(params).includes("orderID")) response.orderID = params.orderID;
            if (Object.keys(params).includes("additionalHotFoodItem")) response = { ...response, ...params.additionalHotFoodItem };
            dispatch({ type: "ADD_ORDER_FOOD", item: response });
        };

        const errorCallback = (error) => {
            dispatch({
                type: "ITEM_DETAILS_ALLOWED_FOR_COLLECTION_FAILURE",
                errorMessage: error.message,
            });
            if (customErrorCallback) {
                customErrorCallback(error);
            }
        };

        dispatch({ type: "ITEM_DETAILS_ALLOWED_FOR_COLLECTION_REQUEST" });

        const getProductDetailsRequest = {
            ...getStoreDetails(defaultValues),
            productId,
        };

        HttpUtils.restSendHandler(
            dispatch,
            RestHost.POST,
            "getProductDetails",
            getProductDetailsRequest,
            null,
            successCallback,
            errorCallback,
            RestHost.JSON,
        );
    };

function getDisplayOrder(productId, getState) {
    const { menuItemStack, currentMenuItemStackIndex } = getState().defaultPage;
    const result = menuItemStack[currentMenuItemStackIndex].find(
        (menu) => menu.productId === productId,
    );
    return result ? result.displayOrder : null;
}
