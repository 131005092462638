import { Box, Typography } from "@mui/material";
import { Helpers } from "common-functions";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { SHOW_INTERVENTION } from "../../../actions";
import {
    cancelScan,
    FILL_UP_BARCODE,
    ORDER_INFORMATION_FAILURE,
    scanBarcode,
} from "../../../actions/defaultPage";
import VirtualKeyboard from "../../../components/VirtualKeyboard";
import ElectronicServices from "../../../libs/ElectronicServices";
import {
    inputFieldMappingForError,
    transactionEnum,
} from "../../../utils/Constants";
import useIdleHook from "../../../utils/hooks/idleHook";
import MenuUtil from "../../../utils/MainMenuUtils";
import {
    getDefaultStoreDetails,
    showHotFoodComboIntervention,
} from "../helpers/hotFoodCombo.utils";
import { getProductByPosCode } from "../hotFoodCombo.services";

const CustomField = (props) => {
    const {
        scanRetries,
        allowScan,
        onCancelScanVoucher,
        onStartScanVoucher,
        onNumpadPress,
        barcodeNumber,
        pageDetails,
        errorMessage,
        customErrorMessage,
        onShowErrorMessage,
        orderHasError,
        onBarcodeChange,
        onAddItem,
    } = props;
    const history = useHistory();
    useIdleHook();

    useEffect(() => {
        if (allowScan) {
            onStartScanVoucher(history);
        }
        return () => onCancelScanVoucher();
    }, [scanRetries]);

    useEffect(() => {
        if (customErrorMessage) {
            onShowErrorMessage(customErrorMessage);
        }
    }, []);

    const handleOnChange = (event) => {
        onBarcodeChange(event);
    };
    const handleOnKeyPress = (key) => {
        if (key === "ENTER") {
            onAddItem(barcodeNumber, history);
            return;
        }
        onNumpadPress(key);
    };

    return (
        <div
            className="product-field"
            style={{ padding: 0, width: "80%", height: "initial" }}
        >
            <Box
                component={"div"}
                sx={{ transform: "translateX(-8px)" }}
                className="container"
            >
                <div className="field-container">
                    <Typography color={"#fff"}>
                        {pageDetails?.description}
                    </Typography>
                    <input
                        id={"barcode"}
                        name={"barcode"}
                        onChange={handleOnChange}
                        value={barcodeNumber}
                        type="text"
                    />
                    <VirtualKeyboard
                        onKeyPress={handleOnKeyPress}
                        onSubmit={() => onAddItem(barcodeNumber, history)}
                        hasNextButton
                    />
                    <div
                        className="voucher-error"
                        style={{ display: orderHasError ? "" : "none" }}
                    >
                        <p>{errorMessage || "Unknown voucher."}</p>
                    </div>
                </div>
            </Box>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { skipEServicesPage } =
        MenuUtil.checkIfShouldSkipEServicesPage(state);

    const scanNotAllowedTransactions = [
        transactionEnum.CANCELLATION,
        transactionEnum.REFUND,
    ];
    const allowScan = !scanNotAllowedTransactions.includes(
        state.defaultPage.transaction,
    );
    const customRefundErrorMessage = Helpers.getURLParameter("errorMessage");
    return {
        allowScan,
        skipEServicesPage,
        customErrorMessage: customRefundErrorMessage,
        scanRetries: state.defaultPage.scanRetries,
        pageDetails: state.defaultPage.pageDetails,
        errorMessage: state.defaultPage.errorMessage,
        orderHasError: state.defaultPage.orderHasError,
        barcodeNumber: state.defaultPage.barcodeNumber,
        initialDefaultProduct: state.defaultPage.initialDefaultProduct,
    };
};

const onContinueBarcodePage =
    (barcodeNumber, history) => (dispatch, getState) => {
        const barcode = {};
        barcode.Barcode = barcodeNumber;
        const {
            config: { ncrWebServiceUrl, defaultValues },
        } = getState();
        const storeDetails = Helpers.getStoreDetails();
        const { storeId, operatorId } = getDefaultStoreDetails(defaultValues);
        const requestBody = {
            storePosId: storeDetails.storeId || storeId,
            operatorId: storeDetails.operatorId || operatorId,
            posCode: barcodeNumber,
        };
        // ElectronicServices.AddItem(barcode, null, true);
        const showIntervention = () =>
            showHotFoodComboIntervention({
                tabName: "COMBO OPTIONS",
                onContinue: () => onContinueFunction(dispatch, history),
                dispatch,
                onGoBack: () => history.goBack(),
            });
        getProductByPosCode({
            baseURL: ncrWebServiceUrl,
            dispatch,
            requestBody,
            history,
            showIntervention,
        });
    };

const onContinueFunction = (dispatch, history) => {
    dispatch(onCancelScan);
    setTimeout(() => {
        dispatch(
            scanBarcode(onContinueBarcodePage, FILL_UP_BARCODE, "B", history),
        );
    }, 10);
};
const onBarcodeChange = (event, numpad) => (dispatch, getState) => {
    const { config, defaultPage } = getState();
    const { productName } = defaultPage;

    let productInfo = config.productInformationList.find(
        (product) => product.productName === productName,
    );
    productInfo =
        productInfo ||
        config.productInformationList.find(
            (product) => product.menuItemName === productName,
        );

    if (numpad) {
        dispatch({
            type: FILL_UP_BARCODE,
            value: event,
            source: "keyboard",
            target: "barcode",
            validation: productInfo.validation,
        });
    } else {
        const { value } = event.target;
        dispatch({
            type: FILL_UP_BARCODE,
            value,
            target: event.target.id,
            validation: productInfo.validation,
        });
    }
};

const validate =
    (barcodeNumber, successCallback, history) => (dispatch, getState) => {
        const { transaction, productName } = getState().defaultPage;
        const { productInformationList } = getState().config;
        if (barcodeNumber) {
            let productInfo = productInformationList.find(
                (product) => product.productName === productName,
            );
            productInfo =
                productInfo ||
                productInformationList.find(
                    (product) => product.menuItemName === transaction,
                );

            const { validation } = productInfo;
            if (barcodeNumber.length < validation.minLength) {
                dispatch({
                    type: ORDER_INFORMATION_FAILURE,
                    errorMessage: `Minimum length is ${validation.minLength}`,
                });
            } else {
                dispatch(successCallback(barcodeNumber, history));
            }
        } else {
            const inputField = inputFieldMappingForError[transaction];
            dispatch({
                type: ORDER_INFORMATION_FAILURE,
                errorMessage: `${inputField} is required`,
            });
        }
    };

const onCancelScan = (dispatch) => {
    dispatch({ type: "CLEAR_BARCODE" });
    ElectronicServices.CancelSwipe();
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAddItem: (barcodeNumber, history) =>
            dispatch(validate(barcodeNumber, onContinueBarcodePage, history)),
        onStartScanVoucher: (history) =>
            dispatch(
                scanBarcode(
                    onContinueBarcodePage,
                    FILL_UP_BARCODE,
                    "B",
                    history,
                ),
            ),
        onCancelScanVoucher: () => dispatch(onCancelScan),
        onNumpadPress: (key) => dispatch(onBarcodeChange(key, true)),
        onBarcodeChange: (event) => dispatch(onBarcodeChange(event)),
        onShowErrorMessage: (errorMessage) => {
            dispatch({
                type: SHOW_INTERVENTION,
                description: errorMessage,
                shouldExitBrowser: true,
            });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomField);
