import InputValidator from "../libs/InputValidator";

import { DISPLAY_TIME_OUT_TIMER } from "../actions";

export const clearDisplayTimeout = (
    dispatch,
    displayTimeoutTimer,
    displayTimeoutTimerList = [],
) => {
    window.clearTimeout(displayTimeoutTimer);
    clearTimeout(displayTimeoutTimer);
    displayTimeoutTimerList.forEach((timer) => {
        window.clearTimeout(timer);
        clearTimeout(timer);
    });
    dispatch({ type: DISPLAY_TIME_OUT_TIMER, displayTimeoutTimer: null });
};

export function inputValidation(validations = {}) {
    const {
        value,
        name,
        defaultGiftCardPrefixes,
        minLength,
        maxLength,
        jsValidators,
        productFieldValidator,
    } = validations;
    if (Object.keys(productFieldValidator).length) {
        const { fieldMax, fieldMin, formattedValue } = productFieldValidator;
        const inputFieldValue = +formattedValue;
        if (inputFieldValue < fieldMin) {
            return { name, message: `Minimum amount is $${fieldMin}.00` };
        }
        if (inputFieldValue > fieldMax) {
            return { name, message: `Maximum amount is $${fieldMax}.00` };
        }
    } else {
        if (!value || value.trim().length < minLength) {
            return { name, message: `Minimum length is ${minLength}` };
        }

        if (value.length > maxLength) {
            return { name, message: `Maximum length is ${maxLength}` };
        }
    }

    if (Array.isArray(jsValidators) && jsValidators.length) {
        for (let i = 0; i <= jsValidators.length; i++) {
            const selectedValidator = InputValidator.find(
                (validator) => validator.name === jsValidators[i],
            );
            if (selectedValidator) {
                const {
                    validationErrorMessage,
                    validationGiftcards,
                    validationRegex,
                } = selectedValidator;
                const regex = new RegExp(validationRegex);
                if (
                    validationGiftcards &&
                    !validationGiftcards(defaultGiftCardPrefixes, value)
                ) {
                    return {
                        name: jsValidators[i],
                        message: validationErrorMessage,
                    };
                }
                if (validationRegex) {
                    if (!regex.test(value)) {
                        return {
                            name: jsValidators[i],
                            message: validationErrorMessage,
                        };
                    }
                }
            }
        }
    }
    return {};
}

export function extractKeyValueInArray(arr, separator = ":") {
    let obj = {};

    const extractor = (array, index) => {
        const valueIndex = array[index].indexOf(separator) + 1;
        const value = array[index].slice(valueIndex, array[index].length + 1);
        const key = array[index].slice(0, valueIndex - 1);
        return { [key]: value };
    };

    for (let i = 0; i < arr.length; i++) {
        obj = Object.assign(obj, extractor(arr, i));
    }

    return obj;
}

export function removeLeadingZero(amount) {
    if (amount[0] === "0") {
        return parseInt(amount);
    }
    return amount;
}

export function totalSumValue(arr = [], key) {
    return arr.reduce((acc, current) => acc + current[key], 0);
}

export function subTotal(arr = []) {
    const newArr = arr.filter((data) => !data.isUnknown);
    const total = newArr.reduce(
        (acc, data) => acc + +data.totalAmount,
        0,
    );
    // const total = newArr.reduce(
    //     (acc, data) => acc + data.quantity * +data.totalAmount,
    //     0,
    // );
    return total.toFixed(2);
}

export function getUniqueFromArray(arr, field) {
    // store the comparison  values in array
    const unique = arr
        .map((e) => e[field])

        // store the indexes of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)

        // eliminate the false indexes & return unique objects
        .filter((e) => arr[e])
        .map((e) => arr[e]);

    return unique;
}

export function compareArrays(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }
    return true;
}


export function mapProductField(productField) {
    return {
        name: productField.name,
        description: productField.description,
        minLength: productField.fieldMin,
        maxLength: productField.fieldMax,
        jsValidator: productField.jsValidator,
        formattedValue: ["amount", "AMOUNT"].includes(productField.name)
            ? "0.00"
            : null,
        entryMethod: productField.entryMethod,
        entryMethodCopy: productField.entryMethod,
        productNumber: productField.productNumber,
        displayOrder: productField.displayOrder,
        dataType: productField.dataType,
        value: "",
    };
}

export function duplicateQuantity(arr = []) {
    arr.forEach((element, index, arrArg) => {
        if (element.quantity && element.quantity > 1) {
            arrArg.push(...Array(element.quantity - 1).fill(element));
        }
    });
    return arr;
}

export const beautifyPrice = (price) => {
    const numPrice = +price;
    if (!numPrice) return 0.0;
    return `$${numPrice.toFixed(2)}`;
};

export function parseQueryString(search = "") {
    if (!search) return null;
    const paramArr = search.replace("?", "").split("&");
    const query = {};
    paramArr.forEach((el) => {
        const [key, value] = el.split("=");
        query[key] = value;
    });
    return query;
}

export function showViewOrderDetailsButton(state, ownProps) {
    const {
        defaultPage: {
            currentMenuItemStackIndex,
            menuItemStack,
            selectedMenuStack,
        },
        hotFood: { allOrderFoods: orderedFoods },
        ui: { searchParam },
    } = state;
    const currentMenu = menuItemStack[currentMenuItemStackIndex - 1];
    const menuId = selectedMenuStack[selectedMenuStack.length - 1];
    const queryString = parseQueryString(searchParam);
    const showOrderDetails =
        typeof ownProps === "object" &&
        Object.keys(ownProps).includes("showViewOrderButton");

    if (showOrderDetails && !ownProps.showViewOrderButton) return false;
    if (queryString && queryString.menuId && orderedFoods.length) {
        return true;
    }

    if (showOrderDetails && orderedFoods.length) return true;

    if (!menuId || !currentMenu) return false;

    const selectedMenu = currentMenu.find((menu) => menu.menuId === menuId);
    if (selectedMenu && orderedFoods.length) return true;
    return false;
}


export function getImage(images = [], imageName) {
    if (!images.length) return;
    const image = images.find((img) => img.name === imageName);
    if (!image) return;
    return `data:image/png;base64, ${image.content}`;
}

export function hideParagraphTag(id) {
    const ref = document.getElementById(id);
    if (ref) ref.style.display = 'none';
}

export function isEllipsisActive(e, fontSize = '11px', isOverlay = false) {
    if (!e) return;
    if (e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth) {
        if(isOverlay) {
            e.style.lineHeight = 1.2;
        }
        return (e.style.fontSize = fontSize);
    }
}

export const checkImageExist = (image, url) => {
    if (!url || !image) return false;
    const img = new Image();
    img.src = url + '/' + image;

    return img.complete
}

export function generateRandomId() {
    const characters = 'abcdef0123456789';
    const idLength = 32;
    let randomId = '';

    for (let i = 0; i < idLength; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomId += characters.charAt(randomIndex);
    }

    return randomId;
}