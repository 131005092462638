/* eslint-disable no-undef */
const enablAlert = false;

const alertLog = (message) => enablAlert && alert(message);

var ElectronicServices = new (function () {
    this.iPortNumber = 8080;
    this.strHost = "localhost";
    this.SwipeToken = null;
    function CORSHttpRequest(OnResponse, i64RequestId) {
        let bIsXDomainRequest = false;

        var xmlhttp = null;

        if (typeof XMLHttpRequest != "undefined") {
            xmlhttp = new XMLHttpRequest();
            if (
                !("withCredentials" in xmlhttp) &&
                typeof XDomainRequest != "undefined"
            ) {
                xmlhttp = new XDomainRequest();
                bIsXDomainRequest = true;
            }
        } else {
            try {
                xmlhttp = new ActiveXObject("Msxml2.XMLHTTP");
            } catch (e) {
                try {
                    xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
                } catch (e) {}
            }
        }

        this.open = function (method, url) {
            var result;
            var minimum = 2;
            var maximum = 250000;
            if (i64RequestId === 0) {
                i64RequestId =
                    Math.floor(Math.random() * (maximum - minimum + 1)) +
                    minimum;
            }

            if (bIsXDomainRequest) {
                result = xmlhttp.open(method, url);
                xmlhttp.onload = function () {
                    OnResponse(this.responseText, false, false, i64RequestId); // no error; no timeout
                };

                xmlhttp.onerror = function () {
                    alertLog("onerror: " + this.responseText);
                    OnResponse(this.responseText, true, false, i64RequestId); // error; no timeout
                };
            } else {
                result = xmlhttp.open(method, url, true);

                xmlhttp.onreadystatechange = function () {
                    if (xmlhttp.readyState != 4) {
                        return;
                    }
                    var strRespRequestId = xmlhttp.getResponseHeader(
                        "PosConnect-RequestId",
                    );
                    var i64RespRequestId = Number(strRespRequestId);
                    if (i64RequestId != i64RespRequestId) {
                        alertLog(
                            "onreadystatechange: Request ids do not match sent" +
                                i64RequestId +
                                ", Received " +
                                i64RespRequestId,
                        );
                        return;
                    }
                    if (xmlhttp.status == 200) {
                        OnResponse(
                            xmlhttp.responseText,
                            false,
                            false,
                            i64RespRequestId,
                        ); // no error; no timeout
                    } else {
                        alertLog(
                            "onreadystatechange: status is " +
                                xmlhttp.status +
                                " " +
                                xmlhttp.responseText,
                        );
                        OnResponse(
                            xmlhttp.responseText,
                            true,
                            false,
                            i64RespRequestId,
                        ); // error; no timeout
                    }
                };

                xmlhttp.setRequestHeader(
                    "Content-Type",
                    "application/json; charset=UTF-8",
                );
                xmlhttp.setRequestHeader(
                    "PosConnect-RequestId",
                    i64RequestId.toString(),
                );
                xmlhttp.setRequestHeader("PosConnect-Async", "0");
            }

            if ("ontimeout" in xmlhttp) {
                xmlhttp.ontimeout = function () {
                    alertLog("ontimeout: " + xmlhttp.responseText);
                    OnResponse(xmlhttp.responseText, false, true, i64RequestId); // no error; timeout
                };
            }
            return result;
        };

        this.send = function (strRequest) {
            return xmlhttp.send(strRequest);
        };
    }

    function safeParseJSON(strResponseText) {
        var result = null;
        try {
            result = JSON.parse(strResponseText);
        } catch (e) {}
        return result;
    }

    function sendRequest(strUrl, onResponse, i64RequestId, strRequest) {
        var xhr = new CORSHttpRequest(onResponse, i64RequestId);
        try {
            xhr.open("POST", strUrl);
        } catch (e) {
            alertLog("open - " + e.name + ": " + e.message + "\n" + e.stack);
        }

        try {
            xhr.send(strRequest);
        } catch (e) {
            alertLog("send - " + e.name + ": " + e.message + "\n" + e.stack);
        }
    }

    function sendRetrieveRequest(
        strUrl,
        strToken,
        needTheExtendedBarcode,
        onResponse,
    ) {
        sendRequest(
            strUrl,
            onResponse,
            0,
            JSON.stringify([
                "Pos/RetrieveSwipe",
                {
                    Token: strToken,
                    NeedExtendedBarcode: needTheExtendedBarcode,
                },
            ]),
        );
    }

    function makeUrl() {
        return (
            // eslint-disable-next-line no-restricted-globals
            location.protocol +
            "//" +
            ElectronicServices.strHost +
            ":" +
            ElectronicServices.iPortNumber +
            "/rposdeviceaccess"
        );
    }

    ////////////////////////////////////////

    this.DisplayBrowser = function (strRequest) {
        this.strRequest = JSON.stringify([
            "Pos/DisplayWebBrowser",
            JSON.parse(strRequest),
        ]);

        var onDisplayBrowserResponse = function (
            strResponse,
            bError,
            bTimeout,
        ) {};

        sendRequest(makeUrl(), onDisplayBrowserResponse, 0, strRequest);
    };

    this.CloseBrowser = function (strRequest) {
        strRequest = JSON.stringify([
            "Pos/CloseWebBrowser",
            JSON.parse(strRequest),
        ]);

        var onCloseBrowserResponse = function (
            strResponse,
            bError,
            bTimeout,
        ) {};

        sendRequest(makeUrl(), onCloseBrowserResponse, 0, strRequest);
    };

    this.PromptForSwipeRequest = function (strRequest) {
        this.strRequest = JSON.stringify([
            "Pos/PromptForSwipe",
            JSON.parse(strRequest),
        ]);

        this.strToken = null;
        this.NeedTheExtendedBarcode = false;
        this.OnSwipeDataReady = null;
        this.OnTokenReceived = null;
        ElectronicServices.SwipeToken = null;
    };

    this.CaptureSwipeRequest = function (strRequest) {
        this.strRequest = JSON.stringify([
            "Pos/CaptureSwipe",
            JSON.parse(strRequest),
        ]);
        this.strToken = null;
        this.NeedTheExtendedBarcode = false;
        this.OnSwipeDataReady = null;
        this.OnTokenReceived = null;
        ElectronicServices.SwipeToken = null;
    };

    this.RequestSwipe = function (oRequest) {
        var onCheckSwipeDataResponse = function (
            strResponse,
            bError,
            bTimeout,
        ) {
            if (!bError && !bTimeout) {
                var response = safeParseJSON(strResponse);
                if (response && response[1].EntryMethod) {
                    if (
                        oRequest.OnSwipeDataReady &&
                        ElectronicServices.SwipeToken
                    ) {
                        oRequest.OnSwipeDataReady(
                            response[1].EntryMethod,
                            response[1].Data,
                            false,
                            false,
                        ); // no error, no timeout
                    }
                } else {
                    if (ElectronicServices.SwipeToken) {
                        setTimeout(checkSwipeData(), 500);
                    }
                }
            } else {
                oRequest.OnSwipeDataReady("", "", bError, bTimeout);
            }
        };

        var checkSwipeData = function () {
            if (ElectronicServices.SwipeToken) {
                sendRetrieveRequest(
                    makeUrl(),
                    oRequest.strToken,
                    oRequest.NeedTheExtendedBarcode,
                    onCheckSwipeDataResponse,
                );
            }
        };

        var onSwipeRequestResponse = function (strResponse, bError, bTimeout) {
            if (!bError && !bTimeout) {
                var response = safeParseJSON(strResponse);

                if (response && response[1].Token) {
                    oRequest.strToken = response[1].Token;
                    ElectronicServices.SwipeToken = response[1].Token;
                    if (oRequest.OnTokenReceived) {
                        oRequest.OnTokenReceived(response[1].Token);
                    }
                    if (ElectronicServices.SwipeToken) {
                        setTimeout(checkSwipeData(), 500);
                    }
                } else {
                    if (oRequest.OnSwipeDataReady) {
                        oRequest.OnSwipeDataReady("", "", true, false); // error, no timeout
                    }
                }
            } else {
                if (oRequest.OnSwipeDataReady) {
                    oRequest.OnSwipeDataReady("", "", bError, bTimeout);
                }
            }
        };

        sendRequest(makeUrl(), onSwipeRequestResponse, 0, oRequest.strRequest);
    };

    this.GetSwipeResult = function (
        strToken,
        needTheExtendedBarcode,
        OnResult,
    ) {
        var onGetResultResponse = function (strResponse, bError, bTimeout) {
            var strEntryMethod = "";
            var strData = "";

            if (!bError && !bTimeout) {
                var response = safeParseJSON(strResponse);
                if (
                    response &&
                    response[1].EntryMethod &&
                    ElectronicServices.SwipeToken
                ) {
                    strEntryMethod = response[1].EntryMethod;
                    strData = response[1].Data;
                } else {
                    bError = true;
                }
            } else {
                if (bError) {
                    alertLog("GetSwipeResult.onGetResultResponse: error");
                }
                if (bTimeout) {
                    alertLog("GetSwipeResult.onGetResultResponse: timeout");
                }
            }

            OnResult(strEntryMethod, strData, bError, bTimeout);
        };

        sendRetrieveRequest(
            makeUrl(),
            strToken,
            needTheExtendedBarcode,
            onGetResultResponse,
        );
    };

    this.CancelSwipe = function (strToken, onResponse) {
        var strRequest = JSON.stringify([
            "Pos/CancelSwipe",
            { Token: strToken },
        ]);

        var onCancelSwipeResponse = function (strResponse, bError, bTimeout) {
            ElectronicServices.SwipeToken = null;
            onResponse(strResponse, bError, bTimeout);
        };

        sendRequest(makeUrl(), onCancelSwipeResponse, 0, strRequest);
    };

    this.CustomerDisplayMessage = function (strRequest) {
        strRequest = JSON.stringify([
            "Pos/CustomerDisplayMessage",
            JSON.parse(strRequest),
        ]);

        var onCustomerDisplayResponse = function (
            strResponse,
            bError,
            bTimeout,
        ) {};

        sendRequest(makeUrl(), onCustomerDisplayResponse, 0, strRequest);
    };

    this.ReceiptTextLine = function (strText) {
        this.Command = "Text";
        this.Text = strText;
        this.Align = "Left";
        this.Font = "Normal";
        this.Width = "Normal";
        this.Height = "Normal";
        this.Bold = "No";
        this.Underline = "No";
        this.Italics = "No";
        this.NewLine = "Yes";
    };

    this.ReceiptBarcode = function (strBarcode) {
        this.Command = "Barcode";
        this.Barcode = strBarcode;
        this.Type = "Code39";
        this.Align = "Center";
        this.Width = "2";
        this.Height = "8";
        this.PrintBarcodeText = "Yes";
    };

    this.ReceiptImage = function (strImageBase64Encoded) {
        this.Command = "Image";
        this.Image = strImageBase64Encoded;
        this.Align = "Center";
    };

    this.ReceiptHorizontalLine = function () {
        this.Command = "HorizontalLine";
    };

    this.ReceiptNewLine = function () {
        this.Command = "NewLine";
    };

    this.ReceiptCutPaper = function (bHalfCut) {
        this.Command = "CutPaper";
        if (bHalfCut) {
            this.HalfCut = "Yes";
        } else {
            this.HalfCut = "No";
        }
    };

    this.PrintReceiptRequest = function () {
        this.PrintFormat = "NCRPRINTAPI";
        this.PrintCommands = new Array();

        this.AddCommand = function (oCommand) {
            this.PrintCommands.push(oCommand);
        };
    };

    this.PrintReceipt = function (strRequest, onTokenReceived) {
        strRequest = JSON.stringify([
            "Pos/CustomerPrintReceipt",
            JSON.parse(strRequest),
        ]);

        var onPrintReceiptResponse = function (strResponse, bError, bTimeout) {
            if (onTokenReceived != null) {
                if (bError || bTimeout) {
                    onTokenReceived("", bError, bTimeout);
                } else {
                    var response = safeParseJSON(strResponse);

                    if (response && response[1].Token) {
                        onTokenReceived(response[1].Token, false, false);
                    } else {
                        onTokenReceived(response[1].Token, true, false);
                    }
                }
            }
        };

        sendRequest(makeUrl(), onPrintReceiptResponse, 0, strRequest);
    };

    this.AddItem = function (
        strData,
        onAddItemResponseHandler,
        isForTesting = false,
    ) {
        const data = isForTesting ? strData : JSON.parse(strData);
        var strNewRequest = JSON.stringify(["Pos/SellItem", data]);

        var onAddItemResponse;
        if (onAddItemResponseHandler !== null) {
            onAddItemResponse = onAddItemResponseHandler;
        } else {
            onAddItemResponse = function (strResponse, bError, bTimeout) {};
        }

        sendRequest(makeUrl(), onAddItemResponse, 0, strNewRequest);
    };
})();

export default ElectronicServices;
