import React from "react";

const ScrollButton = ({
    setCurrentPage,
    currentPage,
    lastPage,
    buttonSize,
    setLastRowCount,
    isHideTabs,
}) => {
    const handleScrollUp = () => {
        const rows = isHideTabs ? 4 : 3;
        setCurrentPage((prevState) => prevState - 1);
        if (typeof setLastRowCount === "function")
            setLastRowCount((prevState) => prevState - rows);
    };

    const handleScrollDown = () => {
        const rows = isHideTabs ? 4 : 3;
        setCurrentPage((prevState) => prevState + 1);
        if (typeof setLastRowCount === "function")
            setLastRowCount((prevState) => prevState + rows);
    };
    const arrowUp =
        currentPage > 0
            ? "/resources/images/arrow_up_active.png"
            : "/resources/images/arrow_up_inactive.png";
    const arrowDown =
        currentPage < lastPage
            ? "/resources/images/arrow_down_active.png"
            : "/resources/images/arrow_down_inactive.png";
    const arrowUpStyle = {
        backgroundImage: `url(${arrowUp})`,
        ...(buttonSize && buttonSize),
    };
    const arrowDownStyle = {
        backgroundImage: `url(${arrowDown})`,
        ...(buttonSize && buttonSize),
    };
    return (
        <div className="scroll-button-container">
            <button
                type="button"
                style={arrowUpStyle}
                onClick={handleScrollUp}
                disabled={currentPage === 0}
                className="scroll-btn scroll-up"
            />
            <button
                type="button"
                style={arrowDownStyle}
                onClick={handleScrollDown}
                disabled={lastPage === currentPage}
                className="scroll-btn scroll-down"
            />
        </div>
    );
};

export default ScrollButton;
