import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line import/no-cycle
import { exitBrowser } from "../../actions/defaultPage";

const useIdleHook = () => {
    const dispatch = useDispatch();
    const { displayTimeOutInSeconds } = useSelector(({ config }) => config);
    const time = displayTimeOutInSeconds || 300;
    const onIdle = () => dispatch(exitBrowser);
    return useIdleTimer({
        onIdle,
        timeout: time * 1000,
    });
};

export default useIdleHook;
