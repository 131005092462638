import combineReducers from "./combineReducers";
// import { combineReducers } from "redux";

import ui from "./ui";
import validation from "./validation";
import defaultPage from "./defaultPage";
import debugMode from "./debugMode";
import hotFood from "./hotFoodReducer";
import productFieldsPage from "./productFieldsComponentReducer";
import pricingManagementReducer from "./pricingManagementReducer";
import ETenderReducer from "./etender";
import configReducer from "./config";
import hotFoodComboReducer from "./HotFoodComboReducer";

const rootReducer = combineReducers({
    config: configReducer,
    ui,
    validation,
    defaultPage,
    debugMode,
    hotFood,
    productFieldsPage,
    pricingManagement: pricingManagementReducer,
    etender: ETenderReducer,
    hotFoodCombo: hotFoodComboReducer,
});

export default rootReducer;
