import { RestHost } from "common-functions";

export const CONFIG_REQUEST = "CONFIG_REQUEST";
export const CONFIG_SUCCESS = "CONFIG_SUCCESS";
export const CONFIG_FAILURE = "CONFIG_FAILURE";

export const configRequest = (dispatch, successCallback) => {
    const processOnBackground = false;
    dispatch({ type: CONFIG_REQUEST, processOnBackground });

    const restHost = new RestHost("resources/config/");
    restHost.get("settings.json",
        (response) => {
            dispatch({ type: CONFIG_SUCCESS, response, processOnBackground });
            if (successCallback) {
                successCallback(response);
            }
        },
        (error) => dispatch({ type: CONFIG_FAILURE, error, processOnBackground }),
        RestHost.JSON,
        processOnBackground);
};
