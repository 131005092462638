import { applyMiddleware, createStore, compose } from "redux";
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { configRequest } from "./actions/config";
import { validationsRequest } from "./actions/validation";

import reducers from "./reducers";
const isProduction = process.env.NODE_ENV === "production";
let stateRehydrated = false;
const postRehydrate = (store) => {
    if (!stateRehydrated) {
        stateRehydrated = true;

        const dispatch = store.dispatch;

        configRequest(dispatch);
        validationsRequest(dispatch, isProduction);
    }
};

const persistConfig = {
    key: "root",
    storage,
    whitelist: [],
};

const persistedReducer = persistReducer(persistConfig, reducers);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    let store = createStore(
        persistedReducer,
        composeEnhancers(applyMiddleware(thunk)),
    );
    let persistor = persistStore(store, {}, () => postRehydrate(store));
    return { store, persistor };
};
