import { CONFIG_SUCCESS, CONFIG_FAILURE } from "../actions/config";

const defaultState = {};
let CONFIG_LOADED = false;

export const isConfigLoaded = () => CONFIG_LOADED;

const configReducer = (state, action) => {
    const config = state.config || defaultState;

    switch (action.type) {
        case CONFIG_SUCCESS:
            CONFIG_LOADED = true;
            return action.response;

        case CONFIG_FAILURE:
            return defaultState;

        default:
            return config;
    }
};

export default configReducer;
