export const SHOW_LOADING_BOX = "SHOW_LOADING_BOX";
export const SHOW_INTERVENTION = "SHOW_INTERVENTION";
export const HIDE_INTERVENTION = "HIDE_INTERVENTION";

export const SHOW_CONSOLE = "SHOW_CONSOLE";
export const HIDE_CONSOLE = "HIDE_CONSOLE";
export const HANDLE_CONSOLE_LOG = "HANDLE_CONSOLE_LOG";
export const CLEAR_CONSOLE_LOG = "CLEAR_CONSOLE_LOG";

export const DISPLAY_TIME_OUT_TIMER = "DISPLAY_TIME_OUT_TIMER";

export const RESET_STATE = "RESET_STATE";
