import { Helpers } from 'common-functions';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { getDefaultStoreDetails } from '../hotFoodCombo.utils';


function usePrefetchGetExtendedEserviceMenu() {
    const dispatch = useDispatch();
    const { ncrWebServiceUrl, defaultValues } =
        useSelector((state) => state.config);

    async function handlePrefetchGetExtendedEserviceMenu(tabs = [], isMainTab) {
        const typePreFetchSuffix = "GET_PRE_FETCH_";
        if (!tabs.length) return;

        const storeDetails = Helpers.getStoreDetails();
        const { storeId, operatorId } = getDefaultStoreDetails(defaultValues);
        const requestBody = {
            storePosId: storeDetails.storeId || storeId,
            operatorId: storeDetails.operatorId || operatorId,
            operatorAuthId: null,
        };

        dispatch({type: typePreFetchSuffix + 'REQUEST'});
        const promises = tabs.map(tab => {
            requestBody.menuId = tab.menuId;
            if(tab.parentMenuId) requestBody.parentMenuId = tab.parentMenuId
            return axios.post(`${ncrWebServiceUrl}menu/getEServiceExtendedMenu`, requestBody);
        });
        try {
            const result = await Promise.allSettled(promises);
            const fetchedData = result.map(d => {
                if (d?.status === 'fulfilled') {
                    const { config: { data: params }, data: { menuItems } } = d.value;
                    const param = JSON.parse(params);
                    const items = menuItems.filter(item => item.productId);
                    const tabs = menuItems.filter(item => !item.productId);
                    const menuItemsCopy = items.map((menuItem) => ({ ...menuItem, parentMenuId: param.parentMenuId || param.menuId }))
                    return {
                        menuId: param.menuId,
                        menuItems: menuItemsCopy,
                        tabs
                    }
                }
            });
            if (Array.isArray(fetchedData) && fetchedData.length) {
                const items = fetchedData[0].menuItems
                dispatch({ type: 'HFC_MENU_ITEMS', menuItems: items });
                if(fetchedData[0].tabs.length) dispatch({ type: "HFC_SET_TABS", tabs: fetchedData[0].tabs, parentMenuId: requestBody.menuId });
            }
           
            dispatch({ type: "HFC_PREFECTHED_ITEMS", prefetchedItems: fetchedData }); 
            if (isMainTab) dispatch({ type: 'HFC_PREFECTHED_TABS', prefetchedTabs: fetchedData });
            dispatch({type: typePreFetchSuffix + 'SUCCESS'});
        } catch (error) {
            dispatch({type: typePreFetchSuffix + 'FAILURE'});
        }
        
    }
    return { handlePrefetchGetExtendedEserviceMenu }
}

export default usePrefetchGetExtendedEserviceMenu;