import { cloneDeep } from "lodash";

const defaultState = {
    rowsPerPage: 9,
    isSuccessAuth: false,
    tabs: [],
    subMenuId: null,
    menuItems: [],
    tabsLength: 0,
    hideNavigationTab: false,
    hotFoodPageStack: [],
    tabsStack: [],
    prefetchedItems: [],
    prefetchedTabs: [],
    orders: [],
    parentOrderID: '',
    hotFoodComboID: '',
    activeTabTitle: '',
    parentTabMenuId: null,
    hideParentNav: false,
};

const hotFoodComboReducer = (state, action) => {
    const hotFoodCombo = state.hotFoodCombo || cloneDeep(defaultState);
    switch (action.type) {
        case "HFC_SET_AUTH_SUCCESS": {
            hotFoodCombo.isSuccessAuth = action.isSuccessAuth;
            return hotFoodCombo;
        }
        case "HFC_SET_TABS": {
            const stateCopy = { ...hotFoodCombo };
            const tabs = action.tabs.map((tab) => ({
                ...tab, ...action.parentMenuId && { parentMenuId: action.parentMenuId }
            }))
            stateCopy.tabs = tabs;
            stateCopy.tabsLength = action.tabs?.length || 0;
            return stateCopy;
        }
    
        case "SET_PARENT_TAB_MENU_ID": {
            return {
                ...hotFoodCombo,
                parentTabMenuId: action.parentTabMenuId
            }
        }
        case "HFC_SET_HIDE_NAVIGATION_TABS": {
            const stateCopy = { ...hotFoodCombo };
            stateCopy.hideNavigationTab = action.hideNavigationTab;
            return stateCopy;
        }
    
        case "HFC_SET_HIDE_PARENT_NAV": {
            const stateCopy = { ...hotFoodCombo };
            stateCopy.hideParentNav = action.hideParentNav;
            return stateCopy;
        }
        case "HFC_SET_ACTIVE_TAB_TITLE": {
            const stateCopy = { ...hotFoodCombo };
            stateCopy.activeTabTitle = action.title;
            return stateCopy;
        }


        case "HFC_SET_SUB_MENU_ID":
            return { ...hotFoodCombo, subMenuId: action.subMenuId };
        case "HFC_ADD_PAGE_STACK": {
            if (hotFoodCombo.hotFoodPageStack.includes(action.menuId))
                return hotFoodCombo;
            return {
                ...hotFoodCombo,
                hotFoodPageStack: [
                    ...hotFoodCombo.hotFoodPageStack,
                    action.menuId,
                ],
            };
        }
        case "HFC_ADD_TAB_STACK": {
            const tabsCopy = action.tabs.map((tab) => ({ ...tab, ...action.parentMenuId && { parentMenuId: action.parentMenuId } }));
            let tabsStackCopy = hotFoodCombo.tabsStack;
            if (action.parentMenuId) {
                const tabsChildren = action.tabs.map((tab) => tab.menuId);
                tabsStackCopy = hotFoodCombo.tabsStack.map((tabStack, index) => {
                    if (index === 0) {
                        tabStack = tabStack.map((tab) => {
                            if (tab.menuId === action.parentMenuId) {
                                tab.children = tabsChildren
                            }
                            return tab;
                        })
                    }
                    return tabStack;
                })
            }
            return {
                ...hotFoodCombo,
                tabsStack: [
                    ...tabsStackCopy,
                    tabsCopy,
                ],
            };
        }
        case "HFC_REMOVE_PAGE_STACK":
            return {
                ...hotFoodCombo,
                hotFoodPageStack: hotFoodCombo.hotFoodPageStack.slice(0, -1),
            };
        case "HFC_MENU_ITEMS":
            return { ...hotFoodCombo, menuItems: action.menuItems };
        case "HFC_PREFECTHED_ITEMS":
            return { ...hotFoodCombo, prefetchedItems: action.prefetchedItems };
        case "HFC_PREFECTHED_TABS":
            return { ...hotFoodCombo, prefetchedTabs: action.prefetchedTabs };

        case "HFC_RESET_STATE": {
            const { parentOrderID, hotFoodComboID } = hotFoodCombo;
            return {...defaultState, parentOrderID, hotFoodComboID};
        }
        case "HFC_SET_ORDERS": {
            return { ...hotFoodCombo, orders: [...hotFoodCombo.orders, action.orders] }
        }

        case "HFC_MAIN_PRODUCT_ID":
            return { ...hotFoodCombo, parentOrderID: action.parentOrderID, hotFoodComboID: action.hotFoodComboID }
        default:
            return hotFoodCombo;
    }
};

export default hotFoodComboReducer;
