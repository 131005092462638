import Intl from "./Intl";
import Helpers from "./Helpers";

const Validation = {};

const emailRegex = /^[a-zA-Z0-9_.]{2,}@[a-zA-Z0-9_.]{1,}\.[a-zA-Z]{2,}$/;

Validation.validate = (translation = {}, fieldID, prioritizedValue) => {
    const result = { errors: [], hasInput: false };

    let elements = [];

    if (fieldID) {
        elements.push(document.getElementById(fieldID));
    } else {
        const inputs = Helpers.getElementsByTagName("input");
        const textareas = Helpers.getElementsByTagName("textarea");
        const selects = document.querySelectorAll("[data-select]");

        elements = inputs && inputs.length ? elements.concat(inputs) : elements;
        elements = textareas && textareas.length ? elements.concat(textareas) : elements;
        elements = selects && selects.length ? elements.concat([...selects]) : elements;
    }

    elements.forEach((element) => {
        if (!element.disabled && !element.hidden) {
            result.hasInput = true;

            const validation = Validation.getElementAttributes(element);

            if (validation) {
                let message = null;
                const customValue = prioritizedValue && prioritizedValue[element.id];
                const value = customValue || element.value || element.getAttribute("data-value");
                const label = element.getAttribute("data-label");
                let length = value ? value.length : 0;

                if (validation.allowedAutoFormatCharacters && length > 0) {
                    const allowedAutoFormatCharacters = validation.allowedAutoFormatCharacters.split("");
                    let val = value;
                    for (let idx = 0; idx < allowedAutoFormatCharacters.length; idx += 1) {
                        const allowedAutoFormatCharacter = allowedAutoFormatCharacters[idx];
                        val = val.replace(new RegExp(allowedAutoFormatCharacter, "g"), "");
                    }
                    length = val.length;
                }

                const isNumeric = () => {
                    const { allowedSpecialCharacters, allowedAutoFormatCharacters } = validation;
                    const specialChars = `${allowedSpecialCharacters || ""}${allowedAutoFormatCharacters || ""}`;
                    const regExpStr = Helpers.escapeRegExp(`0-9${specialChars}`);
                    return new RegExp(`^[${regExpStr}]*$`).test(value);
                };

                const isRegexValid = () => {
                    const regEx = new RegExp(`^${validation.regex}$`);
                    return regEx.test(value);
                };

                if (value == null || length === 0) {
                    if (validation.isRequired) {
                        message = Intl.translate(translation, "error.message.is.required", "{0} is required", label);
                    }
                } else if (validation.type === "NUMERIC" && !isNumeric()) {
                    message = Intl.translate(translation, "error.message.is.invalid", "{0} is invalid", label);
                } else if (validation.minLength && validation.minLength > length) {
                    message = Intl.translate(translation, "error.message.min.length", "{0} minimum length is {1}", [label, validation.minLength]);
                } else if (validation.maxLength && validation.maxLength < length) {
                    message = Intl.translate(translation, "error.message.max.length", "{0} maximum length is {1}", [label, validation.maxLength]);
                } else if (validation.type === "EMAIL" && !emailRegex.test(value)) {
                    message = Intl.translate(translation, "error.message.is.invalid", "{0} is invalid", label);
                } else if (element.getAttribute("data-dropdown") && JSON.parse(element.getAttribute("data-valid")) !== true) {
                    message = Intl.translate(translation, "error.message.is.invalid", "{0} is invalid", label);
                } else if (element.getAttribute("data-select") && JSON.parse(element.getAttribute("data-valid")) !== true) {
                    message = Intl.translate(translation, "error.message.is.invalid", "{0} is invalid", label);
                } else if (element.getAttribute("data-search") && JSON.parse(element.getAttribute("data-valid")) !== true) {
                    message = Intl.translate(translation, "error.message.is.invalid", "{0} is invalid", label);
                } else if (validation.regex && !isRegexValid()) {
                    message = Intl.translate(translation, "error.message.is.invalid", "{0} is invalid", label);
                }

                if (message) {
                    result.errors.push({ fieldName: element.id, message });
                }
            }
        }
    });
    result.hasFormatErrors = result.errors.length !== 0;
    return result;
};

Validation.validateSearchInput = (searchValue) => {
    return Validation.validate(null, null, searchValue);
}

Validation.isChangeValid = (element) => {
    if (element && !element.disabled && !element.hidden) {
        let value = element.value;
        if (!value) {
            if (typeof element.getAttribute === "function") {
                value = element.getAttribute("data-value");
            } else {
                return true;
            }
        }
        const validation = Validation.getElementAttributes(element);
        const { allowedSpecialCharacters, allowedAutoFormatCharacters, allowedNumbers, prefix } = validation;

        if (validation.type === "NUMERIC" && value && value !== "") {
            const specialChars = `${allowedSpecialCharacters || ""}${allowedAutoFormatCharacters || ""}${prefix || ""}`;
            const regExpStr = Helpers.escapeRegExp(`0-9${specialChars}`);
            return new RegExp(`^[${regExpStr}]*$`).test(value);
        } else if (validation.type === "TEXT" || validation.type === "EMAIL") {
            const specialChars = `${allowedSpecialCharacters || ""}${allowedAutoFormatCharacters || ""}`;

            const regExpStr = ` a-zA-Z${allowedNumbers ? "0-9" : ""}${Helpers.escapeRegExp(specialChars).replace("-", "\\-")}`;
            return new RegExp(`^[${regExpStr}]*$`).test(value);
        }
    }
    return true;
};

Validation.getElementAttributes = (element) => {
    const attributes = {};
    if (element.dataset) {
        Object.keys(element.dataset).forEach((key) => {
            let value = element.dataset[key];
            if (!isNaN(Number(value)) || value === "true" || value === "false") {
                value = value === "" ? value : JSON.parse(value);
            }
            attributes[key] = value;
        });
    }
    return attributes;
};

Validation.getAsAttributes = (validation) => {
    const attributes = {};
    if (validation) {
        if (validation.maxLength) {
            if (validation.allowedAutoFormatCharactersLength) {
                attributes.maxLength = validation.maxLength + validation.allowedAutoFormatCharactersLength;
            } else {
                attributes.maxLength = validation.maxLength;
            }
        }
        attributes["data-type"] = validation.type;
        attributes["data-min-length"] = validation.minLength;
        attributes["data-max-length"] = validation.maxLength;
        attributes["data-is-required"] = validation.isRequired;
        attributes["data-allowed-numbers"] = validation.allowedNumbers;
        attributes["data-allowed-special-characters"] = validation.allowedSpecialCharacters;
        attributes["data-allowed-auto-format-characters"] = validation.allowedAutoFormatCharacters;
        attributes["data-allowed-auto-format-characters-length"] = validation.allowedAutoFormatCharactersLength;
        attributes["data-regex"] = validation.regex;
    }
    return attributes;
};

const closest = (el, selector, stopSelector) => {
    let element = el;
    let retval = null;
    while (element) {
        if (element.matches(selector)) {
            retval = element;
            break;
        } else if (stopSelector && element.matches(stopSelector)) {
            break;
        }
        element = element.parentElement;
    }
    return retval;
};

Validation.scrollToError = () => {
    if (Helpers.isMobile()) {
        const error = document.getElementsByClassName("has-error")[0];
        const errorContainer = closest(error, ".input-container, .input-container-no-margin-bottom, .promotion-flag-container, .select-container, .bday-container", ".container");

        if (errorContainer) {
            errorContainer.scrollIntoView();

            const headerHeight = 100;
            const scrolledY = window.scrollY;
            if (scrolledY) {
                window.scroll(0, scrolledY - headerHeight);
            }
        }
    }
};

export default Validation;
