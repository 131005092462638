import cloneDeep from "lodash.clonedeep";

const initialState = {
    errorMessage: "",
    operatorAuth: null,
    newPriceValue: 0.00,
    currentPrice: 0,
    selectedEndDate: new Date(),
    fromMainMenu: false,
    fromItemPricePage: false,
    dateRange: {
        start: new Date(),
        end: null,
        rawEndDate: null,
    },
    selectedProductPricing: {
        productDescription: "",
        price: 0.00,
        storePricing: {},
        pricingRules: {},
    },
};

const pricingManagementReducer = (state, action) => {
    const pricingManangementState = cloneDeep(state.pricingManagement) || cloneDeep(initialState);
    switch (action.type) {
        case "SET_PRICE_MANAGEMENT_ERROR":
            return { ...pricingManangementState, errorMessage: action.errorMessage };

        case "SELECTED_PRODUCT_PRICING":
            return { ...pricingManangementState, selectedProductPricing: { ...pricingManangementState.selectedProductPricing, ...action.selectedProductPricing } };

        case "SET_PRICE_VALUE":
            return { ...pricingManangementState, newPriceValue: action.newPriceValue };

        case "SET_DATE_RANGE":
            return { ...pricingManangementState, dateRange: { ...pricingManangementState.dateRange, ...action.dateRange } };

        case "SET_FROM_MAIN_MENU":
            return { ...pricingManangementState, fromMainMenu: action.fromMainMenu };

        case "SET_CURRENT_PRICE":
            return { ...pricingManangementState, currentPrice: action.currentPrice };

        case "SET_SELECTED_ENDDATE":
            return { ...pricingManangementState, selectedEndDate: action.selectedEndDate };

        case "SET_OPERATOR_AUTH":
            return { ...pricingManangementState, operatorAuth: action.operatorAuth };

        case "SET_FROM_UPDATE_PRICE_PAGE":
            return { ...pricingManangementState, fromItemPricePage: action.fromItemPricePage };

        case "REMOVE_STORE_PRICING":
            return {
                ...pricingManangementState,
                currentPrice: pricingManangementState.selectedProductPricing.price,
                selectedProductPricing: { ...pricingManangementState.selectedProductPricing, storePricing: {} },
            };
        case "RESET_UPDATE_PRICING_PAGE": {
            return {
                ...pricingManangementState,
                newPriceValue: 0.00,
                dateRange: {
                    start: new Date(),
                    end: null,
                },
                selectedProductPricing: {
                    productDescription: "",
                    price: 0.00,
                    storePricing: {},
                    pricingRules: {},
                },
            };
        }
        case "RESET_PRICE_MANAGEMENT":
            return initialState;
        default:
            return pricingManangementState;
    }
};

export default pricingManagementReducer;
