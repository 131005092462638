/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { HIDE_INTERVENTION } from "../../../actions";
import {
    REMOVE_PAGE_STACK,
    getProductDetails,
} from "../../../actions/defaultPage";
import { getItemDetailsForAllowedCollection } from "../../../actions/hotFoodAction";
import MenuItemContent from "../../../components/MenuItemContent";
import ScrollButton from "../../../components/ScrollButton";
import { generateRandomId } from "../../../utils/helper";
import useGetExtendedEserviceMenu from "../helpers/hooks/useGetExtendedEserviceMenu";
import {
    setPageItem,
    showHotFoodComboIntervention,
} from "../helpers/hotFoodCombo.utils";

const HotFoodComboPageItems = (props) => {
    const { sortedItems, activeTabName } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState(0);
    const [lastRowCount, setLastRowCount] = useState(0);
    const { handleGetExtendedEserviceMenu } = useGetExtendedEserviceMenu();
    const {
        tabs,
        subMenuId,
        hideNavigationTab,
        tabsStack,
        hotFoodComboID,
        parentOrderID,
        hideParentNav,
        prefetchedTabs,
    } = useSelector((state) => state.hotFoodCombo);

    const { newItems, pageLength } = useMemo(
        () =>
            setPageItem({
                items: sortedItems,
                currentPage,
                lastRowCount,
                isHideTabs: hideNavigationTab,
            }),
        [sortedItems, currentPage, lastRowCount, hideNavigationTab],
    );

    useEffect(() => {
        if (pageLength === 1) {
            setCurrentPage(0);
            setLastRowCount(0);
        }
    }, [pageLength]);



    function isEllipsisActive(e) {
        if (!e) return;
        if (e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth) {
            return (e.style.fontSize = "11px");
        }
    }


    const handleGetProductDetails = (item) => {
        if (item.requiredAuth && !item.productId) {
            return dispatch({
                type: "SET_PROMPT_OPERATOR_ID",
                promptOperatorId: true,
                pageStackItem: item.name.replace(/\s/g, ""),
            });
        }
        if (item.productId) {
            const { productId, allowCollection } = item;
            const orderID = generateRandomId();
            const additionalHotFoodItem = {
                parentOrderID: parentOrderID,
                hotFoodComboID,
                extendedTab: activeTabName,
                orderID,
                isChildCombo: true,
            };
            if (allowCollection) {
                return dispatch(
                    getItemDetailsForAllowedCollection({
                        productId,
                        customSuccessCallback: () => handleGetSides(item),
                        additionalHotFoodItem,
                    }),
                );
            }
            dispatch(getProductDetails(productId, true, null, allowCollection));
        }
    };


    const handleGoBack = () => {
        dispatch({ type: REMOVE_PAGE_STACK });
        dispatch({ type: HIDE_INTERVENTION });
        history.goBack();
    };

    const handleGetSides = (item) => {
        hideParentNav
            ? handleGetSidesWithHideTabs(item)
            : handleGetSidesNoHideTab(item);
    };

    const handleGetSidesWithHideTabs = (item) => {
        dispatch({
            type: "HFC_SET_ORDERS",
            orders: { category: props.activeTabName || item.menuId, ...item },
        });

        let tabIndex = 0;
        let menuId = null;
        tabIndex = tabsStack[0].findIndex((tab) => {
            if (tab?.children && tab.children.includes(subMenuId)) {
                return tab;
            }
        });
        if (tabIndex === -1)
            tabIndex = tabsStack[0].findIndex(
                (tab) => tab.menuId === subMenuId,
            );
        if (tabsStack[0][tabIndex + 1])
            menuId = tabsStack[0][tabIndex + 1].menuId;
        
        const hasPrefetchTab = prefetchedTabs.find((item) => item.menuId === menuId);
        if(hasPrefetchTab) {
            if(!hasPrefetchTab.tabs.length) {
                dispatch({
                    type: "HFC_SET_HIDE_NAVIGATION_TABS",
                    hideNavigationTab: true,
                });
            }
        }

        
        if(menuId) handleGetExtendedEserviceMenu({ menuId });
        if(!menuId) handleGoBack()
    };


    const handleGetSidesNoHideTab = (item) => {
        const newOrder = {
            category: props.activeTabName || item.menuId,
            ...item,
        };
        dispatch({
            type: "HFC_SET_ORDERS",
            orders: newOrder,
        });

      
        if (!hideParentNav) {
            const selectedTab = tabs.find((tab) => tab.menuId === subMenuId);
            return showHotFoodComboIntervention({
                tabName: activeTabName || selectedTab.name,
                dispatch,
                preventNameSplit: true,
                onGoBack: handleGoBack,
            });
        }
    };

    return (
        <Box
            component={"div"}
            sx={{
                position: "relative",
                display: "flex",
                alignSelf: "baseline",
                paddingLeft: "8px",
            }}
        >
            <div
                style={{ minWidth: "560px", marginTop: 0, overflow: "hidden" }}
                className={"button-container"}
            >
                {newItems.map((item, index) => {
                    const columnSpan = item.columnSpan || 1;
                    const widthLength = columnSpan * 80 + (columnSpan - 1) * 10;

                    const itemProps = {
                        key: `${item.name}-${index}`,
                        onClick: () => handleGetProductDetails(item),
                        className: `menu-item ${item.productId ? "red" : ""}`,
                        style: {
                            width: `${widthLength}px`,
                            visibility: item.name === "" ? "hidden" : "visible",
                        },
                        ...(item.image && { image: item.image }),
                    };

                    return (
                        <button
                            ref={(el) => isEllipsisActive(el)}
                            {...itemProps}
                        >
                            <MenuItemContent {...item} />
                        </button>
                    );
                })}
            </div>
            {pageLength > 1 && sortedItems.length && (
                <ScrollButton
                    buttonSize={{ width: 72, height: 63 }}
                    lastPage={pageLength - 1}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    setLastRowCount={setLastRowCount}
                    isHideTabs={hideNavigationTab}
                />
            )}
        </Box>
    );
};

export default HotFoodComboPageItems;
