/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { useLocation } from "react-router";
import CustomProductField from "../../../../components/CustomProductField";

import { stageEtender } from "../../../../actions/etender";

function EtenderPage() {
    const dispatch = useDispatch();
    const location = useLocation();
    const config = useSelector((state) => state.config);
    const defaultPage = useSelector((state) => state.defaultPage);

    const [eTenderTransactionId, setETenderTransactionId] = useState(null);
    useEffect(() => {
        const bodyElement = document.body;
        if(bodyElement) {
            bodyElement.style.height ="490px";
            bodyElement.style.width ="710px";
        }
         const { search } = location;
        const fields = getFields(getQueryString(search));

        if (Array.isArray(fields) && fields.length) {
            dispatch({
                type: "SET_ETENDER_PRODUCT_FIELDS",
                pageDetails: fields,
            });
        }
    }, []);

    function getFields(queryParams) {
        const fieldKeys = [
            "name",
            "description",
            "required",
            "entryMethod",
            "dataType",
            "fieldMin",
            "fieldMax",
            "displayOrder",
            "jsValidator",
        ];
        const fields = [];
        queryParams.split("^").forEach((field) => {
            const obj = {};
            field.split("|").forEach((val, i) => {
                fieldKeys.forEach((newVal, index) => {
                    if (i === index) {
                        obj[newVal] = parseValue(newVal, val);
                        if (newVal === "entryMethod") {
                            const entryMethodCopy = String(val);
                            if (entryMethodCopy.includes(";")) {
                                obj.entryMethodCopy = entryMethodCopy
                                    .split(";")
                                    .filter((entry) => entry);
                                return;
                            }
                            obj.entryMethodCopy = entryMethodCopy;
                        }
                    }
                });
            });
            fields.push(obj);
        });
        const sortedFields = fields.sort(
            (a, b) => a.displayOrder - b.displayOrder,
        );
        return sortedFields;
    }

    function parseValue(key, value) {
        const numbers = ["fieldMin", "fieldMax", "displayOrder"];
        if (key === "required") {
            return Boolean(value);
        }
        if (numbers.includes(key)) return parseInt(value);

        return value;
    }

    function getQueryString(search) {
        const queryParams = queryString.parse(search);
        const isDecoded = Object.keys(queryParams).includes("fields");

        if (!isDecoded) {
            const [queryParam] = Object.keys(queryParams);
            const decode = queryString.parse(queryParam);
            setETenderTransactionId(parseInt(decode.eTenderTransactionId));
            return decode.fields;
        }
        setETenderTransactionId(parseInt(queryParams.eTenderTransactionId));
        return queryParams.fields;
    }

    function onSubmit() {
        const fields = defaultPage.pageDetails.map((detail) => {
            const obj = {};
            obj.name = detail.name;
            obj.value = detail.value;
            obj.entryMethod = detail.entryMethod;
            return obj;
        });
        dispatch(stageEtender(fields, eTenderTransactionId));
    }

    if (!Object.keys(config).length)
        return <p style={{ display: "none" }}>loading</p>;
    return <CustomProductField onSubmit={onSubmit} />;
}

export default EtenderPage;
