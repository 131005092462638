import cloneDeep from "lodash.clonedeep";

const defaultState = {
    stageEtenderStatus: null,
};

const ETenderReducer = (state, action) => {
    const etender = cloneDeep(state.etender) || cloneDeep(defaultState);
    switch (action.type) {
        case "STAGE_ETENDER_SUCCESS": {
            return Object.assign(etender, {
                stageEtenderStatus: action.status,
            });
        }

        default:
            return etender;
    }
};

export default ETenderReducer;
