import Helpers from "./Helpers";

const Intl = {};

Intl.translate = (translation, key, defaultValue, args) => {
    let result = defaultValue || key;
    if (translation) {
        if (typeof translation[key] !== "undefined" && translation[key] != null) {
            result = translation[key];
        } else if (typeof translation[defaultValue] !== "undefined" && translation[defaultValue] != null) {
            result = translation[defaultValue];
        } else if (typeof defaultValue !== "undefined" && defaultValue != null) {
            result = defaultValue;
        } else {
            result = key;
        }
    }

    let translatedArgs = [];
    if (typeof args !== "undefined" && args !== null) {
        const argsArray = Helpers.isArray(args) ? args : [args];
        translatedArgs = argsArray.map((arg) => Intl.translate(translation, arg));
    }

    return Intl.replaceVars(result, translatedArgs);
};

Intl.replaceVars = (value, args) => {
    let result = value;
    if (args && args.length) {
        args.forEach((arg, index) => {
            const search = `{${index}}`; // e.g. {0}
            const regExp = new RegExp(Helpers.escapeRegExp(search), "g");
			const realArg = typeof arg === "string" && ~arg.indexOf("$") ? arg.split("$").join("$$") : arg;
            result = result.replace(regExp, realArg);
        });
    }
    return result;
};

Intl.removeInternationalizationProps = (properties) => {
    const props = Object.assign({}, properties);
    if (props) {
        delete props.messageKey;
        delete props.translation;
        delete props.args;
    }
    return props;
};

export default Intl;
