import { inputValidation, removeLeadingZero } from "../../../../utils/helper";

export const validateProductField = (dispatch, getState) => {
    const { config, productFieldsPage } = getState();
    const { defaultGiftCardPrefixes } = config.defaultValues;
    const {
        currentPageIndex,
        productFields,
        currentFieldValue,
    } = productFieldsPage;

    const productField = Array.isArray(productFields) && productFields[currentPageIndex];

    const jsValidators = productField.jsValidator && productField.jsValidator.split(",").map((validator) => validator.trim());
    const productFieldValidator = {};
    const {
        dataType, fieldMax, fieldMin, value, name, minLength, maxLength,
    } = productField;
    if (dataType === "DECIMAL") {
        Object.assign(productFieldValidator, {
            dataType,
            fieldMax,
            fieldMin,
            formattedValue: value ? parseFloat(value / 100).toFixed(2) : "0.00",
            currentFieldValue,
        });
    }
    const { message } = inputValidation({
        value: productField.dataType === "DECIMAL" ? removeLeadingZero(value) : value,
        name,
        jsValidators,
        minLength,
        maxLength,
        defaultGiftCardPrefixes,
        productFieldValidator,
    });

    return message;
};

export const test = () => {};
// const onScanBarcode = (dispatch, getState) => {
//     const { currentPageDetailsIndex } = getState().defaultPage;
//     const pageDetailsList = getState().defaultPage.pageDetails;
//     const { entryMethodCopy } = pageDetailsList[currentPageDetailsIndex];

//     const fieldCount = pageDetailsList.length;
//     const onSuccessScan = () => () => {
//         if (currentPageDetailsIndex >= fieldCount - 1) {
//             dispatch(initiateStageProduct(true));
//         } else {
//             dispatch({ type: NEXT_PRODUCT_FIELD });
//         }
//     };

//     dispatch(
//         scanBarcode(onSuccessScan, FILL_UP_PRODUCT_FIELD, entryMethodCopy),
//     );
// };

// if (validationError && Object.keys(validationError).length) {
//     const { name: validatorName, message: errorMessage } = validationError;
//     if (validatorName !== "7ELEVENGIFTCARD") {
//         resetTimer();
//         dispatch({
//             type: CUSTOM_PRODUCT_FIELD_ERROR,
//             error: errorMessage,
//             pageDetailsIndex: currentPageDetailsIndex,
//         });
//     } else {
//         resetTimer();
//         const continueAction = [{ type: SCAN_ERROR }];
//         dispatch({
//             type: SHOW_INTERVENTION,
//             description: errorMessage,
//             continueAction,
//         });
//     }
// } else {
//     onSuccessValidate(dispatch, getState);
// }
