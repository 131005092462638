/* eslint-disable import/prefer-default-export */
import { RestHost } from "common-functions";
import { SHOW_INTERVENTION } from ".";
import HttpUtils from "../utils/HttpUtils";
import {
    CLEAR_SWIPE_INPUT_FIELD, exitBrowser, FILL_UP_BARCODE, getStoreDetails, PREV_PRODUCT_FIELD, SCAN_ERROR,
} from "./defaultPage";

export const stageEtender = (productFields, eTenderTransactionId) => (dispatch, getState) => {
    const amountNames = ["amount", "AMOUNT"];
    const { defaultValues } = getState().config;
    const {
        pageDetails,
        currentPageDetailsIndex,
    } = getState().defaultPage;
    const successCallback = (response) => {
        if (response.validationStatus === "FAIL") {
            stageETenderError(response);
        }

        dispatch({ type: "STAGE_ETENDER_SUCCESS", status: response.validationStatus });
        dispatch(exitBrowser);
    };

    const stageETenderError = ({ message }) => {
        const description = message || "Unable to stage Etender ";
        let continueAction = [{ type: SCAN_ERROR }];
        if (pageDetails.length) {
            const { name: currentProductFieldName } = pageDetails[currentPageDetailsIndex];
            const pageDetail = pageDetails.find(
                (productField) => productField.name === "MAGDATA",
            );
            // eslint-disable-next-line no-unneeded-ternary
            const { entryMethod: magdataEntryMethod } = pageDetail || { entryMethod: null };

            const isFromSwipe = magdataEntryMethod === "C";

            const clearInputFieldsAction = [
                {
                    type: CLEAR_SWIPE_INPUT_FIELD,
                    currentPageDetailsIndex: 0,
                },
            ];

            const prevPageAction = [{ type: PREV_PRODUCT_FIELD }];

            if (
                amountNames.includes(currentProductFieldName)
                && isFromSwipe
            ) {
                continueAction = [
                    ...continueAction,
                    ...clearInputFieldsAction,
                    ...prevPageAction,
                ];
            }

            if (
                amountNames.includes(currentProductFieldName)
                && magdataEntryMethod === "K"
            ) {
                continueAction = [...continueAction, ...prevPageAction];
            }
            if (
                pageDetails.length === 1
                && amountNames.includes(currentProductFieldName)
            ) {
                continueAction = [
                    { type: "DEFAULT_PAGE_PREV_PAGE" },
                    { type: FILL_UP_BARCODE, value: "" },
                ];
            }

            if (currentProductFieldName === "MAGDATA" && isFromSwipe) {
                continueAction = [
                    ...continueAction,
                    ...clearInputFieldsAction,
                ];
            }
        }

        dispatch({ type: "STAGE_ETENDER_FAILURE" });
        dispatch({ type: SHOW_INTERVENTION, description, continueAction });
    };
    const etenderRequestBody = {
        ...getStoreDetails(defaultValues),
        productFields,
        eTenderTransactionId,
    };
    dispatch({ type: "STAGE_ETENDER_REQUEST" });
    HttpUtils.restSendHandler(dispatch, RestHost.POST, "stageETender", etenderRequestBody, null, successCallback, stageETenderError, RestHost.JSON);
};
