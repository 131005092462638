import React from "react";
import { connect } from "react-redux";
import {
    exitBrowser, initiateStageProduct, NEXT_PAGE, PREV_PAGE, REMOVE_PAGE_STACK
} from "../../../actions/defaultPage";
import Navigation from "../../../components/Navigation";
import { transactionEnum } from "../../../utils/Constants";

const SingleOperatorInstructions = ({
    instruction, onBack, onNext, instructionsOnly = true,
}) => {
    const title = "Instructions: ";
    return (
        <div className={`operator-instructions ${instructionsOnly ? "custom-style" : ""}`}>
            <div className="operator-instructions-container">
                <div>
                    <h2 className="title">{title}</h2>
                    <pre className="instruction">{instruction}</pre>
                </div>
            </div>

            <Navigation onBack={onBack} onNext={onNext} />
        </div>
    );
};

const finalizeTransction = (transaction, dispatch) => {
    const transactionsToExit = [
        transactionEnum.CANCELLATION,
        transactionEnum.REFUND_REQUEST,
    ];

    if (transactionsToExit.includes(transaction)) {
        dispatch(exitBrowser);
    } else {
        dispatch(initiateStageProduct());
    }
};

const onNextPage = () => (dispatch, getState) => {
    const { currentIndex, pages, pageDetails, operatorInstructionIsActive, transaction } = getState().defaultPage;
    if (!pageDetails.length) {
        if (operatorInstructionIsActive && pages.length - 1 === currentIndex) {
            finalizeTransction(transaction, dispatch, getState);
        } else {
            dispatch({ type: NEXT_PAGE });
        }
    } else {
        dispatch({ type: NEXT_PAGE });
    }
};

const onBackPage = (dispatch) => {
    dispatch({ type: REMOVE_PAGE_STACK });
    dispatch({ type: PREV_PAGE });
};

const mapDispatchToProps = (dispatch) => {
    return {
        onNext: () => dispatch(onNextPage()),
        onBack: () => dispatch(onBackPage),
    };
};

export default connect(null, mapDispatchToProps)(SingleOperatorInstructions);
