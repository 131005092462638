import React from "react";
import { Link } from "react-router-dom";
import Navigation from "../components/Navigation";

const ComingSoon = () => {
    const params = window.location.search;
    const backLink = (component) => (<Link to={`/index.html${params}`}>{component}</Link>);
    return (
        <div className="coming-soon">
            <h2>Service is unavailable for now</h2>
            <Navigation disableNext backLink={backLink} />
        </div>
    );
};

export default ComingSoon;
