import { Helpers, Validation } from "common-functions";
import { SHOW_INTERVENTION, HIDE_INTERVENTION, RESET_STATE } from "../actions";
import {
    VALIDATE, VALIDATION_FAILED, CLEAR_VALIDATION_ERRORS, VALIDATE_INPUTS_FORMAT, RESET_INPUTS_FORMAT_VALIDATION,
} from "../actions/validation";

const revalidateActions = [
    VALIDATE_INPUTS_FORMAT,
];

const defaultState = {
    onGoingRequestCount: 0,
    errors: [],
    hasInput: undefined,
    hasFormatErrors: undefined,
    consoleLogs: [],
    searchParam: undefined,
};

const processRequestErrors = (action) => {
    let errors = [];
    const value = action.error || action.errors;

    if (value) {
        if (Helpers.isArray(value)) {
            errors = value;
        } else if (value.fieldName) {
            errors = [{
                fieldName: value.fieldName,
                message: value.message,
            }];
        }
    }
    return errors;
};

const uiReducer = (state, action) => {
    const uiState = state.ui || defaultState;

    const requestStarted = action.type.endsWith("_REQUEST");
    const requestSuccesful = action.type.endsWith("_SUCCESS");
    const requestFailed = action.type.endsWith("_FAILURE");

    const isNextPage = action.type.indexOf("NEXT_PAGE") !== -1;
    const isPreviousPage = action.type.indexOf("PREVIOUS_PAGE") !== -1;
    const isNavigate = action.type.indexOf("PAGE_NAVIGATE") !== -1;
    const isPageChange = isNextPage || isPreviousPage || isNavigate;

    if (requestStarted && !action.processOnBackground) {
        return { ...uiState, onGoingRequestCount: uiState.onGoingRequestCount + 1 };
    } if (requestSuccesful && !action.processOnBackground) {
        let updatedCount = uiState.onGoingRequestCount - 1;
        updatedCount = updatedCount < 0 ? 0 : updatedCount;
        return { ...uiState, onGoingRequestCount: updatedCount };
    } if (requestFailed && (!action.processOnBackground || action.error.fieldName)) {
        const previousCount = uiState.onGoingRequestCount;
        let updatedCount = action.processOnBackground ? previousCount : previousCount - 1;
        updatedCount = updatedCount < 0 ? 0 : updatedCount;
        return { ...uiState, onGoingRequestCount: updatedCount, errors: processRequestErrors(action) };
    } if (action.type.startsWith("INITIATE_") || isPageChange) {
        return {
            ...uiState, errors: [], hasFormatErrors: undefined, hasInput: undefined,
        };
    }

    if (revalidateActions.includes(action.type)) {
        const result = Validation.validate();
        const { hasInput, hasFormatErrors } = result;
        return { ...uiState, hasInput, hasFormatErrors };
    }

    switch (action.type) {
        case VALIDATION_FAILED: {
            const hasFormatErrors = action.hasFormatErrors || uiState.hasFormatErrors;
            return { ...uiState, errors: processRequestErrors(action), hasFormatErrors };
        }

        case CLEAR_VALIDATION_ERRORS:
            return { ...uiState, errors: [] };

        case RESET_INPUTS_FORMAT_VALIDATION:
            return { ...uiState, hasFormatErrors: undefined, hasInput: undefined };

        case VALIDATE: {
            const { internationalization } = state;
            const translation = internationalization ? internationalization.translation : null;
            const result = Validation.validate(translation);
            const { errors, hasInput, hasFormatErrors } = result;
            return {
                ...uiState, errors, hasInput, hasFormatErrors,
            };
        }

        case SHOW_INTERVENTION:
            return {
                ...uiState,
                showIntervention: true,
                interventionTitle: action.title,
                interventionDescription: action.description,
                interventionChildren: action.children,
                interventionClassName: action.className,
                interventionJsonFormat: action.jsonFormat,
                showInterventionCloseBtn: action.showCloseBtn,
                showInterventionOnTop: action.prioritizeDisplay,
                interventionShouldExitBrowser: action.shouldExitBrowser,
                interventionContinueAction: action.continueAction,
                onGoingRequestCount: action.hideLoadingBox ? 0 : uiState.onGoingRequestCount,
            };

        case HIDE_INTERVENTION:
            return { ...uiState, showIntervention: false };

        case "ADD_UI_ERROR": {
            return Object.assign(uiState, { errors: [...uiState.errors, action.error] });
        }
        case "SET_SEARCH_PARAM":
            return { ...uiState, searchParam: action.searchParam };
        case RESET_STATE:
            return defaultState;

        default:
            return uiState;
    }
};

export default uiReducer;
