/* eslint-disable react/button-has-type */
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import useIdleHook from "../../utils/hooks/idleHook";

const DefaultPage = ({ pages, currentIndex }) => {
    const dispatch = useDispatch();
    const { search } = useLocation();
    useIdleHook();

    useEffect(() => {
        if (search) {
            dispatch({ type: "SET_SEARCH_PARAM", searchParam: search });
        }
    }, []);

    const page = pages[currentIndex];
    return <div className="default-page">{page.component}</div>;
};

const mapStateToProps = (state) => {
    return {
        pages: state.defaultPage.pages,
        currentIndex: state.defaultPage.currentIndex,
    };
};

export default connect(mapStateToProps, null)(DefaultPage);
