import React, { useState } from "react";

const VirtualKeyboard = ({ onKeyPress, hasNextButton = false }) => {
    const [whiteImage, setWhiteImage] = useState({
        backspace: false,
        delete: false,
    });

    const handleMouseUp = (key) => {
        setWhiteImage({ ...whiteImage, [key]: false });
    };
    const handleMouseDown = (key) => {
        setWhiteImage({ ...whiteImage, [key]: true });
    };

    const actions = (key) => {
        return {
            onMouseUp: () => handleMouseUp(key),
            onMouseDown: () => handleMouseDown(key),
        };
    };

    const deleteImage = `/resources/images/keyboard-delete${
        whiteImage.delete ? "-white" : ""
    }.png`;
    const backspaceImage = `/resources/images/keyboard-backspace2${
        whiteImage.backspace ? "-white" : ""
    }.png`;
    return (
        <div className="numpad-container">
            <div className="numpad">
                <div className="numbers-container">
                    <div className="button-row">
                        <button
                            onClick={() => onKeyPress("7")}
                            className="num-key"
                        >
                            7
                        </button>
                        <button
                            onClick={() => onKeyPress("8")}
                            className="num-key"
                        >
                            8
                        </button>
                        <button
                            onClick={() => onKeyPress("9")}
                            className="num-key"
                        >
                            9
                        </button>
                    </div>
                    <div className="button-row">
                        <button
                            onClick={() => onKeyPress("4")}
                            className="num-key"
                        >
                            4
                        </button>
                        <button
                            onClick={() => onKeyPress("5")}
                            className="num-key"
                        >
                            5
                        </button>
                        <button
                            onClick={() => onKeyPress("6")}
                            className="num-key"
                        >
                            6
                        </button>
                    </div>
                    <div className="button-row">
                        <button
                            onClick={() => onKeyPress("1")}
                            className="num-key"
                        >
                            1
                        </button>
                        <button
                            onClick={() => onKeyPress("2")}
                            className="num-key"
                        >
                            2
                        </button>
                        <button
                            onClick={() => onKeyPress("3")}
                            className="num-key"
                        >
                            3
                        </button>
                    </div>
                    <div className="button-row">
                        <button
                            onClick={() => onKeyPress("DELETE")}
                            {...actions("delete")}
                            className="num-key backspace"
                        >
                            <img src={deleteImage} />
                        </button>
                        <button
                            onClick={() => onKeyPress("0")}
                            className="num-key"
                        >
                            0
                        </button>
                        <button
                            onClick={() => onKeyPress("BACKSPACE")}
                            {...actions("backspace")}
                            className="num-key backspace"
                        >
                            <img src={backspaceImage} />
                        </button>
                        {hasNextButton && (
                            <button
                                onClick={() => onKeyPress("ENTER")}
                                className="num-key"
                                style={{
                                    position: "absolute",
                                    fontSize: "20px",
                                }}
                            >
                                Enter
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VirtualKeyboard;
