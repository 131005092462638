import cloneDeep from "lodash.clonedeep";
import { mapProductField } from "../utils/helper";

const initialState = {
    productFields: [],
    productFieldsCopy: [],
    currentPageIndex: 0,
};

const productFieldsReducer = (state, action) => {
    const productFieldsState = cloneDeep(state.productFieldsPage) || cloneDeep(initialState);
    switch (action.type) {
        case "SET_PRODUCT_FIELDS": {
            let { productFields } = { ...productFieldsState };
            const { productFields: _productFields } = action;
            const sortProductFields = (fieldA, fieldB) => fieldA.displayOrder - fieldB.displayOrder;
            const sortedProductFields = _productFields.sort(sortProductFields).map(mapProductField);
            const productFieldNames = productFields.map(({ name }) => name);
            sortedProductFields.forEach((productField) => {
                if (!productFieldNames.includes(productField.name)) {
                    productFields = [...productFields, productField];
                }
            });

            const productFieldsCopy = productFields.sort(sortProductFields).filter((productField) => !productField.isSave);

            return {
                ...productFieldsState,
                productFields,
                productFieldsCopy,
            };
        }

        case "SET_PRODUCT_FIELD_VALUE": {
            const { item } = action;
            const { productFields } = productFieldsState;
            productFields.forEach((productField, index, arr) => {
                // eslint-disable-next-line no-param-reassign
                if (productField.name === item.name) arr[index].value = item.value;
            });
            return {
                ...productFieldsState,
                productFields,
                currentFieldValue: item.value,
                hasError: false,
            };
        }

        case "SET_CURRENT_PAGE_INDEX": {
            const { name, currentPageIndex } = action;
            const { productFields } = productFieldsState;
            productFields.forEach((productField, index, arr) => {
                if (name === productField.name) {
                    // eslint-disable-next-line no-param-reassign
                    arr[index].isSave = true;
                }
            });

            return { ...productFieldsState, currentPageIndex, productFields };
        }
        default:
            return productFieldsState;
    }
};

export default productFieldsReducer;
