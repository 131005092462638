import cloneDeep from "lodash.clonedeep";

const defaultState = {
    orderedFoods: [],
    hotFoodProductFields: [],
    allowCollection: false,
    allOrderFoods: [],
};

function removeHotFoodCombo(allOrderFoods = [], orderID) {
    const allOrderFoodsCopy = [...allOrderFoods];
    const orderFood = allOrderFoodsCopy.find(
        (order) => order.orderID === orderID,
    );
    if (orderFood?.isParentOrder) {
        return allOrderFoodsCopy.map((order) => {
            if (orderID === order.parentOrderID) {
                delete order.hotFoodComboID;
                if (order?.isChildCombo) delete order.isChildCombo;
                return order;
            }
            return order;
        });
    }
    return allOrderFoodsCopy;
}

const hotFoodReducer = (state, action) => {
    const hotFood = cloneDeep(state.hotFood) || cloneDeep(defaultState);
    switch (action.type) {
        case "ADD_ORDER_FOOD": {
            const { item } = action;
            const { orderedFoods, allOrderFoods } = hotFood;
            const { productFields, orderID } = item;
            let orderFoodsModified = [];
            const itemIsOnOrderedFoods = orderedFoods.find(
                (order) => +order.productNumber === +item.productNumber,
            );
            const hasProductFields =
                Array.isArray(item.productFields) && item.productFields.length;

            if (hasProductFields) {
                item.productFields.forEach((field, index, arr) => {
                    // eslint-disable-next-line no-param-reassign
                    arr[index].productNumber = item.productNumber;
                    arr[index].orderID = orderID;
                });
            }

            if (itemIsOnOrderedFoods) {
                orderFoodsModified = hotFood.orderedFoods.map((order) => {
                    const orderCopy = { ...order };
                    if (+order.productNumber === +item.productNumber) {
                        orderCopy.quantity += 1;
                        return orderCopy;
                    }
                    return order;
                });
            }

            if (!itemIsOnOrderedFoods) item.quantity = 1;

            const orderFoodsCopy = !itemIsOnOrderedFoods
                ? [...hotFood.orderedFoods, item]
                : orderFoodsModified;

            return Object.assign(hotFood, {
                orderedFoods: orderFoodsCopy,
                allOrderFoods: [...allOrderFoods, item],
                ...(productFields.length && {
                    hotFoodProductFields: [
                        ...hotFood.hotFoodProductFields,
                        ...productFields,
                    ],
                }),
            });
        }

        case "REMOVE_HOT_FOOD_ORDER": {
            const { orderID } = action;
            const orderedFoodsCopy = [
                ...removeHotFoodCombo(hotFood.allOrderFoods, orderID),
            ];

            const hotFoodProductFieldsCopy = [...hotFood.hotFoodProductFields];
            const newOrderedFoods = orderedFoodsCopy.filter(
                (order) => order.orderID !== orderID,
            );
            const newHotFoodProductFields = hotFoodProductFieldsCopy.filter(
                (field) => field.orderID !== orderID,
            );

            return {
                ...hotFood,
                allOrderFoods: newOrderedFoods,
                hotFoodProductFields: newHotFoodProductFields,
            };
        }

        case "SET_HOTFOOD_COMBO_ID": {
            const orderedFoodsCopy = [...hotFood.allOrderFoods];
            const newOrderedFoods = orderedFoodsCopy.map((orderFood) => {
                const isOrderIdEqual =
                    orderFood?.orderID && orderFood?.orderID === action.orderID;
                if (isOrderIdEqual) {
                    return {
                        ...orderFood,
                        hotFoodComboID: action.hotFoodComboID,
                        isParentOrder: true,
                    };
                }
                return orderFood;
            });
            return { ...hotFood, allOrderFoods: newOrderedFoods };
        }

        case "RESET_HOTFOOD_REDUCER":
            return {
                ...hotFood,
                orderedFoods: [],
                initialFoodOredered: {},
                customerName: "",
                hotFoodProductFields: [],
            };

        default:
            return hotFood;
    }
};

export default hotFoodReducer;
